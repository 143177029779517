(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@cainiaofe/cn-ui"), require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["@cainiaofe/cn-ui", "react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["CNDOMAINFORMITEM"] = factory(require("@cainiaofe/cn-ui"), require("react"), require("react-dom"));
	else
		root["CNDOMAINFORMITEM"] = factory(root["CNUI"], root["React"], root["ReactDOM"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__1569__, __WEBPACK_EXTERNAL_MODULE__5442__, __WEBPACK_EXTERNAL_MODULE__6003__) => {
return 