import { __assign } from "tslib";
/**
 * cn-filter 开启 showSelected 时渲染已选项
 */
import React from 'react';
import { transferObjToAvailable } from '../utils/useDetail';
// -- Todo 有默认值时已选项显示label
export default function RegionItemRender(props) {
    var outerProps = props.outerProps, value = props.value, itemLabel = props.itemLabel, getData = props.getData;
    var arrValue = transferObjToAvailable(value, __assign({}, outerProps));
    var arrLabel = getData(arrValue).map(function (o) { return o === null || o === void 0 ? void 0 : o.label; });
    if (!arrLabel || (arrLabel === null || arrLabel === void 0 ? void 0 : arrLabel.length) === 0) {
        arrLabel = arrValue;
    }
    return (React.createElement("div", { style: { display: 'inline' } },
        itemLabel,
        ": ",
        arrLabel.toString()));
}
