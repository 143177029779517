import { preHandlerDataLanguage, getName } from './languages';
import { ABROAD_CODES } from './const';
/**
 * 将数据从地址库格式转换为fusion组件标准格式
 * @param dataProps
 * @returns
 */
export function handleData(dataProps) {
    var input = dataProps.input, _a = dataProps.isLeaf, isLeaf = _a === void 0 ? false : _a, parentId = dataProps.parentId, showDisabledDivision = dataProps.showDisabledDivision, propDataLanguage = dataProps.dataLanguage, maxDataLevel = dataProps.maxDataLevel, needSkipLevelData = dataProps.needSkipLevelData, divisionSort = dataProps.divisionSort, isSearch = dataProps.isSearch, levelConfig = dataProps.levelConfig, iso = dataProps.iso, isShowAbroad = dataProps.isShowAbroad;
    // 预处理数据语言
    var dataLanguage = preHandlerDataLanguage(propDataLanguage);
    var res = [];
    for (var i = 0; i < input.length; i++) {
        var o = input[i];
        if (!showDisabledDivision && o.disable) {
            continue;
        }
        if (!isShowAbroad && ABROAD_CODES.includes(o.id)) {
            continue;
        }
        var label = getName(o, dataLanguage).name;
        var isLeafInEvery = false;
        var originLeaf = o.leaf; // 原始数据
        isLeafInEvery = originLeaf;
        if (!isLeafInEvery) {
            isLeafInEvery = isLeaf;
        }
        // 需要跳级数据时，按照逻辑level判断，不需要跳级数据时按照真正level判断
        var l = needSkipLevelData ? o.level : o.rawLevel || o.level;
        if (!isLeafInEvery) {
            isLeafInEvery = l >= maxDataLevel;
        }
        // 单独配置国家层级 - 优先级最高
        // 且仅在设置 iso="" 生效, 即设置全部国家时
        var independentLevelConfig = levelConfig === null || levelConfig === void 0 ? void 0 : levelConfig[o.iso];
        if (independentLevelConfig && iso === '') {
            isLeafInEvery = l >= independentLevelConfig;
        }
        var item = {
            disable: o.disable,
            label: label,
            value: o.id,
            level: o.level,
            parentId: parentId,
            iso: o.iso,
            // 1.原始数据判断, 2.展开层级判断, 3.数据层级判断
            // isLeaf: o?.leaf || isLeaf || o.level >= maxDataLevel,
            isLeaf: isLeafInEvery,
            names: o.names,
            language: o.language,
        };
        if (o.rawLevel)
            item.rawLevel = o.rawLevel;
        // 国内区划选择第一级level设置
        if (iso !== '' && !parentId) {
            item.rawLevel = o.level;
            item.level = 1;
        }
        res.push(item);
    }
    // sort
    // 非搜索结果数据再进行排序
    if (!isSearch) {
        res = handlerSortData(res, divisionSort, dataLanguage);
    }
    return res;
}
/**
 * 数据排序
 * @param data <array>源数据
 * @param divisionSort
 * @param dataLanguage
 * @returns
 */
export function handlerSortData(data, divisionSort, dataLanguage) {
    if (divisionSort === 'EN' || divisionSort === 'pinyin') {
        return data.sort(function (a, b) {
            return (a.names.EN || a.names.pinyin || '').localeCompare(b.names.EN || b.names.pinyin || '');
        });
    }
    else if (divisionSort === 'ID') {
        return data.sort(function (a, b) {
            var id1 = a.id || a.value;
            var id2 = b.id || b.value;
            return id1 - id2;
        });
    }
    else {
        // 按字母 alphabetical
        return data.sort(function (a, b) {
            var A = getName(a, dataLanguage);
            var B = getName(b, dataLanguage);
            return (A.name || '').localeCompare(B.name || '');
        });
    }
}
