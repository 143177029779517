import { __assign, __awaiter, __generator } from "tslib";
import PropTypes, { any } from 'prop-types';
import mtop from './mtop';
import api from './api';
var apiModels = { mtop: mtop, api: api };
// sendSDK 支持 @ali/tbjssdk https://yuque.antfin.com/chenghong.qc/prkdcp/bmliq1
var CndzkEntranceApi = /** @class */ (function () {
    function CndzkEntranceApi(options) {
        var _a = options.apiModel, apiModel = _a === void 0 ? 'mtop' : _a, _b = options.applicationType, applicationType = _b === void 0 ? 'query' : _b, _c = options.apiType, apiType = _c === void 0 ? 'cndzk' : _c, _d = options.cnDataVersion, cnDataVersion = _d === void 0 ? '' : _d, _e = options.businessId, businessId = _e === void 0 ? '' : _e, _f = options.realm, realm = _f === void 0 ? '' : _f, sendSDK = options.sendSDK, _g = options.isJsonpForMtop, isJsonpForMtop = _g === void 0 ? false : _g, _h = options.hideSensitiveDivision, hideSensitiveDivision = _h === void 0 ? true : _h;
        if (apiModel == 'mtop') {
            var _j = apiModels[apiModel], apis = _j.apis, get_1 = _j.get;
            this.get = function (apiName_1) {
                return __awaiter(this, arguments, void 0, function (apiName, data) {
                    if (data === void 0) { data = {}; }
                    return __generator(this, function (_a) {
                        return [2 /*return*/, get_1(apiType, apiName, __assign({ applicationType: applicationType, cnDataVersion: cnDataVersion, businessId: businessId, realm: realm, isJsonpForMtop: isJsonpForMtop, hideSensitiveDivision: hideSensitiveDivision }, data), sendSDK)];
                    });
                });
            };
            this.apis = apis;
        }
        else if (apiModel == 'api') {
            var ins = new apiModels.api(options);
            this.get = ins.get;
            this.apis = ins.apis;
        }
    }
    CndzkEntranceApi.propTypes = {
        apiModel: PropTypes.string,
        applicationType: PropTypes.string,
        apiType: PropTypes.string,
        cnDataVersion: PropTypes.string,
        businessId: PropTypes.string,
        realm: PropTypes.string,
        sendSDK: any,
        isJsonpForMtop: PropTypes.bool,
    };
    return CndzkEntranceApi;
}());
export { mtop, CndzkEntranceApi };
export default { mtop: mtop, CndzkEntranceApi: CndzkEntranceApi };
