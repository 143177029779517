import isNumber from 'lodash/isNumber';
import uniq from 'lodash/uniq';
import { isPlainObject } from './object';
export var normalizeValue = function (value) {
    // id兼容处理为 string 类型
    if (value) {
        if (Array.isArray(value)) {
            return value.map(function (item) { return (isPlainObject(item) ? item : String(item)); });
        }
        return isPlainObject(value) ? [value] : [String(value)];
    }
    return [];
};
export function genDict(arr) {
    if (arr === void 0) { arr = []; }
    var dict = {};
    for (var i = 0; i < arr.length; i++) {
        var value = arr[i];
        if (!dict[value])
            dict[value] = i;
    }
    return dict;
}
export function processValue(value, v, checked, d) {
    var arr = uniq(normalizeValue(v));
    var dict = d || genDict(value);
    var shouldDel = [];
    for (var i = 0; i < arr.length; i++) {
        var v_1 = arr[i];
        var index = dict[v_1];
        if (checked && !isNumber(index)) {
            value.push(v_1);
            dict[v_1] = value.length - 1;
        }
        else if (!checked && isNumber(index)) {
            // value.splice(index, 1, undefined);
            shouldDel.push(index);
        }
    }
    shouldDel = shouldDel.sort(function (a, b) { return a - b; });
    for (var i = shouldDel.length - 1; i >= 0; i--) {
        var index = shouldDel[i];
        value.splice(index, 1);
    }
}
export function forEachEnableNode(node, callback) {
    if (callback === void 0) { callback = function () { }; }
    if (node.disabled || node.checkboxDisabled)
        return;
    // eslint-disable-next-line callback-return
    callback(node);
    if (node.children && node.children.length > 0) {
        node.children.forEach(function (child) { return forEachEnableNode(child, callback); });
    }
}
export function isSiblingOrSelf(currentPos, targetPos) {
    var currentNums = currentPos.split('-').slice(0, -1);
    var targetNums = targetPos.split('-').slice(0, -1);
    return (currentNums.length === targetNums.length &&
        currentNums.every(function (num, index) {
            return num === targetNums[index];
        }));
}
export function isNodeChecked(node, checkedValues) {
    if (node.disabled || node.checkboxDisabled)
        return true;
    /* istanbul ignore next */
    if (node.checkable === false) {
        return (!node.children ||
            node.children.length === 0 ||
            node.children.every(function (c) { return isNodeChecked(c, checkedValues); }));
    }
    return checkedValues.indexOf(node.value) > -1;
}
export function getCheckableParentNode(node, _p2n) {
    var parentPos = node.pos.split(['-']);
    if (parentPos.length === 2)
        return node;
    parentPos.splice(parentPos.length - 1, 1);
    parentPos = parentPos.join('-');
    var parentNode = _p2n[parentPos];
    if (parentNode.disabled || parentNode.checkboxDisabled)
        return false;
    /* istanbul ignore next */
    if (parentNode.checkable === false) {
        return getCheckableParentNode(parentNode, _p2n);
    }
    return parentNode;
}
export function filterChildValue(values, _v2n, _p2n) {
    var newValues = [];
    var dict = genDict(values);
    values.forEach(function (value) {
        var node = getCheckableParentNode(_v2n[value], _p2n);
        if (!node || node.checkable === false || node === _v2n[value] || !isNumber(dict[node.value])) {
            newValues.push(value);
        }
    });
    return newValues;
}
