export function preHandlerDataLanguage(dataLanguage) {
    var mapString = {
        'en-US': 'EN',
        'es-ES': 'ES',
        'fr-FR': 'FR',
        'ja-JP': 'JP',
        'ko-KR': 'KR',
        'pt-PT': 'PT',
        'ru-RU': 'RU',
        'zh-CN': 'CN',
    };
    if (mapString[dataLanguage])
        return mapString[dataLanguage];
    return dataLanguage;
}
/**
 * 根据语言获取区划名称
 * 获取名称时，默认使用当地语言，即 local；获取某种语言时，某些国家不一定存在该语言类型，语言顺序为：设置语言 > 英文 > 本土语言
 * @param item 区划Item
 * @param dataLanguage 语言
 * @returns 返回语言对象
 */
export function getName(item, dataLanguage) {
    if (item === void 0) { item = {}; }
    var _a = item.names, names = _a === void 0 ? {} : _a, language = item.language;
    if (dataLanguage == 'local') {
        return { language: language, name: names[language] };
    }
    else if (names[dataLanguage]) {
        return { language: dataLanguage, name: names[dataLanguage] };
    }
    else if (names.EN) {
        return { language: 'EN', name: names.EN };
    }
    else {
        return { language: language, name: names[language] };
    }
}
