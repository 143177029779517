import { __extends, __read } from "tslib";
import React from 'react';
import constants from './constants';
var COMPONENT_HEIGHT = constants.COMPONENT_HEIGHT;
function isHorizontal(x1, x2) {
    if (x1 === x2) {
        return false;
    }
    return true;
}
function genKey(value) {
    if (value === void 0) { value = []; }
    var _a = __read(value, 4), x1 = _a[0], y1 = _a[1], x2 = _a[2], y2 = _a[3];
    return "".concat(x1, ",").concat(y1, "-").concat(x2, ",").concat(y2);
}
var Link = /** @class */ (function (_super) {
    __extends(Link, _super);
    function Link() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Link.prototype.drawLine = function (x1, y1, x2, y2) {
        var isH = isHorizontal(x1, x2);
        var width = isH ? x2 - x1 : Math.abs(y1 - y2);
        var highlight = this.props.highlight;
        var style = {
            height: isH ? 1 : width,
            width: isH ? width : 1,
            left: x1,
            top: (isH ? y1 : Math.min(y1, y2)) + COMPONENT_HEIGHT / 2,
            zIndex: highlight ? 1 : 0,
        };
        var clsNames = "link ".concat(highlight ? ['link-highlight'] : '');
        return React.createElement("div", { className: clsNames, style: style, key: genKey([x1, y1, x2, y2]) });
    };
    Link.prototype.drawLines = function () {
        var _a = this.props, source = _a.source, target = _a.target;
        var x1 = source.x, y1 = source.y;
        var x2 = target.x, y2 = target.y;
        var lines = [];
        if (x1 === x2 || y1 === y2) {
            // 一条直线
            lines = [this.drawLine(x1, y1, x2, y2)];
        }
        else {
            // 一条折线，找到转折点，左(x1,y1) -> 右(x2,y2)
            var xm = (x1 + x2) / 2;
            var ym = y1;
            var xn = xm;
            var yn = y2;
            lines.push(this.drawLine(x1, y1, xm, ym));
            lines.push(this.drawLine(xm, ym, xn, yn));
            lines.push(this.drawLine(xn, yn, x2, y2));
        }
        return React.createElement(React.Fragment, null, lines);
    };
    Link.prototype.render = function () {
        return this.drawLines();
    };
    return Link;
}(React.PureComponent));
export default Link;
