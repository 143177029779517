import { __assign, __extends } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import { CnTag, CnButton, CnIcon, CnInput } from '@cainiaofe/cn-ui';
import { func, KEYCODE } from '@cainiaofe/cn-ui/_fusion/lib/util/index';
import { Menu } from '../menu';
import { isNull, getValueDataSource, valueToSelectKey, preventDefault, highlightRender, filter, } from '../../utils/index';
import DataStore from './data-store';
import { SelectPopup } from '../select-popup';
import { SelectTrigger } from '../select-trigger';
import './index.scss';
var noop = func.noop;
var PureSelect = /** @class */ (function (_super) {
    __extends(PureSelect, _super);
    function PureSelect(props) {
        var _this = _super.call(this, props) || this;
        _this.useDetailValue = function () {
            var _a = _this.props, popupContent = _a.popupContent, useDetailValue = _a.useDetailValue, dataSource = _a.dataSource;
            return useDetailValue || (popupContent && !dataSource);
        };
        /**
         * Menu.Item onSelect
         * @private
         * @param  {Array<string>} keys
         * @
         */
        _this.handleMenuSelect = function (keys, item) {
            var _a = _this.props, readOnly = _a.readOnly, isPreview = _a.isPreview, disabled = _a.disabled;
            if (readOnly || isPreview || disabled) {
                return false;
            }
            // 正常多选
            return _this.handleMultipleSelect(keys, 'itemClick', item && item.props && item.props._key);
        };
        _this.handleItemClick = function () {
            _this.focusInput();
        };
        _this.handleSearchValue = function (value) {
            if (_this.state.searchValue === value) {
                return;
            }
            var filterLocal = _this.props.filterLocal;
            if (filterLocal) {
                if (!('searchValue' in _this.props)) {
                    _this.setState({
                        searchValue: value,
                        dataSource: _this.dataStore.updateByKey(value),
                    });
                    _this.setFirstHightLightKeyForMenu();
                    setTimeout(function () {
                        _this.focusInput();
                    });
                }
            }
            else if (!('searchValue' in _this.props)) {
                _this.setState({
                    searchValue: value,
                });
            }
        };
        _this.handleSearch = function (value, e, triggerType) {
            _this.handleSearchValue(value);
            // inputing should trigger popup open
            if (!_this.state.visible && value) {
                _this.setVisible(true);
            }
            _this.props.onSearch(value, triggerType || 'inputChange', e);
        };
        _this.handleSearchClear = function (triggerType) {
            _this.handleSearch('', null, triggerType);
        };
        // 搜索框 keyDown 事件
        _this.handleSearchKeyDown = function (e) {
            var _a = _this.props, popupContent = _a.popupContent, onKeyDown = _a.onKeyDown, onToggleHighlightItem = _a.onToggleHighlightItem, readOnly = _a.readOnly, disabled = _a.disabled;
            if (popupContent) {
                return onKeyDown(e);
            }
            var proxy = 'search';
            switch (e.keyCode) {
                case KEYCODE.UP:
                    e.preventDefault();
                    onToggleHighlightItem(_this.toggleHighlightItem(-1, e), 'up');
                    break;
                case KEYCODE.DOWN:
                    e.preventDefault();
                    onToggleHighlightItem(_this.toggleHighlightItem(1, e), 'down');
                    break;
                case KEYCODE.ENTER:
                    e.preventDefault();
                    if (readOnly || disabled) {
                        break;
                    }
                    _this.chooseHighlightItem(proxy, e);
                    break;
                case KEYCODE.ESC:
                    e.preventDefault();
                    _this.state.visible && _this.setVisible(false, 'keyDown');
                    break;
                case KEYCODE.SPACE:
                    e.stopPropagation();
                    break;
                default:
                    break;
            }
            onKeyDown(e);
        };
        _this.chooseMultipleItem = function (key) {
            var value = _this.state.value || [];
            var keys = value.map(function (v) {
                return valueToSelectKey(v);
            });
            var index = keys.map(function (v) { return "".concat(v); }).indexOf(key);
            if (index > -1) {
                // unselect
                keys.splice(index, 1);
            }
            else {
                // select
                keys.push(key);
            }
            _this.handleMultipleSelect(keys, 'enter', null);
        };
        // 回车 选择高亮的 item
        _this.chooseHighlightItem = function (proxy, e) {
            if (!_this.state.visible) {
                return false;
            }
            var highlightKey = _this.state.highlightKey;
            // 没有高亮选项 或者 没有可选菜单
            if (highlightKey === null || !_this.dataStore.getMenuDS().length) {
                return;
            }
            _this.chooseMultipleItem(highlightKey);
            // 阻止事件冒泡到最外层，让Popup 监听到触发弹层关闭
            e && e.stopPropagation();
        };
        /**
         * Handle Tag close event
         * @param  {Object} item
         * @return {Boolean} false  return false to prevent auto close
         * ----
         * It MUST be multiple mode, needn't additional judgement
         */
        _this.handleTagClose = function (item) {
            var readOnly = _this.props.readOnly;
            if (readOnly)
                return false;
            if (_this.useDetailValue()) {
                var value = _this.state.value.filter(function (v) {
                    return item.value !== v.value;
                });
                _this.handleChange(value, 'tag');
            }
            else {
                // filter out current item, and then call handleMenuSelect
                var value = _this.state.value.filter(function (v) {
                    return item.value !== v;
                });
                _this.handleMultipleSelect(value, 'tag', null);
            }
            _this.props.onRemove(item);
            // prevent tag close
            return false;
        };
        /**
         * Handle SelectAll span click event
         * @param {Event} e click event
         */
        _this.handleSelectAll = function (selectValues) {
            var nextValues;
            if (selectValues.includes('selectAll')) {
                nextValues = _this.dataStore.getEnableDS().map(function (item) { return item.value; });
            }
            else {
                nextValues = [];
            }
            // 直接传 values，减少 toString 操作
            _this.handleMultipleSelect(nextValues, 'selectAll', null);
        };
        /**
         * Set popup visible
         * @protected
         * @param {boolean} visible
         * @param {string} type trigger type
         */
        _this.setVisible = function (visible, type) {
            // disabled 状态下只允许关闭不允许打开
            if ((_this.props.disabled && visible) || _this.state.visible === visible) {
                return;
            }
            if (!('visible' in _this.props)) {
                _this.setState({
                    visible: visible,
                });
            }
            _this.props.onVisibleChange(visible, type);
        };
        // afterClose() {
        //   const { showSearch } = this.props;
        //   // 关闭的时候清空搜索值
        //   if (showSearch) {
        //     this.handleSearchClear('popupClose');
        //   }
        // }
        _this.handleClear = function (e) {
            var onClear = _this.props.onClear;
            var valueDS = _this.state.valueDS;
            if (onClear) {
                onClear();
            }
            e.stopPropagation();
            var emptyValueDS = valueDS.filter(function (item) { return item.disabled; }).map(valueToSelectKey);
            _this.handleChange(emptyValueDS, 'clear');
        };
        /**
         * Handle Menu body click
         * @param {Event} e click event
         */
        _this.handleMenuBodyClick = function () {
            _this.focusInput();
        };
        _this.saveSearchInputRef = function (ref) {
            if (ref && ref.getInstance()) {
                _this.searchInputRef = ref.getInstance();
            }
        };
        _this.saveSelectDOM = function (domElement) {
            _this.selectDOM = domElement;
        };
        /**
         * 点击 arrow 或 label 的时候焦点切到 input 中
         * @override
         */
        _this.focusInput = function () {
            var _a;
            // todo: 需要在弹层显示后再 focus
            (_a = _this.searchInputRef) === null || _a === void 0 ? void 0 : _a.focus();
        };
        _this.afterOpen = function () {
            _this.focusInput();
        };
        _this.savePopupRef = function (ref) {
            _this.selectPopupRef = ref;
        };
        _this.renderSelected = function () {
            var valueDS = _this.state.valueDS;
            var _a = _this.props, valueRender = _a.valueRender, fillProps = _a.fillProps, disabled = _a.disabled, triggerTagProps = _a.triggerTagProps;
            var selectedValueNodes = Array.isArray(valueDS) &&
                valueDS.length > 0 &&
                valueDS.map(function (v) {
                    if (!v) {
                        return null;
                    }
                    var labelNode = fillProps ? v[fillProps] : valueRender(v);
                    return (React.createElement(CnTag, __assign({ key: v.value, disabled: disabled || v.disabled, type: "primary", size: "small", animation: false, onClose: function () { return _this.handleTagClose(v); }, closable: true }, triggerTagProps), labelNode));
                });
            return (React.createElement("div", { className: "cn-multi-select-selected-container" },
                React.createElement("div", { className: "cn-multi-select-selected-title" },
                    React.createElement("div", { className: "text" }, $i18n.get({
                        id: 'ValueDSLengthSelected',
                        dm: '已选择 {valueDSLength} 项',
                        ns: 'CnFastMultiSelect',
                    }, { valueDSLength: valueDS.length })),
                    React.createElement("div", { className: "clear" },
                        React.createElement(CnButton, { size: "small", onClick: _this.handleClear }, $i18n.get({ id: 'Empty', dm: '清空', ns: 'CnFastMultiSelect' })))),
                React.createElement("div", { className: "cn-multi-select-selected-body" }, selectedValueNodes)));
        };
        _this.dataStore = new DataStore({
            filter: props.filter,
            filterLocal: props.filterLocal,
            showDataSourceChildren: props.showDataSourceChildren,
        });
        var menuDataSource = _this.setDataSource(props);
        // value 相关
        var value = 'value' in props ? props.value : props.defaultValue;
        var valueDS = [];
        // 对 value 数组订正
        if (value && !Array.isArray(value)) {
            value = [value];
        }
        // 根据value和计算后的dataSource，更新value对应的详细数据valueDataSource
        if (typeof value !== 'undefined') {
            var itemObj = getValueDataSource(value, _this.dataStore.getMapDS());
            valueDS = itemObj.valueDS;
        }
        // 搜索值相关
        var searchValue = 'searchValue' in props ? props.searchValue : '';
        _this.dataStore.setOptions({
            key: searchValue,
        });
        _this.state = {
            value: value,
            valueDS: valueDS,
            visible: 'visible' in props ? props.visible : props.defaultVisible,
            searchValue: searchValue,
            dataSource: menuDataSource,
            highlightKey: 'highlightKey' in props ? props.highlightKey : props.defaultHighlightKey,
        };
        return _this;
    }
    PureSelect.getDerivedStateFromProps = function (nextProps, prevState) {
        var state = {};
        // 用于受控更新 value
        if ('value' in nextProps && nextProps.value !== prevState.value) {
            Object.assign(state, {
                value: nextProps.value,
            });
        }
        // 用于受控更新当前高亮 key
        if ('highlightKey' in nextProps && nextProps.highlightKey !== prevState.highlightKey) {
            Object.assign(state, {
                highlightKey: nextProps.highlightKey,
            });
        }
        // 用于受控更新 searchValue
        if ('searchValue' in nextProps && nextProps.searchValue !== prevState.searchValue) {
            var searchValue = nextProps.searchValue;
            Object.assign(state, {
                searchValue: searchValue === undefined || searchValue === null ? '' : searchValue,
            });
        }
        // 用于受控更新 visible
        if ('visible' in nextProps && nextProps.visible !== prevState.visible) {
            Object.assign(state, {
                visible: nextProps.visible,
            });
        }
        if (Object.keys(state).length) {
            return state;
        }
        return null;
    };
    PureSelect.prototype.componentDidUpdate = function (prevProps, prevState) {
        var props = this.props;
        if ('searchValue' in props && this.state.searchValue !== prevState.searchValue) {
            this.dataStore.setOptions({ key: this.state.searchValue });
        }
        if (props.filter !== prevProps.filter) {
            this.dataStore.setOptions({
                filter: props.filter,
            });
        }
        if (props.filterLocal !== prevProps.filterLocal) {
            this.dataStore.setOptions({
                filterLocal: props.filterLocal,
            });
        }
        if (prevProps.dataSource !== props.dataSource) {
            // 设置 dataSource
            this.setState({
                dataSource: this.setDataSource(props),
            });
            // 非受控情况下使用 this.state.value, 受控情况下使用 props.value
            var stateValue = this.state.value;
            if ('value' in props) {
                stateValue = props.value;
            }
            var itemObj = getValueDataSource(stateValue, this.dataStore.getMapDS());
            this.updateSelectAllYet(itemObj.value);
            this.setState({
                valueDS: itemObj.valueDS,
            });
            if (!props.popupContent) {
                this.setFirstHightLightKeyForMenu();
            }
        }
        if ('value' in props && prevProps.value !== props.value) {
            var itemObj = getValueDataSource(props.value, this.dataStore.getMapDS());
            this.updateSelectAllYet(itemObj.value);
            this.setState({
                valueDS: itemObj.valueDS,
            });
        }
    };
    /**
     * 清空搜索值
     * 注意⚠️，这里会用空的值再次触发搜索
     * @param triggerType 触发类型
     */
    PureSelect.prototype.clearSearchValueAndResearch = function (triggerType) {
        var shouldClearSearchValueAfterSelect = this.props.shouldClearSearchValueAfterSelect;
        if (!('searchValue' in this.props) &&
            this.state.searchValue &&
            shouldClearSearchValueAfterSelect) {
            this.handleSearchClear(triggerType);
        }
    };
    /**
     * 单纯的清空搜索条件
     */
    PureSelect.prototype.clearSearchValueOnly = function () {
        var shouldClearSearchValueAfterSelect = this.props.shouldClearSearchValueAfterSelect;
        if (!('searchValue' in this.props) && shouldClearSearchValueAfterSelect) {
            this.setState({
                searchValue: '',
            });
        }
    };
    /**
     * 多选模式 multiple/tag
     */
    PureSelect.prototype.handleMultipleSelect = function (keys, triggerType, key) {
        // 因为搜索后会设置 hightLight 为第一个item，menu渲染会自动滚动到 hightLight 的元素上面。
        // 所以设置 hightLight 为当前选中项避免滚动
        key &&
            this.state.visible &&
            this.setState({
                highlightKey: key,
            });
        var value = this.handleChange(keys, triggerType).value;
        this.updateSelectAllYet(value);
        this.clearSearchValueOnly();
    };
    PureSelect.prototype.updateSelectAllYet = function (value) {
        var _this = this;
        // multiple mode
        // is current state select all or not
        this.selectAllYet = false;
        if (Array.isArray(value)) {
            var selectAllValues = this.dataStore.getEnableDS().map(function (item) { return item.value; });
            if (selectAllValues.length <= value.length) {
                this.selectAllYet = true;
                selectAllValues.forEach(function (val) {
                    if (value.indexOf(val) === -1) {
                        _this.selectAllYet = false;
                    }
                });
            }
        }
    };
    /**
     * 渲染弹层的 header 内容
     * @override
     * @param {object} props
     */
    PureSelect.prototype.renderMenuHeader = function () {
        var _a;
        var _this = this;
        var _b = this.props, menuProps = _b.menuProps, searchSlotBefore = _b.searchSlotBefore, searchSlotAfter = _b.searchSlotAfter, searchPlaceholder = _b.searchPlaceholder, _c = _b.showSelectAll, showSelectAll = _c === void 0 ? true : _c;
        if (menuProps && 'header' in menuProps) {
            return menuProps.header;
        }
        var selectAllYet = this.selectAllYet;
        var cls = classNames((_a = {},
            _a["cn-multi-select-all"] = true,
            _a["cn-multi-selected"] = selectAllYet,
            _a));
        var searchEl = (React.createElement("div", { className: "cn-multi-select-header-search" },
            searchSlotBefore,
            React.createElement(CnInput, { value: this.state.searchValue, hasClear: true, placeholder: searchPlaceholder, onChange: this.handleSearch, onKeyDown: this.handleSearchKeyDown, className: "cn-multi-select-header-search-content", ref: this.saveSearchInputRef, innerAfter: React.createElement(CnIcon, { className: "cn-multi-select-header-search-icon", onClick: function () {
                        _this.handleSearch(_this.state.searchValue);
                    }, type: "search", style: { margin: 4 } }) }),
            searchSlotAfter));
        var selectAllEl = (React.createElement("div", { key: "all", className: cls, style: { lineHeight: 'unset' } },
            React.createElement(Menu, { selectMode: "multiple", onSelect: this.handleSelectAll, selectedKeys: selectAllYet ? ['selectAll'] : [], onClick: this.handleMenuBodyClick, onItemClick: this.handleItemClick, onMouseDown: preventDefault, focusable: false, dataSource: [
                    {
                        key: 'selectAll',
                        label: $i18n.get({ id: 'SelectAll', dm: '全选', ns: 'CnFastMultiSelect' }),
                    },
                ] })));
        return (React.createElement("div", { className: "cn-multi-select-header" },
            searchEl,
            showSelectAll && selectAllEl));
    };
    /**
     * 将更新后的 dataSource 通过 dataStore 进行处理
     * 数据源处理打平后返回
     * 数据源 map 缓存至 dataStore
     */
    PureSelect.prototype.setDataSource = function (props) {
        var dataSource = props.dataSource;
        if (Array.isArray(dataSource)) {
            return this.dataStore.updateByDS(dataSource);
        }
        return [];
    };
    PureSelect.prototype.setFirstHightLightKeyForMenu = function () {
        var _a = this.props, autoHighlightFirstItem = _a.autoHighlightFirstItem, onToggleHighlightItem = _a.onToggleHighlightItem;
        if (!autoHighlightFirstItem) {
            return;
        }
        // 设置高亮 item key
        if (this.dataStore.getMenuDS().length && this.dataStore.getEnableDS().length) {
            var highlightKey = "".concat(this.dataStore.getEnableDS()[0].value);
            this.setState({
                highlightKey: highlightKey,
            });
            onToggleHighlightItem(highlightKey, 'autoFirstItem');
        }
    };
    PureSelect.prototype.handleChange = function (value, triggerType) {
        var itemObj = getValueDataSource(value, this.dataStore.getMapDS());
        // 非受控模式清空内部数据
        if (!('value' in this.props)) {
            this.setState({
                value: itemObj.value,
                valueDS: itemObj.valueDS,
            });
        }
        var resultValue = {};
        resultValue.value = itemObj.value;
        resultValue.valueDS = itemObj.valueDS;
        if (this.useDetailValue()) {
            this.props.onChange(resultValue.valueDS, triggerType);
        }
        else {
            this.props.onChange(resultValue.value, triggerType, resultValue.valueDS);
        }
        return resultValue;
    };
    /**
     * Toggle highlight MenuItem
     * @private
     * @param {number} dir -1: up, 1: down
     */
    PureSelect.prototype.toggleHighlightItem = function (dir) {
        if (!this.state.visible) {
            this.setVisible(true, 'enter');
            return;
        }
        var maxCount = this.dataStore.getEnableDS().length;
        // When there is no enabled item
        if (!maxCount) {
            return false;
        }
        var highlightKey = this.state.highlightKey;
        var highlightIndex = -1;
        // find previous highlight index
        highlightKey !== null &&
            this.dataStore.getEnableDS().some(function (item, index) {
                if ("".concat(item.value) === highlightKey) {
                    highlightIndex = index;
                }
                return highlightIndex > -1;
            });
        // toggle highlight index
        highlightIndex += dir;
        if (highlightIndex < 0) {
            highlightIndex = maxCount - 1;
        }
        if (highlightIndex >= maxCount) {
            highlightIndex = 0;
        }
        // get highlight key
        var highlightItem = this.dataStore.getEnableDS()[highlightIndex];
        highlightKey = highlightItem ? "".concat(highlightItem.value) : null;
        this.setState({ highlightKey: highlightKey });
        this.scrollMenuIntoView();
        return highlightItem;
    };
    // scroll into focus item
    PureSelect.prototype.scrollMenuIntoView = function () {
        var _this = this;
        window.clearTimeout(this.highlightTimer);
        this.highlightTimer = window.setTimeout(function () {
            try {
                // eslint-disable-next-line react/no-find-dom-node
                var menuNode = findDOMNode(_this.menuRef);
                var itemNode = menuNode.querySelector('.cn-multi-select-menu-item.cn-multi-focused');
                itemNode && itemNode.scrollIntoViewIfNeeded && itemNode.scrollIntoViewIfNeeded();
            }
            catch (ex) {
                // I don't care...
            }
        });
    };
    /**
     * render popup children
     * @protected
     * @param {object} props
     */
    PureSelect.prototype.renderMenu = function () {
        var _a;
        var _this = this;
        var _b = this.props, state = _b.state, footer = _b.footer, notFoundContent = _b.notFoundContent, menuProps = _b.menuProps, popupContent = _b.popupContent;
        var _c = this.state, dataSource = _c.dataSource, highlightKey = _c.highlightKey;
        var value = this.state.value;
        var selectedKeys;
        if (isNull(value) || value.length === 0) {
            selectedKeys = [];
        }
        else {
            selectedKeys = [].concat(value).map(function (n) { return valueToSelectKey(n); });
        }
        var menuDataSource = this.renderMenuItem(dataSource);
        var isDataSourceEmpty = !menuDataSource || !menuDataSource.length;
        var menuClassName = classNames((_a = {},
            _a["cn-multi-select-menu"] = true,
            _a["cn-multi-select-menu-empty"] = isDataSourceEmpty,
            _a));
        var customProps = __assign(__assign({}, menuProps), { dataSource: menuDataSource, role: 'listbox', selectedKeys: selectedKeys, focusedKey: highlightKey, focusable: false, selectMode: 'multiple', onSelect: this.handleMenuSelect, onItemClick: this.handleItemClick, onClick: this.handleMenuBodyClick, onMouseDown: function (e) {
                if (!_this.searchInputRef || e.target !== _this.searchInputRef.inputRef) {
                    e.preventDefault();
                }
            }, className: menuClassName });
        if (isDataSourceEmpty) {
            customProps.error =
                notFoundContent || $i18n.get({ id: 'NoData', dm: '无数据', ns: 'CnFastMultiSelect' });
        }
        if (popupContent) {
            customProps.children = React.createElement("div", { className: "cn-multi-select-popup-wrap" }, popupContent);
        }
        if (state === 'loading') {
            customProps.loading = true;
        }
        return (React.createElement("div", { className: "cn-multi-select-menu-container" },
            this.renderMenuHeader(),
            React.createElement(Menu, __assign({}, customProps, { useVirtual: true })),
            footer));
    };
    /**
     * render menu item
     * @protected
     * @param {Array} dataSource
     */
    PureSelect.prototype.renderMenuItem = function (dataSource) {
        var _this = this;
        var _a = this.props, itemRender = _a.itemRender, showDataSourceChildren = _a.showDataSourceChildren;
        var searchValue = this.state.searchValue;
        // If it has.
        var searchKey = searchValue;
        return dataSource.map(function (item) {
            if (!item) {
                return null;
            }
            if (Array.isArray(item.children) && showDataSourceChildren) {
                return {
                    key: item.value,
                    type: 'group',
                    label: highlightRender(item.label, searchValue),
                    children: _this.renderMenuItem(item.children),
                    tooltip: item.label,
                };
            }
            else {
                var itemProps = {
                    type: 'item',
                    role: 'option',
                    key: item.value,
                    className: "cn-multi-select-menu-item",
                    disabled: item.disabled,
                    label: highlightRender(item.label || item.value, searchValue),
                    tooltip: item.label,
                };
                if (itemRender) {
                    itemProps.children = itemRender(item, searchKey);
                }
                return itemProps;
            }
        });
    };
    PureSelect.prototype.render = function () {
        var _a = this.props, popupProps = _a.popupProps, popupContainer = _a.popupContainer, popupClassName = _a.popupClassName, popupStyle = _a.popupStyle, popupContent = _a.popupContent, followTrigger = _a.followTrigger, disabled = _a.disabled, valueRender = _a.valueRender, fillProps = _a.fillProps, useDetailValue = _a.useDetailValue, placeholder = _a.placeholder, className = _a.className, style = _a.style, hasBorder = _a.hasBorder, state = _a.state, onBlur = _a.onBlur, onFocus = _a.onFocus, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave, triggerTagProps = _a.triggerTagProps;
        var _b = this.state, visible = _b.visible, value = _b.value, valueDS = _b.valueDS;
        var trigger = (React.createElement(SelectTrigger, { saveSelectDOM: this.saveSelectDOM, disabled: disabled, valueRender: valueRender, fillProps: fillProps, useDetailValue: useDetailValue, placeholder: placeholder, className: className, style: style, hasBorder: hasBorder, state: state, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, value: value, valueDS: valueDS, dataStore: this.dataStore, onClear: this.handleClear, onTagClose: this.handleTagClose, visible: visible, triggerTagProps: triggerTagProps }));
        var _props = {
            cache: false,
            popupProps: popupProps,
            disabled: disabled,
            afterOpen: this.afterOpen,
            followTrigger: followTrigger,
            visible: visible,
            onVisibleChange: this.setVisible,
            popupClassName: popupClassName,
            popupContent: popupContent,
            popupStyle: popupStyle,
            popupContainer: popupContainer,
            trigger: trigger,
            onFocus: onFocus,
            onBlur: onBlur,
        };
        return (React.createElement(SelectPopup, __assign({}, _props, { ref: this.savePopupRef }),
            React.createElement("div", { className: "cn-multi-select-popup-container" },
                this.renderMenu(),
                this.renderSelected())));
    };
    PureSelect.defaultProps = {
        size: 'medium',
        onChange: noop,
        onVisibleChange: noop,
        onToggleHighlightItem: noop,
        popupProps: {
            offset: [0, 4],
        },
        filterLocal: true,
        filter: filter,
        autoHighlightFirstItem: true,
        showDataSourceChildren: true,
        defaultHighlightKey: null,
        cacheValue: true,
        onSearch: noop,
        hasBorder: true,
        onRemove: noop,
        valueRender: function (item) {
            return item.label || item.value;
        },
        onKeyDown: noop,
        onFocus: noop,
        onBlur: noop,
        onMouseEnter: noop,
        onMouseLeave: noop,
        shouldClearSearchValueAfterSelect: false,
    };
    PureSelect.displayName = 'Select';
    return PureSelect;
}(React.Component));
export { PureSelect };
