var _a;
import { __assign, __extends, __read, __spreadArray } from "tslib";
import React from 'react';
import Form, { Field } from 'rc-field-form';
import { Form as AForm, Select, Balloon, Button, CnIcon, cnUtils } from '@cainiaofe/cn-ui';
import { DndProvider, createDndContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import $i18n from 'panda-i18n';
import { hierarchy } from 'd3-hierarchy';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import assign from 'lodash/assign';
import get from 'lodash/get';
import set from 'lodash/set';
import isFunction from 'lodash/isFunction';
import isEqual from 'lodash/isEqual';
import Drag, { UnDrag } from './drag';
import Drop from './drop';
import Link from './link';
import constants from './constants';
import { transformValue, alwaysTrue, getRealKey } from './utils';
// eslint-disable-next-line
import "./index.scss";
var FormItem = AForm.Item;
var Tooltip = Balloon.Tooltip;
function getHierarchyId() {
    var ids = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        ids[_i] = arguments[_i];
    }
    return ids.join('.');
}
// eslint-disable-next-line
var gIndex = 0;
var RELATIONS = constants.RELATIONS, ACTION = constants.ACTION, RELATION = constants.RELATION, LEAF = constants.LEAF, COMPONENT_HEIGHT = constants.COMPONENT_HEIGHT, COMPONENT_SPACE_HORIZONTAL = constants.COMPONENT_SPACE_HORIZONTAL, COMPONENT_SPACE_VERTICAL = constants.COMPONENT_SPACE_VERTICAL, COMPONENT_MARGIN = constants.COMPONENT_MARGIN, RELATION_WIDTH = constants.RELATION_WIDTH;
var DndContext = createDndContext(HTML5Backend);
var CnRuleTree = /** @class */ (function (_super) {
    __extends(CnRuleTree, _super);
    function CnRuleTree(props) {
        var _this = _super.call(this, props) || this;
        _this.pathByKey = Object.create(null);
        /**
         * operations
         */
        _this.handleAddCondition = function (data) {
            var children = get(_this.value, data.parentPath);
            children.push({});
            _this.onChange(_this.value);
        };
        _this.handleAddGroup = function (data) {
            var children = get(_this.value, data.parentPath);
            children.push({
                children: [{}],
            });
            _this.onChange(_this.value);
        };
        _this.handleDrop = function (dropProps, dragProps) {
            var parent = get(_this.value, dragProps.data.parentPath);
            var dropParent = get(_this.value, dropProps.data.parentPath);
            // 删掉
            var dragItem = parent.splice(dragProps.data.index, 1)[0];
            // 添加
            dropParent.splice(dropProps.data.index, 0, dragItem);
            _this.onChange(_this.value);
        };
        _this.handleDeleteGroup = function (node) {
            var deleteParent = node.parent;
            var deleteGrandPa = deleteParent.parent;
            if (!deleteGrandPa) {
                // root
                _this.value.children = [];
            }
            else if (!deleteGrandPa.data.parentPath) {
                // grandpa是root
                _this.value.children.splice(deleteParent.data.index, 1);
            }
            else {
                var dp = get(_this.value, deleteParent.data.parentPath);
                dp.splice(deleteParent.data.index, 1);
            }
            _this.onChange(_this.value);
        };
        _this.handleDelete = function (data, node) {
            var _a, _b;
            if (((_b = (_a = node === null || node === void 0 ? void 0 : node.parent) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b.length) === 2) {
                _this.handleDeleteSingleGroup(node);
            }
            else {
                var deleteParent = get(_this.value, data === null || data === void 0 ? void 0 : data.parentPath);
                deleteParent.splice(data === null || data === void 0 ? void 0 : data.index, 1);
                _this.onChange(_this.value);
            }
        };
        _this.onValuesChange = function (changedValues) {
            var _a;
            var _b, _c;
            var _d = _this.getNamePath(changedValues), namePath = _d.namePath, value = _d.value;
            set(_this.value, namePath, value);
            var _e = _this.props, cascades = _e.cascades, onCascade = _e.onCascade;
            var lastIndex = namePath.length - 1;
            var id = namePath[lastIndex];
            var path = namePath.slice(0, lastIndex);
            if (onCascade && cascades && cascades.indexOf(id) > -1) {
                var ctx = (_a = {
                        getValue: function (cid) {
                            var _a, _b;
                            return (_b = (_a = _this.form) === null || _a === void 0 ? void 0 : _a.getFieldValue) === null || _b === void 0 ? void 0 : _b.call(_a, __spreadArray(__spreadArray([], __read(path), false), [cid], false));
                        },
                        setValues: function (cid, value) {
                            var _a, _b;
                            var fields = [];
                            if (isObject(cid)) {
                                Object.keys(cid).forEach(function (k) {
                                    var name = __spreadArray(__spreadArray([], __read(path), false), [k], false);
                                    set(_this.value, name, value);
                                    fields.push({
                                        name: name,
                                        value: cid[k],
                                    });
                                });
                            }
                            else {
                                var name = __spreadArray(__spreadArray([], __read(path), false), [cid], false);
                                set(_this.value, name, value);
                                fields.push({
                                    name: name,
                                    value: value,
                                });
                            }
                            (_b = (_a = _this.form) === null || _a === void 0 ? void 0 : _a.setFields) === null || _b === void 0 ? void 0 : _b.call(_a, fields);
                        },
                        id: id
                    },
                    _a[constants.uniqueKey] = get(_this.value, __spreadArray(__spreadArray([], __read(path), false), [constants.uniqueKey], false)),
                    _a.value = (_c = (_b = _this.form) === null || _b === void 0 ? void 0 : _b.getFieldValue) === null || _c === void 0 ? void 0 : _c.call(_b, namePath),
                    _a);
                onCascade(ctx);
            }
            _this.onChange(_this.value);
        };
        /**
         * icon actions 是否可点击
         */
        _this.renderActions = function (data) {
            var _a = _this.props, canAddCondition = _a.canAddCondition, canAddConditionGroup = _a.canAddConditionGroup;
            // const curLevel = data.parentPath.reduce((memo, item) => {
            //   if(item === 'children') {
            //     memo += 1;
            //   }
            //   return memo;
            // }, 0);
            var finalCanAddCondition = canAddCondition === null || canAddCondition === void 0 ? void 0 : canAddCondition(data);
            var finalCanAddConditionGroup = canAddConditionGroup === null || canAddConditionGroup === void 0 ? void 0 : canAddConditionGroup(data);
            return (React.createElement("div", { className: "actions" },
                finalCanAddCondition ? (React.createElement(Tooltip, { trigger: React.createElement("div", { className: "action-icon", onClick: function () { return finalCanAddCondition && _this.handleAddCondition(data); } },
                        React.createElement(CnIcon, { type: "add", size: "medium", className: "cn-rule-tree-icon ".concat(finalCanAddCondition ? '' : 'action-icon-disabled') })) }, $i18n.get({
                    id: 'AddCondition',
                    dm: '添加条件',
                    ns: 'CnRuleTree',
                }))) : null,
                finalCanAddConditionGroup || true ? (React.createElement(Tooltip, { trigger: React.createElement("div", { className: "action-icon", onClick: function () { return finalCanAddConditionGroup && _this.handleAddGroup(data); } },
                        React.createElement(CnIcon, { custom: true, type: "list", size: "medium", className: "cn-rule-tree-icon ".concat(finalCanAddConditionGroup ? '' : 'action-icon-disabled') })) }, $i18n.get({
                    id: 'AddConditionGroup',
                    dm: '添加条件组',
                    ns: 'CnRuleTree',
                }))) : null,
                !finalCanAddCondition && !finalCanAddConditionGroup ? (React.createElement("div", { className: "action-icon" },
                    React.createElement(CnIcon, { className: "cn-rule-tree-icon", type: "lock", size: "medium" }))) : null));
        };
        _this.dndType = "dndType-".concat(gIndex);
        gIndex += 1;
        var relations = _this.getUsefulRelations();
        _this.value = transformValue(props.value, relations);
        _this.relationPaths = [];
        return _this;
    }
    CnRuleTree.prototype.componentDidUpdate = function (preProps) {
        var _this = this;
        var _a, _b;
        var _c = this.props, relations = _c.relations, value = _c.value;
        if (!isUndefined(value)) {
            try {
                if (!isEqual(value, preProps.value)) {
                    this.value = transformValue(value, this.getUsefulRelations());
                    this.onChange(this.value);
                    console.log('update value', this.value);
                }
            }
            catch (e) {
                console.error(e);
            }
        }
        var initRelations = [];
        this.relationPaths.forEach(function (name) {
            var _a, _b;
            var relationValue = (_b = (_a = _this.form) === null || _a === void 0 ? void 0 : _a.getFieldValue) === null || _b === void 0 ? void 0 : _b.call(_a, name);
            if (isUndefined(relationValue)) {
                // 这里get获取老的this.value
                var initialValue = get(_this.value, name) || get(relations, '0.value');
                initRelations.push({
                    name: name,
                    value: initialValue,
                });
                // 这里set设置新的this.value
                set(_this.value, name, initialValue);
            }
        });
        (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.setFields) === null || _b === void 0 ? void 0 : _b.call(_a, initRelations);
    };
    CnRuleTree.prototype.getUsefulRelations = function () {
        var _a, _b, _c, _d;
        return (_d = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.rootRelations) !== null && _b !== void 0 ? _b : (_c = this.props) === null || _c === void 0 ? void 0 : _c.relations) !== null && _d !== void 0 ? _d : RELATIONS;
    };
    CnRuleTree.prototype.handleDeleteSingleGroup = function (node) {
        var _a, _b, _c;
        if (!(node === null || node === void 0 ? void 0 : node.parent) || !((_a = node === null || node === void 0 ? void 0 : node.parent) === null || _a === void 0 ? void 0 : _a.children)) {
            return;
        }
        if (((_c = (_b = node === null || node === void 0 ? void 0 : node.parent) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c.length) === 2) {
            var parent = (node !== null && node !== void 0 ? node : {}).parent;
            this.handleDeleteGroup(node);
            this.handleDeleteSingleGroup(parent);
        }
    };
    CnRuleTree.prototype.onChange = function (value) {
        var _a, _b, _c;
        if (isObject(value) && !((_a = value.children) === null || _a === void 0 ? void 0 : _a.length)) {
            value.children = [];
        }
        (_c = (_b = this.form) === null || _b === void 0 ? void 0 : _b.setFieldsValue) === null || _c === void 0 ? void 0 : _c.call(_b, value);
        var onChange = this.props.onChange;
        /** ruleTree 本质为非受控组件，如果希望外部控制value，需要同时修改组件key */
        this.setState({});
        if (onChange) {
            onChange(value);
        }
    };
    CnRuleTree.prototype.getNamePath = function (changedValues) {
        var namePath = [];
        var retValue = '';
        var fillNamePath = function (value) {
            if (isArray(value)) {
                value.forEach(function (v, i) {
                    if (!isUndefined(v)) {
                        namePath.push(i);
                        fillNamePath(v);
                    }
                });
            }
            else if (isObject(value)) {
                var key = Object.keys(value)[0];
                namePath.push(key);
                if (key === 'children') {
                    // children
                    fillNamePath(value[key]);
                }
                else {
                    retValue = value[key];
                }
            }
        };
        fillNamePath(changedValues);
        return {
            namePath: namePath,
            value: retValue,
        };
    };
    /**
     * @param children
     * @param parentPath
     * @param canDrag
     * @param level
     * @returns
     * 重新给value增加一些研发态的属性，为后续的逻辑计算提供便利性
     * 比如：parentPath、path、level、type、canDrag等，且为每个children都生成最后一个操作item，type: action
     */
    CnRuleTree.prototype.addDropAreaAndOperation = function (children, parentPath, canDrag, level) {
        var _a;
        var _this = this;
        children = children || [];
        var result = [];
        if (children.length) {
            children.forEach(function (child, index) {
                var _a, _b;
                var path = __spreadArray(__spreadArray([], __read(parentPath), false), [index], false);
                var node = assign({}, child, {
                    type: LEAF,
                    index: index,
                    parentPath: parentPath,
                    path: path,
                });
                if (child.children) {
                    // 关系节点
                    (_b = (_a = _this.relationPaths) === null || _a === void 0 ? void 0 : _a.push) === null || _b === void 0 ? void 0 : _b.call(_a, path);
                    node.type = RELATION;
                    node.children = _this.addDropAreaAndOperation(child.children, path.concat(['children']), canDrag, level + 1);
                    path.push('relation');
                }
                result.push(node);
            });
        }
        if (canDrag) {
            result.push((_a = {
                    index: children.length,
                    type: ACTION
                },
                _a[constants.uniqueKey] = "action-".concat(parentPath.join('-')),
                _a.parentPath = parentPath,
                _a.level = level + 1,
                _a));
        }
        return result;
    };
    CnRuleTree.prototype.buildNodes = function (root, canDrag) {
        var leafCount = 0;
        // 画布高度
        var height = 0;
        var lastLeafNode = null;
        /* eslint-disable no-param-reassign */
        var nodes = root.eachAfter(function (d) {
            var _a, _b;
            d.y =
                d.depth * (RELATION_WIDTH + COMPONENT_SPACE_HORIZONTAL + (canDrag ? COMPONENT_HEIGHT : 0));
            if (canDrag && d.depth > 0) {
                d.y -= COMPONENT_SPACE_HORIZONTAL;
            }
            if (d.data.type !== RELATION) {
                d.x = leafCount * (COMPONENT_HEIGHT + COMPONENT_SPACE_VERTICAL);
                leafCount += 1;
            }
            else {
                d.x =
                    d.children && d.children.length
                        ? (d.children[0].x + d.children[d.children.length - 1].x) / 2
                        : 0;
                if (!d.parent && Array.isArray(d === null || d === void 0 ? void 0 : d.children)) {
                    // 非disabled的时候，最后一个节点是action
                    height = ((_a = d.children[d.children.length - 1]) === null || _a === void 0 ? void 0 : _a.x) + COMPONENT_HEIGHT;
                }
            }
            if (((_b = d === null || d === void 0 ? void 0 : d.data) === null || _b === void 0 ? void 0 : _b.type) === 'leaf') {
                // 最后一个业务数据结点
                lastLeafNode = d;
            }
        });
        // 业务最后一个结点高度 max  最后一个action
        height = Math.max(height, COMPONENT_HEIGHT + (lastLeafNode === null || lastLeafNode === void 0 ? void 0 : lastLeafNode.x)); // 使用最后一个叶子结点结算逻辑比较准确
        /* eslint-enable no-param-reassign */
        return {
            nodes: nodes,
            height: height,
        };
    };
    CnRuleTree.prototype.createFields = function (nodes, canDrag) {
        var _this = this;
        var _a = this.props, structure = _a.structure, relations = _a.relations, rootRelations = _a.rootRelations, canRootChange = _a.canRootChange;
        var value = this.value;
        var result = [];
        var DragItem = canDrag ? Drag : UnDrag;
        nodes.forEach(function (node, nindex) {
            var _a, _b, _c;
            var data = node.data, x = node.x, y = node.y, parent = node.parent;
            var type = data.type, index = data.index, path = data.path;
            var realKey = getRealKey(data);
            if (!parent) {
                // root 节点
                var initialValue = get(value, 'relation') || get(rootRelations, '0.value');
                var fieldElm = (React.createElement(Field, { name: path, key: "root" }, canRootChange ? (React.createElement(Select, { disabled: !canDrag, style: {
                        width: RELATION_WIDTH,
                        minWidth: RELATION_WIDTH,
                        position: 'absolute',
                        left: y,
                        top: x + COMPONENT_MARGIN / 2,
                    } }, rootRelations.map(function (relation) {
                    var _a;
                    return (React.createElement(Select.Option, { value: relation.value, key: relation.value }, (_a = relation.label) !== null && _a !== void 0 ? _a : relation.text));
                }))) : (React.createElement(Button, { style: {
                        width: RELATION_WIDTH,
                        minWidth: RELATION_WIDTH,
                        position: 'absolute',
                        left: y,
                        top: x + COMPONENT_MARGIN / 2,
                    } }, (_b = (_a = rootRelations === null || rootRelations === void 0 ? void 0 : rootRelations[0]) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : (_c = rootRelations === null || rootRelations === void 0 ? void 0 : rootRelations[0]) === null || _c === void 0 ? void 0 : _c.text))));
                set(_this.value, path, initialValue);
                result.push(fieldElm);
            }
            else {
                // 非root节点，关系节点/叶子节点/action节点/drop节点
                if (canDrag) {
                    // drop
                    var dropX = index === 0
                        ? x - COMPONENT_SPACE_VERTICAL
                        : x - (x - (nodes[nindex - 1].x + COMPONENT_HEIGHT) + COMPONENT_SPACE_VERTICAL) / 2;
                    /* eslint-disable react/jsx-no-bind */
                    var dropEle = (React.createElement(Drop, { x: y, y: dropX, node: node, data: data, onDrop: _this.handleDrop.bind(_this), canDrag: canDrag, type: _this.dndType, key: getHierarchyId(realKey, 'drop') }));
                    /* eslint-enable react/jsx-no-bind */
                    result.push(dropEle);
                }
                var ele = void 0;
                if (type === RELATION) {
                    // 关系节点
                    ele = (React.createElement(DragItem, { x: y, y: x, node: node, data: data, type: _this.dndType, key: getHierarchyId(realKey, 'relation') },
                        React.createElement(Field, { name: path }, function (control) {
                            var _a;
                            return (React.createElement(FormItem, null,
                                React.createElement(Select, __assign({ disabled: !canDrag, style: {
                                        width: RELATION_WIDTH,
                                        minWidth: RELATION_WIDTH,
                                    } }, control), (_a = relations === null || relations === void 0 ? void 0 : relations.map) === null || _a === void 0 ? void 0 : _a.call(relations, function (relation) {
                                    var _a;
                                    return (React.createElement(Select.Option, { value: relation.value, key: relation.value }, (_a = relation.label) !== null && _a !== void 0 ? _a : relation.text));
                                }))));
                        })));
                }
                else if (type === LEAF) {
                    // 叶子节点
                    ele = (React.createElement(DragItem, { x: y, y: x, data: data, node: node, type: _this.dndType, key: getHierarchyId(realKey, 'leaf') },
                        structure && structure.length
                            ? structure.map(function (field, i) {
                                return _this.renderField(field, i, canDrag, path, realKey);
                            })
                            : null,
                        canDrag && (React.createElement(CnIcon, { style: {
                                cursor: 'pointer',
                            }, className: "cn-rule-tree-icon delete-icon", type: "delete", size: "medium", onClick: function () { return _this.handleDelete(data, node); } }))));
                }
                else {
                    // action节点
                    ele = (React.createElement("div", { style: {
                            position: 'absolute',
                            left: y,
                            top: x,
                        }, key: getHierarchyId(realKey, 'action') }, _this.renderActions(data)));
                }
                result.push(ele);
            }
        });
        return result;
    };
    CnRuleTree.prototype.createLinks = function (links, canDrag) {
        return links.map(function (link) {
            var source = link.source, target = link.target;
            var sourceKey = getRealKey(source === null || source === void 0 ? void 0 : source.data);
            var targetKey = getRealKey(target === null || target === void 0 ? void 0 : target.data);
            var x;
            if (!source.parent) {
                x = source.y + RELATION_WIDTH;
            }
            else {
                x = source.y + RELATION_WIDTH + (canDrag ? COMPONENT_SPACE_HORIZONTAL : 0);
            }
            return (React.createElement(Link, { key: getHierarchyId(sourceKey, targetKey), source: { x: x, y: source.x }, target: { x: target.y, y: target.x } }));
        });
    };
    /**
     * 渲染field
     */
    CnRuleTree.prototype.renderField = function (field, index, canDrag, path, key) {
        var _this = this;
        var id = field.id, rules = field.rules, render = field.render;
        var namePath = __spreadArray(__spreadArray([], __read(path), false), [id], false);
        var element;
        return (React.createElement(Field, { name: namePath, key: getHierarchyId(key, id), rules: rules }, function (control, meta, form) {
            var _a, _b;
            if (render) {
                var ctx = {
                    getValue: function (cid) {
                        var _a, _b;
                        return (_b = (_a = _this.form) === null || _a === void 0 ? void 0 : _a.getFieldValue) === null || _b === void 0 ? void 0 : _b.call(_a, __spreadArray(__spreadArray([], __read(path), false), [cid], false));
                    },
                    setValues: function (cid, value) {
                        var _a, _b;
                        if (!_this.form)
                            return;
                        var fields = [];
                        if (isObject(cid)) {
                            Object.keys(cid).forEach(function (k) {
                                var name = __spreadArray(__spreadArray([], __read(path), false), [k], false);
                                set(_this.value, name, value);
                                fields.push({
                                    name: name,
                                    value: cid[k],
                                });
                            });
                        }
                        else {
                            var name = __spreadArray(__spreadArray([], __read(path), false), [cid], false);
                            set(_this.value, name, value);
                            fields.push({
                                name: name,
                                value: value,
                            });
                        }
                        (_b = (_a = _this.form) === null || _a === void 0 ? void 0 : _a.setFields) === null || _b === void 0 ? void 0 : _b.call(_a, fields);
                    },
                    id: id,
                    key: key,
                    value: control.value,
                    fullPath: __spreadArray(__spreadArray([], __read(path), false), [id], false),
                };
                element = render(ctx);
            }
            else {
                element = field.element;
            }
            var validateState = (meta.errors || []).length > 0 ? 'error' : undefined;
            var help = (meta.errors || []).length > 0 ? meta.errors[0] : undefined;
            var childElement = React.cloneElement(element, __assign(__assign({ disabled: (_b = (_a = element === null || element === void 0 ? void 0 : element.props) === null || _a === void 0 ? void 0 : _a.disabled) !== null && _b !== void 0 ? _b : !canDrag }, control), { onChange: function () {
                    var _a;
                    var _b;
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    isFunction(control === null || control === void 0 ? void 0 : control.onChange) && control.onChange.apply(control, __spreadArray([], __read(args), false));
                    isFunction((_b = element === null || element === void 0 ? void 0 : element.props) === null || _b === void 0 ? void 0 : _b.onChange) && (_a = element.props).onChange.apply(_a, __spreadArray([], __read(args), false));
                } }));
            return (React.createElement(FormItem, { help: help, validateState: validateState }, childElement));
        }));
    };
    CnRuleTree.prototype.render = function () {
        var _this = this;
        var _a;
        var _b = this.props, style = _b.style, _c = _b.className, className = _c === void 0 ? '' : _c, disabled = _b.disabled, value = _b.value;
        var canDrag = !disabled;
        this.value = transformValue(value, this.getUsefulRelations());
        var finalValue = assign({ type: RELATION, path: ['relation'] }, this.value);
        finalValue.type = (_a = finalValue.type) !== null && _a !== void 0 ? _a : RELATION;
        // 增加研发属性
        finalValue.children = this.addDropAreaAndOperation(this.value.children, ['children'], canDrag, 0);
        this.relationPaths = []; // 重置
        var root = hierarchy(finalValue);
        var _d = this.buildNodes(root, canDrag), nodes = _d.nodes, height = _d.height; // 计算 x,y
        var flattenNodes = nodes.descendants();
        var flattenLinks = nodes.links();
        var fields = this.createFields(flattenNodes, canDrag);
        var links = this.createLinks(flattenLinks, canDrag);
        return (React.createElement(DndProvider, { manager: DndContext.dragDropManager },
            React.createElement("div", { className: "cn-rule-tree ".concat(className), style: assign({ height: height, style: style }) },
                React.createElement(Form, { component: "div", ref: function (ref) {
                        _this.form = ref;
                    }, onValuesChange: this.onValuesChange, initialValues: Array.isArray(this.value) ? {} : this.value }, fields),
                links)));
    };
    CnRuleTree.displayName = 'CnRuleTree';
    CnRuleTree.defaultProps = {
        rootRelations: RELATIONS,
        relations: RELATIONS,
        canAddCondition: alwaysTrue,
        canAddConditionGroup: alwaysTrue,
        canRootChange: true,
    };
    return CnRuleTree;
}(React.Component));
export { CnRuleTree };
(_a = cnUtils === null || cnUtils === void 0 ? void 0 : cnUtils.i18nRegister) === null || _a === void 0 ? void 0 : _a.call(cnUtils, CnRuleTree);
