import React from 'react';
import { CnIcon, CnImageViewer } from '@cainiaofe/cn-ui';
import { pcCssPrefix } from '../constant/css-prefix';
export var UploadListItem = function (props) {
    var file = props.file, value = props.value, index = props.index, onChange = props.onChange, disabled = props.disabled;
    var onPreview = function (_file) {
        CnImageViewer.open({
            value: _file.dingTalkUrl,
            src: value.map(function (item) { return item.dingTalkUrl; }),
        });
    };
    return (React.createElement("div", { className: "".concat(pcCssPrefix, "-item") },
        React.createElement("div", { style: { backgroundImage: "url(".concat(file.dingTalkUrl, ")") }, className: "".concat(pcCssPrefix, "-item-img"), onClick: function () { return onPreview(file); } }),
        disabled ? null : (React.createElement("div", { className: "".concat(pcCssPrefix, "-item-remove"), onClick: function () {
                onChange(value.filter(function (item, _index) { return _index !== index; }));
            } },
            React.createElement(CnIcon, { type: "delete", size: "small" })))));
};
