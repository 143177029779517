import { __read, __spreadArray } from "tslib";
import './cn-ding-talk-scan-input.scss';
import $i18n from 'panda-i18n';
import * as React from 'react';
import classNames from 'classnames';
import { cnUtils, CnIcon } from '@cainiaofe/cn-ui';
import { pcCssPrefix } from '../constant/css-prefix';
import { ValueList } from './value-list';
import { CnDingTalkScanInputReadonly } from './cn-ding-talk-scan-input-readonly';
export var CnDingTalkScanInput = function (props) {
    var _a;
    var readOnly = props.readOnly, disabled = props.disabled, _b = props.placeholder, placeholder = _b === void 0 ? $i18n.get({
        id: 'PleaseEnterTheBarcodeOrScanCodeT_1666274012',
        dm: '请录入条码或扫码添加',
        ns: 'CnDingTalkScanInput',
    }) : _b;
    var _c = __read(cnUtils.useControlled(props), 2), _d = _c[0], value = _d === void 0 ? [] : _d, onChange = _c[1];
    if (!Array.isArray(value)) {
        return null;
    }
    if (readOnly) {
        return React.createElement(CnDingTalkScanInputReadonly, { value: value });
    }
    return (React.createElement("div", { className: classNames(pcCssPrefix, (_a = {}, _a["".concat(pcCssPrefix, "--disabled")] = disabled, _a)) },
        React.createElement("div", { className: classNames("".concat(pcCssPrefix, "-list")) },
            React.createElement(ValueList, { value: value, onChange: onChange }),
            React.createElement("input", { disabled: disabled, style: { width: '100%' }, className: classNames("".concat(pcCssPrefix, "-input")), placeholder: value.length ? '' : placeholder, onKeyDown: function (e) {
                    if (e.key === 'Enter') {
                        onChange(__spreadArray(__spreadArray([], __read(value), false), [e.currentTarget.value], false));
                        e.currentTarget.value = '';
                    }
                } })),
        React.createElement(CnIcon, { type: "scan", outerClassName: "cn-scan-icon" })));
};
CnDingTalkScanInput.displayName = 'CnDingTalkScanInput';
