import { __assign } from "tslib";
/**
 * 将 mtop dataType=jsonp 时，数据格式转化为标准数据，例如 boolean number 返回 string，故将其转化
 * @param data 传入数据
 * @returns 返回处理后数据
 */
export function formatJsonpDataForMtop(dataArr, isJsonpForMtop) {
    // 非 jsonp 直接返回
    if (!isJsonpForMtop)
        return dataArr;
    var data = dataArr.map(function (item) { return (__assign(__assign({}, item), { disable: transferStringToOrigin(item.disable), leaf: transferStringToOrigin(item.leaf), level: transferStringToOrigin(item.level) })); });
    return data;
}
function transferStringToOrigin(field) {
    if (typeof field === 'string') {
        return JSON.parse(field);
    }
    return field;
}
