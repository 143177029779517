import { __assign, __awaiter, __generator } from "tslib";
import { CndzkEntranceApi } from './apiModel';
import { formatJsonpDataForMtop } from './formatJsonp';
var Services = /** @class */ (function () {
    function Services(props) {
        this.config = {
            apiModel: 'mtop',
            applicationType: 'query',
            apiType: 'cndzk',
            businessId: '',
            realm: 'default',
            urlBase: '',
            iso: 'CN',
            isJsonpForMtop: false,
            hideSensitiveDivision: true,
        };
        this.config = __assign(__assign({}, this.config), (props.config || {}));
        this.api = {
            get: function () { },
        };
        if (this.config.apiModel === 'api') {
            if (!this.config.urlBase) {
                console.error('CnAddress Error: urlBase is required');
                return;
            }
            this.api = new CndzkEntranceApi(__assign({ urlBase: this.config.urlBase }, this.config));
        }
        else if (this.config.apiModel === 'mtop') {
            if (!this.config.businessId) {
                console.error('CnAddress Error: businessId is required');
                return;
            }
            this.api = new CndzkEntranceApi(__assign({}, this.config));
        }
    }
    Services.prototype.getTopLevelInfo = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.get('topLevelInfo', __assign({}, params))];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, formatJsonpDataForMtop([res], this.config.isJsonpForMtop)[0]];
                }
            });
        });
    };
    Services.prototype.getTopLevelList = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var res, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.get('topLevelList', __assign({}, params))];
                    case 1:
                        res = _a.sent();
                        data = [];
                        if (!Array.isArray(res))
                            res = [];
                        res.map(function (i) {
                            if (i.regions) {
                                data = data.concat(i.regions || []);
                            }
                            else {
                                data.push(i);
                            }
                        });
                        return [2 /*return*/, data];
                }
            });
        });
    };
    Services.prototype.getRegionChain = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.get('regionChain', __assign({}, params))];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, formatJsonpDataForMtop(res, this.config.isJsonpForMtop)];
                }
            });
        });
    };
    Services.prototype.getChildren = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var res, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.get('children', __assign({}, params))];
                    case 1:
                        res = _a.sent();
                        data = (res === null || res === void 0 ? void 0 : res.divisions) || (Array.isArray(res) && res) || [];
                        return [2 /*return*/, formatJsonpDataForMtop(data, this.config.isJsonpForMtop)];
                }
            });
        });
    };
    Services.prototype.getRegionChainByName = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.get('regionChainByName', __assign({}, params))];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res.map(function (item) { return formatJsonpDataForMtop(item, _this.config.isJsonpForMtop); })];
                }
            });
        });
    };
    Services.prototype.getRegionChainByParse = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.get('regionChainByParse', __assign(__assign({}, params), { countryCode: 'CN' }))];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, formatJsonpDataForMtop(res, this.config.isJsonpForMtop)];
                }
            });
        });
    };
    return Services;
}());
export default Services;
export function getFullDataByCdn(cdnUrl) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (window.__cn_address_CDNDATA && window.__cn_address_CDNDATA[cdnUrl])
                return [2 /*return*/, window.__cn_address_CDNDATA[cdnUrl]];
            return [2 /*return*/, new Promise(function (resolve) {
                    var dataSDK = document.createElement('script');
                    dataSDK.src = cdnUrl;
                    dataSDK.onload = function () {
                        var _a;
                        var _b, _c;
                        if ((_b = window.__cn_address_CDNDATA) === null || _b === void 0 ? void 0 : _b[cdnUrl]) {
                            resolve(window.__cn_address_CDNDATA[cdnUrl]);
                            return;
                        }
                        window.__cn_address_CDNDATA = (_a = {},
                            _a[cdnUrl] = (_c = window.tdist) === null || _c === void 0 ? void 0 : _c.data,
                            _a);
                        if (window.tdist) {
                            window.tdist = null;
                        }
                        resolve(window.__cn_address_CDNDATA[cdnUrl]);
                    };
                    document.body.appendChild(dataSDK);
                })];
        });
    });
}
