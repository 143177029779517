var _a;
import { __assign, __rest } from "tslib";
import $i18n from 'panda-i18n';
import * as React from 'react';
import { CnReadOnly, cnUtils } from '@cainiaofe/cn-ui';
import { useCnRequest } from '@cainiaofe/cn-ui-common';
import { PureSelect } from '../pure-select';
export var CnFastMultiSelect = React.forwardRef(function (props, ref) {
    var _a;
    var _b = props.requestConfig, requestConfig = _b === void 0 ? {} : _b, otherProps = __rest(props, ["requestConfig"]);
    var searchFormat = requestConfig.searchFormat, otherConfig = __rest(requestConfig, ["searchFormat"]);
    var insertSelectProps = {};
    var isRemoteDataSource = React.useMemo(function () {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    var formatResult = function (res) {
        var _a;
        if (Array.isArray(res)) {
            return res;
        }
        else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
            return res.data;
        }
        else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
            return res.data.dataSource;
        }
        return [];
    };
    if (!requestConfig.formatResult) {
        requestConfig.formatResult = formatResult;
    }
    var _c = useCnRequest(__assign({ ready: isRemoteDataSource }, otherConfig)), _d = _c.data, data = _d === void 0 ? [] : _d, run = _c.run;
    // onSearch 自动包装
    if (!otherProps.onSearch && otherProps.filterLocal === false) {
        insertSelectProps.onSearch = function (inputValue, actionType) {
            var searchValue = searchFormat ? searchFormat(inputValue, actionType) : { key: inputValue };
            run(searchValue);
        };
    }
    if (isRemoteDataSource) {
        insertSelectProps.dataSource = data;
    }
    if (otherProps.readOnly) {
        return (React.createElement(CnReadOnly, { allowCopy: true, type: "enum", value: otherProps.value, dataSource: (_a = insertSelectProps === null || insertSelectProps === void 0 ? void 0 : insertSelectProps.dataSource) !== null && _a !== void 0 ? _a : otherProps.dataSource }));
    }
    return React.createElement(PureSelect, __assign({ ref: ref }, otherProps, insertSelectProps));
});
CnFastMultiSelect.displayName = 'CnFastMultiSelect';
CnFastMultiSelect.defaultProps = {
    placeholder: $i18n.get({
        id: 'PleaseSelect',
        dm: '请选择',
        ns: 'CnFastMultiSelect',
    }),
};
(_a = cnUtils === null || cnUtils === void 0 ? void 0 : cnUtils.i18nRegister) === null || _a === void 0 ? void 0 : _a.call(cnUtils, CnFastMultiSelect);
