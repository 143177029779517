import $i18n from 'panda-i18n';
var COMPONENT_MARGIN = 10;
export default {
    RELATION: 'relation',
    LEAF: 'leaf',
    ACTION: 'action',
    uniqueKey: '__key',
    RELATION_WIDTH: 100,
    COMPONENT_HEIGHT: 40,
    COMPONENT_SPACE_VERTICAL: 16,
    COMPONENT_SPACE_HORIZONTAL: 38, // 14 + 8(margin-right) + 8(padding) + 2
    COMPONENT_MARGIN: COMPONENT_MARGIN,
    RELATIONS: [
        {
            value: 'and',
            text: $i18n.get({ id: 'And', dm: '且', ns: 'CnRuleTree' }),
        },
        {
            value: 'or',
            text: $i18n.get({ id: 'Or', dm: '或', ns: 'CnRuleTree' }),
        },
    ],
};
