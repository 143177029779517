var _this = this;
import { __assign, __read, __rest } from "tslib";
import React from 'react';
import cx from 'classnames';
import { CnIcon, CnCheckbox } from '@cainiaofe/cn-ui';
import { KEYCODE } from '@cainiaofe/cn-ui/_fusion/lib/util';
import Item from '@cainiaofe/cn-ui/_fusion/lib/menu/view/item';
export var SelectableItem = function (props) {
    var _a, _b;
    var _key = props._key, root = props.root, className = props.className, disabled = props.disabled, helper = props.helper, label = props.label, children = props.children, tooltip = props.tooltip, needIndent = props.needIndent, loading = props.loading, 
    // 展开状态
    hasExpand = props.hasExpand, onExpand = props.onExpand, expanded = props.expanded, cnCheckboxProps = props.cnCheckboxProps, onClick = props.onClick, others = __rest(props, ["_key", "root", "className", "disabled", "helper", "label", "children", "tooltip", "needIndent", "loading", "hasExpand", "onExpand", "expanded", "cnCheckboxProps", "onClick"]);
    var _c = root.props, prefix = _c.prefix, selectMode = _c.selectMode;
    var getSelected = function () {
        var propsSelectMode = root.props.selectMode;
        var selectedKeys = root.state.selectedKeys;
        return props.selected || (!!propsSelectMode && selectedKeys.indexOf(_key) > -1);
    };
    var _d = __read(React.useState(getSelected()), 2), selected = _d[0], setSelected = _d[1];
    React.useEffect(function () {
        setSelected(getSelected());
    }, [props.selected, root.props.selectMode, root.state.selectedKeys]);
    var handleSelect = function (e) {
        // debugger;
        if (props.onSelect) {
            props.onSelect(!selected, _this, e);
        }
        else {
            root.handleSelect(_key, !selected, _this);
        }
    };
    var handleKeyDown = function (e) {
        if (e.keyCode === KEYCODE.SPACE && !props.disabled) {
            handleSelect(e);
        }
        props.onKeyDown && props.onKeyDown(e);
    };
    var handleClick = function (e) {
        handleSelect(e);
        props.onClick && props.onClick(e);
    };
    var newProps = __assign({ _key: _key, root: root, disabled: disabled, type: 'item', className: cx((_a = {
                'cn-multi-select-menu-item': true
            },
            _a["".concat(prefix, "selected")] = selected,
            _a[className] = !!className,
            _a)), onKeyDown: handleKeyDown, onClick: !disabled ? handleClick : props.onClick, needIndent: needIndent }, others);
    var textProps = {};
    if ('selectMode' in root.props) {
        textProps['aria-selected'] = selected;
    }
    // 优先级 children > label
    if (typeof label === 'string') {
        textProps.title = label;
    }
    if (typeof children === 'string') {
        textProps.title = children;
    }
    if (typeof tooltip === 'string') {
        textProps.title = tooltip;
    }
    var innerText = (React.createElement("div", __assign({ className: "cn-multi-select-menu-item-text" }, textProps), children || label));
    var checkProps = __assign({ disabled: disabled, checked: selected }, cnCheckboxProps);
    var innerRightIcon;
    if (loading) {
        innerRightIcon = (React.createElement(CnIcon, { className: "cn-multi-select-menu-icon-right", type: "loading", size: "xs" }));
    }
    // 对于可展开模式，选中状态及选中函数进行特殊处理
    if (hasExpand) {
        checkProps.onChange = handleClick;
        if (!disabled) {
            newProps.onClick = onExpand;
        }
        newProps.className = cx((_b = {
                'cn-multi-select-menu-item': true
            },
            _b["".concat(prefix, "selected")] = expanded,
            _b[className] = !!className,
            _b));
        if (!innerRightIcon) {
            innerRightIcon = (React.createElement(CnIcon, { className: "cn-multi-select-menu-icon-right", type: "arrow-right", size: "xs" }));
        }
    }
    return (React.createElement(Item, __assign({}, newProps),
        React.createElement("div", { className: "cn-multi-select-menu-item-header" },
            selectMode === 'multiple' && React.createElement(CnCheckbox, __assign({}, checkProps)),
            innerText),
        React.createElement("div", { className: "cn-multi-select-menu-item-footer" },
            helper ? React.createElement("div", { className: "".concat(prefix, "menu-item-helper") }, helper) : null,
            innerRightIcon)));
};
SelectableItem.menuChildType = 'item';
