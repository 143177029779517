import { __assign, __awaiter, __extends, __generator, __read, __spreadArray } from "tslib";
import $i18n from 'panda-i18n';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CnAsyncSelect, CnIcon, CnTooltip, CnReadOnly } from '@cainiaofe/cn-ui';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isNumber from 'lodash/isNumber';
import isEqual from 'lodash/isEqual';
import { func, obj, dom, cascaders, levels, configs, handles, KEYCODE } from './utils/index';
import Services, { getFullDataByCdn } from './utils/services';
import { getDetailValueDS, transferObjToAvailable, checkUseDetail } from './utils/useDetail';
import { preHandlerDataLanguage, getName } from './utils/languages';
import { getFinalValue } from './utils/value';
import { ABROAD_CODES } from './utils/const';
import Footer from './footer';
import Cascader from './cascader';
import NotFoundRender from './components/not-found-render';
import SearchingRender from './components/searching-render';
import SearchFailedRender from './components/search-failed-render';
import RegionItemRender from './components/region-item-render';
import './index.scss';
var bindCtx = func.bindCtx;
var pickOthers = obj.pickOthers;
var getStyle = dom.getStyle;
var normalizeValue = cascaders.normalizeValue, genDict = cascaders.genDict, processValue = cascaders.processValue, forEachEnableNode = cascaders.forEachEnableNode, isSiblingOrSelf = cascaders.isSiblingOrSelf, isNodeChecked = cascaders.isNodeChecked, filterChildValue = cascaders.filterChildValue;
var getFinallyLevel = levels.getFinallyLevel, formatCdnDataLevel = levels.formatCdnDataLevel, handleCdnDataSkip = levels.handleCdnDataSkip;
var checkSDK = configs.checkSDK, setMTOPConfig = configs.setMTOPConfig, preCheckConfigs = configs.preCheckConfigs, mergeGlobalConfig = configs.mergeGlobalConfig;
var handleData = handles.handleData, handlerSortData = handles.handlerSortData;
/**
 * CnAddress
 */
var CnAddress = /** @class */ (function (_super) {
    __extends(CnAddress, _super);
    function CnAddress(props) {
        var _this = _super.call(this, props) || this;
        /**
         * 刷新值数据缓存，删除无效值
         * @param {Arrary | String} curValue 当前值
         */
        _this.refreshValueDataCache = function (curValue) {
            if (curValue) {
                var valueArr_1 = Array.isArray(curValue) ? curValue : [curValue];
                valueArr_1.length &&
                    Object.keys(_this._valueDataCache).forEach(function (v) {
                        if (!valueArr_1.includes(v)) {
                            delete _this._valueDataCache[v];
                        }
                    });
            }
            else {
                _this._valueDataCache = {};
            }
        };
        _this.doSearch = debounce(function (v) {
            var mergeProps = mergeGlobalConfig(_this.props);
            var showTopLevel = mergeProps.showTopLevel, includeList = mergeProps.includeList, excludeList = mergeProps.excludeList, showDisabledDivision = mergeProps.showDisabledDivision, dataLanguage = mergeProps.dataLanguage, needSkipLevelData = mergeProps.needSkipLevelData, blackList = mergeProps.blackList, divisionSort = mergeProps.divisionSort, levelConfig = mergeProps.levelConfig, isParseSearch = mergeProps.isParseSearch, isSearchById = mergeProps.isSearchById, isShowAbroad = mergeProps.isShowAbroad, searchStrictly = mergeProps.searchStrictly;
            var iso = _this.props.iso;
            var dataSource = _this.state.dataSource;
            // 是否为全部国家
            var isAllIsos = iso === '' || iso === null;
            var maxDataLevel = getFinallyLevel(mergeProps);
            var isOpenParse = isParseSearch && iso === 'CN';
            var p = _this.services.getRegionChainByName;
            p.call(_this.services, {
                id: v,
                divisionToken: v,
                // 搜索时全量搜索，在结果中截取数据
                maxDataLevel: searchStrictly ? maxDataLevel : '',
                iso: iso,
            }).then(function () {
                var args_1 = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args_1[_i] = arguments[_i];
                }
                return __awaiter(_this, __spreadArray([], __read(args_1), false), void 0, function (apiRes) {
                    var v2n, parseResult, parseResult, searchResultIso, shouldSearch, serialPromises;
                    var _this = this;
                    var _a;
                    if (apiRes === void 0) { apiRes = []; }
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                v2n = this._v2n || [];
                                if (!(isOpenParse && apiRes.length === 0)) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.services.getRegionChainByParse({
                                        fullAddress: v,
                                        maxLevel: 0,
                                        iso: iso,
                                    })];
                            case 1:
                                parseResult = _b.sent();
                                if (parseResult && parseResult.length) {
                                    apiRes = [parseResult];
                                }
                                _b.label = 2;
                            case 2:
                                if (!(isSearchById && apiRes.length === 0)) return [3 /*break*/, 4];
                                return [4 /*yield*/, this.services.getRegionChain({
                                        id: v,
                                        iso: iso,
                                    })];
                            case 3:
                                parseResult = _b.sent();
                                searchResultIso = (_a = parseResult[0]) === null || _a === void 0 ? void 0 : _a.iso;
                                // 1.非所有国家场景，不能搜索与当前 iso 不一致数据
                                if (parseResult.length === 0 || (!isAllIsos && iso !== searchResultIso)) {
                                    apiRes = [];
                                }
                                else {
                                    apiRes = [parseResult];
                                }
                                _b.label = 4;
                            case 4:
                                // iso指定国家时，且没有设置 showTopLevel 不能搜索第一级
                                if (!isAllIsos && !showTopLevel && apiRes.length && apiRes[0].length <= 1) {
                                    apiRes = [];
                                }
                                shouldSearch = [];
                                apiRes = apiRes
                                    .map(function (o) {
                                    var inputRes = o.filter(function (i) { return i; });
                                    var start = showTopLevel || isAllIsos ? 0 : 1;
                                    var end = maxDataLevel;
                                    // 在搜索结果中截取符合当前层级的数据
                                    inputRes = inputRes.slice(start, end);
                                    // 过滤跳级数据
                                    var lastOne = inputRes.at(-1);
                                    if (!needSkipLevelData && (lastOne === null || lastOne === void 0 ? void 0 : lastOne.level) > maxDataLevel)
                                        return false;
                                    if (blackList.length && inputRes.some(function (input) { return blackList.includes(input.id); }))
                                        return false;
                                    if (!showDisabledDivision && inputRes.some(function (input) { return input.disable; }))
                                        return false;
                                    if (!iso) {
                                        if (excludeList.length && inputRes.some(function (input) { return excludeList.includes(input.iso); }))
                                            return false;
                                        if (includeList.length && !inputRes.some(function (input) { return includeList.includes(input.iso); }))
                                            return false;
                                    }
                                    shouldSearch = shouldSearch.concat(inputRes);
                                    return handleData({
                                        isSearch: true,
                                        input: inputRes,
                                        showDisabledDivision: showDisabledDivision,
                                        dataLanguage: dataLanguage,
                                        maxDataLevel: maxDataLevel,
                                        needSkipLevelData: needSkipLevelData,
                                        divisionSort: divisionSort,
                                        levelConfig: levelConfig,
                                        iso: iso,
                                        isShowAbroad: isShowAbroad,
                                    });
                                })
                                    .filter(function (i) { return i; });
                                // sort 将搜索匹配的字段优先展示
                                apiRes = apiRes.sort(function (a, b) { return b.at(-1).label.indexOf(v) - a.at(-1).label.indexOf(v); });
                                serialPromises = function (promises) {
                                    return promises.reduce(function (prev, next) { return prev.then(next); }, Promise.resolve(dataSource));
                                };
                                serialPromises(shouldSearch.map(function (item) {
                                    if (!v2n[item.id]) {
                                        return _this.mergeSource.bind(_this, item.id);
                                    }
                                })).then(function () {
                                    _this.setState({
                                        searchLoading: false,
                                        searchResult: apiRes,
                                    });
                                });
                                return [2 /*return*/];
                        }
                    });
                });
            });
        }, 600);
        _this.setSearchValue = debounce(function (searchValue) {
            var expandCount = _this.state.expandCount;
            _this.setState({
                searchValue: searchValue,
                showExpandCount: searchValue ? 0 : expandCount,
            });
        }, 600);
        _this.state = {
            value: [],
            iso: 'CN',
            hasInit: false,
            initLoading: true,
            visible: false,
            dataSource: [],
            showExpandCount: 1,
            expandCount: 1,
            expand: [],
            searchResult: [],
            searchValue: '',
            searchLoading: false,
            mergeAllProps: mergeGlobalConfig(_this.props),
            regionChainForWhitelist: [],
        };
        // 缓存选中值数据
        _this._valueDataCache = {};
        bindCtx(_this, [
            'handleVisibleChange',
            'handleAfterOpen',
            'handleSelect',
            'handleChange',
            'handleClear',
            'handleRemove',
            'handleSearch',
            'getPopup',
            'saveSelectRef',
            'saveCascaderRef',
            'handleKeyDown',
        ]);
        return _this;
    }
    CnAddress.getDerivedStateFromProps = function (props, prevState) {
        var st = {};
        if ('value' in props && prevState.hasInit) {
            st.value = transferObjToAvailable(props.value, __assign({}, props));
            return st;
        }
        return null;
    };
    CnAddress.prototype.componentDidMount = function () {
        this.initCnFilterProps();
        this.init();
    };
    CnAddress.prototype.componentDidUpdate = function (prevProps, prevState) {
        var _this = this;
        var mergeProps = this.state.mergeAllProps;
        var props = mergeProps;
        // value变化时，重新获取数据
        if ('value' in this.props &&
            this.props.value &&
            !isEqual(transferObjToAvailable(this.props.value, __assign({}, props)), prevState.value) &&
            this.state.hasInit) {
            this.setInputValue(this.state.dataSource).then(function () {
                _this.setState({
                    value: transferObjToAvailable(_this.props.value, __assign({}, props)),
                });
            });
        }
        // 切换国家/白名单 重新获取数据
        if (prevProps.iso !== this.props.iso || prevProps.whitelistCode !== this.props.whitelistCode) {
            this.setState({
                value: [],
            }, function () {
                _this.props.onChange && _this.props.onChange(undefined, null, null);
                _this.init();
            });
        }
    };
    /**
     * 初始化
     * @returns
     */
    CnAddress.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var mergeProps, showTopLevel, includeList, excludeList, blackList, maxLevel, showDisabledDivision, propDataLanguage, config, needSkipLevelData, divisionSort, levelConfig, mtopConfig, isShowAbroad, hideSensitiveDivision, iso, dataLanguage, preCheckResult, maxDataLevel, countrys, res_1, allCountrys, res, next;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        mergeProps = this.state.mergeAllProps;
                        showTopLevel = mergeProps.showTopLevel, includeList = mergeProps.includeList, excludeList = mergeProps.excludeList, blackList = mergeProps.blackList, maxLevel = mergeProps.maxLevel, showDisabledDivision = mergeProps.showDisabledDivision, propDataLanguage = mergeProps.dataLanguage, config = mergeProps.config, needSkipLevelData = mergeProps.needSkipLevelData, divisionSort = mergeProps.divisionSort, levelConfig = mergeProps.levelConfig, mtopConfig = mergeProps.mtopConfig, isShowAbroad = mergeProps.isShowAbroad, hideSensitiveDivision = mergeProps.hideSensitiveDivision;
                        iso = this.props.iso;
                        this.setState({ iso: iso });
                        dataLanguage = preHandlerDataLanguage(propDataLanguage);
                        preCheckResult = preCheckConfigs(mergeProps);
                        if (!preCheckResult)
                            return [2 /*return*/];
                        maxDataLevel = getFinallyLevel(mergeProps);
                        if (!((config === null || config === void 0 ? void 0 : config.apiModel) === 'cdn')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.initCdn()];
                    case 1:
                        _d.sent();
                        return [2 /*return*/];
                    case 2:
                        if (!((config === null || config === void 0 ? void 0 : config.apiModel) === 'mtop' || !(config === null || config === void 0 ? void 0 : config.apiModel))) return [3 /*break*/, 4];
                        return [4 /*yield*/, checkSDK()];
                    case 3:
                        _d.sent();
                        // 自定义mtop配置
                        setMTOPConfig(mtopConfig);
                        _d.label = 4;
                    case 4:
                        this.services = new Services({
                            config: __assign(__assign({}, (config || {})), { iso: iso || 'CN', hideSensitiveDivision: hideSensitiveDivision }),
                        });
                        countrys = [];
                        return [4 /*yield*/, this._getChainData(this.services)];
                    case 5:
                        _d.sent();
                        if (!iso) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.services.getTopLevelInfo({
                                iso: iso,
                            })];
                    case 6:
                        res_1 = _d.sent();
                        countrys.push(res_1);
                        return [3 /*break*/, 9];
                    case 7: return [4 /*yield*/, this.services.getTopLevelList({})];
                    case 8:
                        allCountrys = _d.sent();
                        allCountrys.forEach(function (item) {
                            if (includeList.indexOf(item.iso) != -1) {
                                countrys.push(item);
                            }
                            if (!includeList.length) {
                                countrys.push(item);
                            }
                            if (excludeList.indexOf(item.iso) != -1) {
                                countrys = countrys.filter(function (country) { return country.iso !== item.iso; });
                            }
                        });
                        _d.label = 9;
                    case 9:
                        if (!(iso && !showTopLevel)) return [3 /*break*/, 11];
                        return [4 /*yield*/, ((_a = this.services) === null || _a === void 0 ? void 0 : _a.getChildren({
                                id: (_b = countrys[0]) === null || _b === void 0 ? void 0 : _b.id,
                                maxLevel: maxLevel,
                                iso: iso || ((_c = countrys[0]) === null || _c === void 0 ? void 0 : _c.iso),
                            }))];
                    case 10:
                        next = _d.sent();
                        if (!next)
                            return [2 /*return*/];
                        res = handleData({
                            input: next || [],
                            showDisabledDivision: showDisabledDivision,
                            dataLanguage: dataLanguage,
                            maxDataLevel: maxDataLevel,
                            needSkipLevelData: needSkipLevelData,
                            divisionSort: divisionSort,
                            iso: iso,
                            isShowAbroad: isShowAbroad,
                        });
                        return [3 /*break*/, 12];
                    case 11:
                        res = handleData({
                            input: countrys,
                            showDisabledDivision: showDisabledDivision,
                            dataLanguage: dataLanguage,
                            maxDataLevel: maxDataLevel,
                            needSkipLevelData: needSkipLevelData,
                            divisionSort: divisionSort,
                            levelConfig: levelConfig,
                            iso: iso,
                            isShowAbroad: isShowAbroad,
                        });
                        _d.label = 12;
                    case 12:
                        // 黑名单过滤
                        res = res.filter(function (item) { return !blackList.includes(item.value); });
                        // 白名单
                        res = this._fiterDataForWhitelist(res);
                        this.setState({
                            dataSource: res,
                        });
                        this.preHandleData(res);
                        return [4 /*yield*/, this.setInputValue(res)];
                    case 13:
                        _d.sent();
                        this.setState({
                            hasInit: true,
                            initLoading: false,
                            value: getFinalValue(mergeProps),
                        });
                        return [2 /*return*/, res];
                }
            });
        });
    };
    /**
     * cdn模式初始化
     */
    CnAddress.prototype.initCdn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var mergeProps, config, propDataLanguage, showTopLevel, showDisabledDivision, includeList, excludeList, blackList, divisionSort, isShowAbroad, iso, dataLanguage, fullFlatDataByCdn, fullFlatData, map, topMap, res;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        mergeProps = this.state.mergeAllProps;
                        config = mergeProps.config, propDataLanguage = mergeProps.dataLanguage, showTopLevel = mergeProps.showTopLevel, showDisabledDivision = mergeProps.showDisabledDivision, includeList = mergeProps.includeList, excludeList = mergeProps.excludeList, blackList = mergeProps.blackList, divisionSort = mergeProps.divisionSort, isShowAbroad = mergeProps.isShowAbroad;
                        iso = this.props.iso;
                        dataLanguage = preHandlerDataLanguage(propDataLanguage);
                        return [4 /*yield*/, getFullDataByCdn(config.cdnUrl)];
                    case 1:
                        fullFlatDataByCdn = _b.sent();
                        fullFlatData = handlerSortData(cloneDeep(fullFlatDataByCdn), divisionSort, dataLanguage);
                        map = {};
                        topMap = {};
                        fullFlatData
                            .map(function (i) {
                            if (!i.parentId && !topMap[i.iso])
                                topMap[i.iso] = i;
                            if (!showDisabledDivision && i.disable)
                                return;
                            if (!map[i.id])
                                map[i.id] = i;
                            if (!i.leaf)
                                i.children = [];
                            var label = getName(i, dataLanguage).name;
                            i.value = i.id;
                            i.label = label;
                            i.rawLevel = i.level;
                        })
                            .filter(function (i) { return i; });
                        fullFlatData.map(function (i) {
                            var _a;
                            if (!showDisabledDivision && i.disable)
                                return;
                            if (blackList.includes(i.id))
                                return;
                            if (!isShowAbroad && ABROAD_CODES.includes(i.id))
                                return;
                            if (map[i.parentId] && !((_a = map[i.parentId]) === null || _a === void 0 ? void 0 : _a.leaf)) {
                                map[i.parentId].children.push(i);
                            }
                        });
                        res = [];
                        if (showTopLevel) {
                            if (iso) {
                                res = [topMap[iso]];
                            }
                            else {
                                res = Object.values(topMap);
                                if (includeList.length) {
                                    includeList.map(function (iso) {
                                        res.push(topMap[iso]);
                                    });
                                }
                                else {
                                    res = Object.values(topMap);
                                }
                                if (excludeList.length) {
                                    res = res.filter(function (country) { return excludeList.indexOf(country.iso); });
                                }
                            }
                        }
                        else {
                            res = ((_a = topMap[iso]) === null || _a === void 0 ? void 0 : _a.children) || [];
                        }
                        // 处理 level
                        res = formatCdnDataLevel(res, mergeProps);
                        // 处理跳级数据
                        res = handleCdnDataSkip(res, mergeProps);
                        this.preHandleData(res);
                        this.setState({
                            dataSource: res,
                            value: getFinalValue(mergeProps),
                            hasInit: true,
                            initLoading: false,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * 初始化 cnFilter 自定义tag渲染
     */
    CnAddress.prototype.initCnFilterProps = function () {
        var _this = this;
        var mergeProps = this.state.mergeAllProps;
        var itemPropsRef = this.props.itemPropsRef;
        if (itemPropsRef) {
            itemPropsRef.current = {
                renderSelected: function (key, value, itemLabel) {
                    return (React.createElement(RegionItemRender, { value: value, itemLabel: itemLabel, outerProps: mergeProps, getData: _this.getData.bind(_this) }));
                },
            };
        }
    };
    CnAddress.prototype.preHandleData = function (data, immutable) {
        var _data = immutable ? cloneDeep(data) : data;
        try {
            return this._flatDataSource(_data);
        }
        catch (err) {
            if ((err.message || '').match('Cannot assign to read only property')) {
                console.error(err.message, 'try to set immutable to true to allow immutable dataSource');
            }
            throw err;
        }
    };
    /**
     *  获取白名单编码区划链，用于过滤使用
     */
    CnAddress.prototype._getChainData = function (services) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, whitelistCode, iso, regionChainForWhitelist;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, whitelistCode = _a.whitelistCode, iso = _a.iso;
                        regionChainForWhitelist = [];
                        if (!whitelistCode) return [3 /*break*/, 2];
                        return [4 /*yield*/, services.getRegionChain({
                                id: whitelistCode,
                                iso: iso,
                            })];
                    case 1:
                        regionChainForWhitelist = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        regionChainForWhitelist = [];
                        _b.label = 3;
                    case 3: return [2 /*return*/, this.setState({ regionChainForWhitelist: regionChainForWhitelist })];
                }
            });
        });
    };
    /**
     * 过滤白名单区划
     * todo: cdn模式
     */
    CnAddress.prototype._fiterDataForWhitelist = function (res) {
        var _regionChain = this.state.regionChainForWhitelist.filter(function (o) { return o; });
        if (_regionChain.length > 0) {
            var cur = res[0];
            var l_1 = (cur === null || cur === void 0 ? void 0 : cur.rawLevel) || (cur === null || cur === void 0 ? void 0 : cur.level);
            var findData_1 = _regionChain.find(function (o) { return o.level === l_1; });
            if (findData_1) {
                return res.filter(function (item) { return (item.value || item.id) === findData_1.id; });
            }
        }
        return res;
    };
    CnAddress.prototype._flatDataSource = function (data, prefix, v2n, p2n) {
        var _this = this;
        if (prefix === void 0) { prefix = '0'; }
        if (v2n === void 0) { v2n = {}; }
        if (p2n === void 0) { p2n = {}; }
        data.forEach(function (item, index) {
            var value = item.value, children = item.children;
            var pos = "".concat(prefix, "-").concat(index);
            var newValue = String(value);
            item.value = newValue;
            v2n[newValue] = p2n[pos] = __assign(__assign({}, item), { pos: pos, _source: item });
            if (children && children.length) {
                _this._flatDataSource(children, pos, v2n, p2n);
            }
        });
        this._v2n = v2n;
        this._p2n = p2n;
        return { v2n: v2n, p2n: p2n };
    };
    /**
     * @desc 当默认值初始化后，如果开启了 useDetailValue, 且传入值为 string 或 [string]，则默认转化输出格式
     * @returns
     */
    CnAddress.prototype._formatOutputAfterInit = function () {
        var _this = this;
        var mergeProps = this.state.mergeAllProps;
        var value;
        var multiple = mergeProps.multiple, checkStrictly = mergeProps.checkStrictly, onChange = mergeProps.onChange, useDetailValue = mergeProps.useDetailValue, useDetailMap = mergeProps.useDetailMap;
        value = getFinalValue(mergeProps);
        if (multiple) {
            var extra = void 0;
            if (mergeProps.onChange) {
                var data = this.getData(value);
                var checked = false;
                var indeterminateValue = this.getIndeterminate(value);
                var indeterminateData_1 = this.getData(indeterminateValue);
                if (checkStrictly) {
                    extra = {
                        checked: checked,
                        currentData: null,
                        checkedData: data,
                        indeterminateData: indeterminateData_1,
                    };
                }
                else {
                    var checkedValue = this.completeValue(value);
                    var checkedData = this.getData(checkedValue);
                    if (checkedData.filter(function (ii) { return ii; }).length !== value.length)
                        return;
                    var chainData_1 = [];
                    data.forEach(function (item) {
                        var filterChainData = indeterminateData_1.filter(
                        // 根据pos匹配父级，从其实配置匹配到才算成功，中间位置的无效
                        function (o) { return item.pos.indexOf(o.pos) === 0; });
                        filterChainData.push(item);
                        filterChainData.sort(function (a, b) { return a.level - b.level; });
                        chainData_1.push(filterChainData);
                    });
                    extra = {
                        checked: checked,
                        currentData: null,
                        checkedData: checkedData,
                        indeterminateData: indeterminateData_1,
                        chainData: chainData_1,
                    };
                }
                // 详细数据处理
                onChange(getDetailValueDS(value, data.filter(function (item) { return item; }), extra, multiple, useDetailMap, useDetailValue), data, extra);
            }
        }
        else {
            var data = this._v2n[value];
            if (!data)
                return;
            var nums_1 = data.pos.split('-');
            var selectedPath = nums_1.slice(1).reduce(function (ret, num, index) {
                var p = nums_1.slice(0, index + 2).join('-');
                ret.push(_this._p2n[p]);
                return ret;
            }, []);
            var extra = {
                selectedPath: selectedPath,
            };
            onChange(getDetailValueDS(value, data, extra, multiple, useDetailMap, useDetailValue), data, extra);
        }
    };
    CnAddress.prototype.setInputValue = function (datas) {
        return __awaiter(this, void 0, void 0, function () {
            var mergeProps, multiple, _a, maxLength, v, newValue, isArray, arr, shouldLoad, propsV;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        mergeProps = mergeGlobalConfig(this.props);
                        multiple = mergeProps.multiple, _a = mergeProps.config.maxLength, maxLength = _a === void 0 ? 50 : _a;
                        v = getFinalValue(mergeProps);
                        newValue = normalizeValue(v);
                        isArray = typeof newValue === 'object' && Object.prototype.toString.call(newValue) === '[object Array]';
                        arr = newValue || [];
                        if (!isArray && newValue) {
                            arr = [newValue];
                        }
                        shouldLoad = [];
                        arr.forEach(function (o) {
                            var exits = (_this._v2n || {})[o];
                            !exits && shouldLoad.push(o);
                        });
                        // 回填会触发  只是选不会触发
                        if (shouldLoad.length > maxLength) {
                            console.error("value's length is too large, please use cdn data");
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, Promise.all(shouldLoad.map(function (i) { return _this.mergeSource(i, datas); }))];
                    case 1:
                        _b.sent();
                        propsV = 'value' in mergeProps ? this.props.value : this.props.defaultValue;
                        if (mergeProps.useDetailValue &&
                            !this.hasNotSelected &&
                            propsV &&
                            !checkUseDetail(propsV, multiple)) {
                            this._formatOutputAfterInit();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CnAddress.prototype.mergeSource = function (code, datas) {
        return __awaiter(this, void 0, void 0, function () {
            var mergeProps, showTopLevel, needSkipLevelData, maxLevel, showDisabledDivision, dataLanguage, divisionSort, levelConfig, isShowAbroad, iso, maxDataLevel, v2n, newData, rawRes, res, count, topCode, top, children, secondLevel, deep;
            var _this = this;
            var _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        mergeProps = mergeGlobalConfig(this.props);
                        showTopLevel = mergeProps.showTopLevel, needSkipLevelData = mergeProps.needSkipLevelData, maxLevel = mergeProps.maxLevel, showDisabledDivision = mergeProps.showDisabledDivision, dataLanguage = mergeProps.dataLanguage, divisionSort = mergeProps.divisionSort, levelConfig = mergeProps.levelConfig, isShowAbroad = mergeProps.isShowAbroad;
                        iso = this.props.iso;
                        maxDataLevel = getFinallyLevel(mergeProps);
                        v2n = this._v2n;
                        if (v2n[code])
                            return [2 /*return*/, datas];
                        newData = __spreadArray([], __read(datas), false).splice(0);
                        return [4 /*yield*/, ((_a = this.services) === null || _a === void 0 ? void 0 : _a.getRegionChain({
                                id: code,
                                maxLevel: maxLevel,
                                iso: iso,
                            }))];
                    case 1:
                        rawRes = (_f.sent()) || [];
                        res = rawRes.filter(function (i) { return i; });
                        // 传入不存在的id时，终止
                        if (res.length === 0) {
                            console.error('CnAddress Error: maybe divisionId is not existed');
                            (_c = (_b = this.props).onNoExistId) === null || _c === void 0 ? void 0 : _c.call(_b, { id: code });
                            return [2 /*return*/];
                        }
                        count = iso && !showTopLevel ? 1 : 0;
                        topCode = (_d = res[count]) === null || _d === void 0 ? void 0 : _d.id;
                        if (!topCode)
                            return [2 /*return*/];
                        top = (this._v2n || {})[topCode];
                        if (maxLevel === 1)
                            return [2 /*return*/];
                        children = (top === null || top === void 0 ? void 0 : top.children) || [];
                        if (!(children.length === 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, ((_e = this.services) === null || _e === void 0 ? void 0 : _e.getChildren({
                                id: topCode,
                                maxLevel: maxLevel,
                            }))];
                    case 2:
                        secondLevel = _f.sent();
                        children = handleData({
                            input: secondLevel || [],
                            isLeaf: maxLevel === 2,
                            parentId: topCode,
                            showDisabledDivision: showDisabledDivision,
                            dataLanguage: dataLanguage,
                            maxDataLevel: maxDataLevel,
                            needSkipLevelData: needSkipLevelData,
                            divisionSort: divisionSort,
                            levelConfig: levelConfig,
                            iso: iso,
                            isShowAbroad: isShowAbroad,
                        });
                        // 白名单过滤
                        children = this._fiterDataForWhitelist(children);
                        _f.label = 3;
                    case 3:
                        count++;
                        deep = function (children) { return __awaiter(_this, void 0, void 0, function () {
                            var nextCode, next, nextChilds;
                            var _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        nextCode = (_a = res[count]) === null || _a === void 0 ? void 0 : _a.id;
                                        next = children.find(function (o) { return (o === null || o === void 0 ? void 0 : o.value) === nextCode; });
                                        if (!(next && !next.children)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, ((_b = this.services) === null || _b === void 0 ? void 0 : _b.getChildren({
                                                id: nextCode,
                                                maxLevel: maxLevel,
                                                divisionLevel: next.rawLevel || next.level,
                                            }))];
                                    case 1:
                                        nextChilds = _c.sent();
                                        // 白名单过滤
                                        nextChilds = this._fiterDataForWhitelist(nextChilds);
                                        // 改由数据层级判断 isLeaf
                                        next.children = handleData({
                                            input: nextChilds || [],
                                            parentId: nextCode,
                                            showDisabledDivision: showDisabledDivision,
                                            dataLanguage: dataLanguage,
                                            maxDataLevel: maxDataLevel,
                                            needSkipLevelData: needSkipLevelData,
                                            divisionSort: divisionSort,
                                            levelConfig: levelConfig,
                                            iso: iso,
                                            isShowAbroad: isShowAbroad,
                                        });
                                        _c.label = 2;
                                    case 2:
                                        count++;
                                        if (!(maxLevel >= count + 1 && res.length >= count + 2)) return [3 /*break*/, 4];
                                        return [4 /*yield*/, deep(next.children)];
                                    case 3:
                                        _c.sent();
                                        _c.label = 4;
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); };
                        if (!((iso && !showTopLevel && maxLevel !== count) ||
                            (iso && showTopLevel && maxLevel !== count + 1) ||
                            (!iso && maxLevel !== count + 1))) return [3 /*break*/, 5];
                        return [4 /*yield*/, deep(children)];
                    case 4:
                        _f.sent();
                        _f.label = 5;
                    case 5:
                        newData.some(function (item) {
                            if (item.value === topCode) {
                                item.children = children;
                                return true;
                            }
                        });
                        this.setState({
                            dataSource: newData,
                        });
                        this.preHandleData(newData);
                        return [2 /*return*/, newData];
                }
            });
        });
    };
    CnAddress.prototype.updateCache = function (dataSource) {
        var _this = this;
        this._v2n = {};
        this._p2n = {};
        var loop = function (data, prefix) {
            if (prefix === void 0) { prefix = '0'; }
            return data.forEach(function (item, index) {
                var value = item.value, children = item.children;
                var pos = "".concat(prefix, "-").concat(index);
                _this._v2n[value] = _this._p2n[pos] = __assign(__assign({}, item), { pos: pos });
                if (children && children.length) {
                    loop(children, pos);
                }
            });
        };
        loop(dataSource);
    };
    CnAddress.prototype.flatValue = function (value) {
        var _this = this;
        var getDepth = function (v) {
            var pos = _this.getPos(v);
            if (!pos) {
                return 0;
            }
            return pos.split('-').length;
        };
        var newValue = value.slice(0).sort(function (prev, next) {
            return getDepth(prev) - getDepth(next);
        });
        for (var i = 0; i < newValue.length; i++) {
            for (var j = 0; j < newValue.length; j++) {
                if (i !== j &&
                    this.isDescendantOrSelf(this.getPos(newValue[i]), this.getPos(newValue[j]))) {
                    newValue.splice(j, 1);
                    j--;
                }
            }
        }
        return newValue;
    };
    CnAddress.prototype.isDescendantOrSelf = function (currentPos, targetPos) {
        if (!currentPos || !targetPos) {
            return false;
        }
        var currentNums = currentPos.split('-');
        var targetNums = targetPos.split('-');
        return (currentNums.length <= targetNums.length &&
            currentNums.every(function (num, index) {
                return num === targetNums[index];
            }));
    };
    CnAddress.prototype.getExpandedValue = function (v, _v2n, _p2n) {
        var _this = this;
        if (!v || !_v2n[v]) {
            return [];
        }
        var pos = _v2n[v].pos;
        if (pos.split('-').length === 2) {
            return [];
        }
        var expandedMap = {};
        Object.keys(_p2n).forEach(function (p) {
            if (_this.isDescendantOrSelf(p, pos) && p !== pos) {
                expandedMap[_p2n[p].value] = p;
            }
        });
        return Object.keys(expandedMap).sort(function (prev, next) {
            return expandedMap[prev].split('-').length - expandedMap[next].split('-').length;
        });
    };
    CnAddress.prototype.getValue = function (pos) {
        return this._p2n[pos] ? this._p2n[pos].value : null;
    };
    CnAddress.prototype.getPos = function (value) {
        return this._v2n[value] ? this._v2n[value].pos : null;
    };
    CnAddress.prototype.getData = function (value) {
        var _this = this;
        if (!this._v2n && Object.keys(this._valueDataCache).length === 0)
            return [];
        return value.map(function (v) { return _this._v2n[v] || _this._valueDataCache[v]; });
    };
    CnAddress.prototype.getLabelPath = function (data) {
        var _this = this;
        var nums = data.pos.split('-');
        return nums.slice(1).reduce(function (ret, num, index) {
            var p = nums.slice(0, index + 2).join('-');
            ret.push(_this._p2n[p].label);
            return ret;
        }, []);
    };
    CnAddress.prototype.getSingleData = function (value) {
        var mergeProps = this.props;
        if (!value.length) {
            return null;
        }
        if (Array.isArray(value))
            value = value[0];
        var data = this._v2n[value];
        if (data) {
            var labelPath = this.getLabelPath(data);
            var displayRender = mergeProps.displayRender || (function (labels) { return labels.join(' / '); });
            data = __assign(__assign({}, data), { label: displayRender(labelPath, data) });
            // 防止传入的value从数据源里移除了
            this._valueDataCache[value] = data;
            this.refreshValueDataCache(value);
        }
        else {
            data = this._valueDataCache[value];
        }
        return (data || {
            value: value,
        });
    };
    CnAddress.prototype.getMultipleData = function (value) {
        var _this = this;
        if (!value.length) {
            return null;
        }
        var displayRender = this.props.displayRender;
        // 此处移除了 flatValue 相关处理，性能较差
        var flatValue = value;
        var data = flatValue.map(function (v) {
            var item = _this._v2n[v];
            if (item) {
                _this._valueDataCache[v] = item;
            }
            else {
                item = _this._valueDataCache[v];
            }
            return item || { value: v };
        });
        if (displayRender) {
            data = data.map(function (item) {
                if (!item.pos || !(item.value in _this._v2n)) {
                    return item;
                }
                var labelPath = _this.getLabelPath(item);
                var newItem = __assign(__assign({}, item), { label: displayRender(labelPath, item) });
                _this._valueDataCache[item.value] = newItem;
                return newItem;
            });
        }
        return data;
    };
    CnAddress.prototype.getIndeterminate = function (value) {
        var _this = this;
        var indeterminate = [];
        var positions = value.map(this.getPos.bind(this));
        positions.forEach(function (pos) {
            if (!pos) {
                return false;
            }
            var nums = pos.split('-');
            for (var i = nums.length; i > 2; i--) {
                var parentPos = nums.slice(0, i - 1).join('-');
                var parentValue = _this.getValue(parentPos);
                if (indeterminate.indexOf(parentValue) === -1) {
                    indeterminate.push(parentValue);
                }
            }
        });
        return indeterminate;
    };
    CnAddress.prototype.saveSelectRef = function (ref) {
        this.select = ref;
    };
    CnAddress.prototype.saveCascaderRef = function (ref) {
        this.cascader = ref;
    };
    CnAddress.prototype.completeValue = function (value) {
        var newValue = [];
        var flatValue = value;
        var ps = Object.keys(this._p2n);
        for (var i = 0; i < ps.length; i++) {
            for (var j = 0; j < flatValue.length; j++) {
                var v = flatValue[j];
                if (this.isDescendantOrSelf(this.getPos(v), ps[i])) {
                    newValue.push(this.getValue(ps[i]));
                    ps.splice(i, 1);
                    i--;
                    break;
                }
            }
        }
        return newValue;
    };
    CnAddress.prototype.isLeaf = function (data) {
        var mergeProps = mergeGlobalConfig(this.props);
        return !((data.children && data.children.length) || (!!mergeProps.loadData && !data.isLeaf));
    };
    CnAddress.prototype.handleVisibleChange = function (visible, type) {
        var mergeProps = this.props;
        this.setState({
            visible: visible,
        });
        if (!visible) {
            this.setState({
                expand: [],
                expandCount: 1,
                showExpandCount: 1,
                searchValue: '',
            });
        }
        else {
            var currentValue = normalizeValue(this.state.value);
            var v = currentValue === null || currentValue === void 0 ? void 0 : currentValue[0];
            if (v) {
                var currentExpandedValue = this.getExpandedValue(v, this._v2n, this._p2n);
                // 设置展开
                this.setState({
                    expand: currentExpandedValue,
                    showExpandCount: currentExpandedValue.length + 1,
                });
            }
        }
        if (['fromCascader', 'keyboard'].indexOf(type) !== -1 && !visible) {
            this.select.focusInput();
        }
        mergeProps.onVisibleChange(visible, type);
    };
    CnAddress.prototype.handleKeyDown = function (e) {
        var mergeProps = mergeGlobalConfig(this.props);
        var onKeyDown = mergeProps.onKeyDown;
        var visible = this.state.visible;
        if (onKeyDown) {
            onKeyDown(e);
        }
        if (!visible) {
            return;
        }
        switch (e.keyCode) {
            case KEYCODE.UP:
            case KEYCODE.DOWN:
                this.cascader.setFocusValue();
                e.preventDefault();
                break;
            default:
                break;
        }
    };
    CnAddress.prototype.getPopup = function (ref) {
        var mergeProps = mergeGlobalConfig(this.props);
        this.popup = ref;
        if (typeof mergeProps.popupProps.ref === 'function') {
            mergeProps.popupProps.ref(ref);
        }
    };
    CnAddress.prototype.handleAfterOpen = function () {
        if (!this.popup)
            return;
        var mergeProps = mergeGlobalConfig(this.props);
        var prefix = mergeProps.prefix, popupProps = mergeProps.popupProps;
        var _a = popupProps.v2, v2 = _a === void 0 ? false : _a;
        if (!v2) {
            var dropDownNode = this.popup.getInstance().overlay.getInstance().getContentNode();
            var cascaderNode = dropDownNode.querySelector(".".concat(prefix, "cascader"));
            if (cascaderNode) {
                this.cascaderHeight = getStyle(cascaderNode, 'height');
            }
        }
        if (typeof popupProps.afterOpen === 'function') {
            popupProps.afterOpen();
        }
    };
    CnAddress.prototype.handleSelect = function (value, data) {
        var mergeProps = mergeGlobalConfig(this.props);
        var multiple = mergeProps.multiple, changeOnSelect = mergeProps.changeOnSelect;
        var _a = this.state, visible = _a.visible, searchValue = _a.searchValue;
        var currentMaxLevelData = getFinallyLevel(mergeProps);
        var isLastLevel = data.level === currentMaxLevelData || data.isLeaf;
        // 是否进行选择/编辑操作
        this.hasNotSelected = true;
        // 单选模式下，搜索区划非最后一级时，不选中,expand
        // 最后一级判断条件有两个，maxLevel与当前搜索区划是否一致 或者 区划已经是最后一级
        if (!multiple && !isLastLevel) {
            var currentExpandedValue = this.getExpandedValue(value, this._v2n, this._p2n);
            currentExpandedValue.push(value);
            this.setState({
                expand: currentExpandedValue,
                showExpandCount: currentExpandedValue.length + 1,
                searchValue: '',
            });
            return;
        }
        if (!multiple && (!changeOnSelect || this.isLeaf(data) || !!searchValue)) {
            this.handleVisibleChange(!visible, 'fromCascader');
        }
    };
    CnAddress.prototype.handleChange = function (value, data, extra) {
        var _this = this;
        var mergeProps = mergeGlobalConfig(this.props);
        var multiple = mergeProps.multiple, onChange = mergeProps.onChange, changeOnSelect = mergeProps.changeOnSelect, useDetailValue = mergeProps.useDetailValue, useDetailMap = mergeProps.useDetailMap;
        var _a = this.state, searchValue = _a.searchValue, stateValue = _a.value;
        var st = {};
        var currentMaxLevelData = getFinallyLevel(mergeProps);
        var isLastLevel = data.level === currentMaxLevelData || data.isLeaf;
        // 是否进行选择/编辑操作
        this.hasNotSelected = true;
        // 单选模式下，搜索区划非最后一级时，不选中
        // changeOnSelect=true，点击即选中
        if (!multiple && !changeOnSelect && !isLastLevel)
            return;
        if (multiple && stateValue && Array.isArray(stateValue)) {
            var noExistedValues_1 = stateValue.filter(function (v) { return !_this._v2n[v]; });
            // filter duplicated non-existent value
            if (noExistedValues_1.length > 0) {
                value = value.filter(function (v) {
                    return !(noExistedValues_1.indexOf(v) >= 0);
                });
            }
            value = __spreadArray(__spreadArray([], __read(noExistedValues_1), false), __read(value), false);
            // onChange 中的 data 参数也应该保留不存在的 value 的数据
            // 在 dataSource 异步加载的情况下，会出现value重复的现象，需要去重
            data = __spreadArray(__spreadArray([], __read(noExistedValues_1.map(function (v) { return _this._valueDataCache[v]; }).filter(function (v) { return v; })), false), __read(data), false).filter(function (current, index, arr) {
                return index === arr.indexOf(current);
            });
            // 更新缓存
            this.refreshValueDataCache(value);
        }
        if (!('value' in mergeProps)) {
            st.value = value;
        }
        if (!multiple && searchValue) {
            st.searchValue = '';
        }
        if (Object.keys(st).length) {
            this.setState(st);
        }
        if (onChange) {
            if (useDetailValue) {
                onChange(getDetailValueDS(value, data, extra, multiple, useDetailMap, useDetailValue), data, extra);
            }
            else {
                onChange(value, data, extra);
            }
        }
        if (searchValue && this.select) {
            this.select.handleSearchClear();
        }
    };
    CnAddress.prototype.handleClear = function () {
        var mergeProps = mergeGlobalConfig(this.props);
        // 单选时点击清空按钮
        var hasClear = mergeProps.hasClear, multiple = mergeProps.multiple, treeCheckable = mergeProps.treeCheckable;
        if (hasClear && (!multiple || !treeCheckable)) {
            if (!('value' in mergeProps)) {
                this.setState({
                    value: [],
                });
            }
            mergeProps.onChange(undefined, null, null);
        }
    };
    CnAddress.prototype.handleRemove = function (currentData) {
        var mergeProps = mergeGlobalConfig(this.props);
        var currentValue = currentData.value;
        var value;
        // 是否进行选择/编辑操作
        this.hasNotSelected = true;
        var multiple = mergeProps.multiple, checkStrictly = mergeProps.checkStrictly, onChange = mergeProps.onChange, useDetailValue = mergeProps.useDetailValue, useDetailMap = mergeProps.useDetailMap;
        if (multiple) {
            value = __spreadArray([], __read(this.state.value), false);
            value.splice(value.indexOf(currentValue), 1);
            var extra = void 0;
            if (mergeProps.onChange) {
                var data = this.getData(value);
                var checked = false;
                var indeterminateValue = this.getIndeterminate(value);
                var indeterminateData_2 = this.getData(indeterminateValue);
                if (checkStrictly) {
                    extra = {
                        checked: checked,
                        currentData: currentData,
                        checkedData: data,
                        indeterminateData: indeterminateData_2,
                    };
                }
                else {
                    var checkedValue = this.completeValue(value);
                    var checkedData = this.getData(checkedValue);
                    var chainData_2 = [];
                    data.forEach(function (item) {
                        var filterChainData = indeterminateData_2.filter(
                        // 根据pos匹配父级，从其实配置匹配到才算成功，中间位置的无效
                        function (o) { return item.pos.indexOf(o.pos) === 0; });
                        filterChainData.push(item);
                        filterChainData.sort(function (a, b) { return a.level - b.level; });
                        chainData_2.push(filterChainData);
                    });
                    extra = {
                        checked: checked,
                        currentData: currentData,
                        checkedData: checkedData,
                        indeterminateData: indeterminateData_2,
                        chainData: chainData_2,
                    };
                }
                // 详细数据处理
                if (useDetailValue) {
                    onChange(getDetailValueDS(value, data, extra, multiple, useDetailMap, useDetailValue), data, extra);
                }
                else {
                    onChange(value, data, extra);
                }
            }
        }
        else {
            value = [];
            onChange(null, null);
        }
        if (!('value' in mergeProps)) {
            this.setState({
                value: value,
            });
        }
        this.refreshValueDataCache(value);
    };
    CnAddress.prototype.handleSearch = function (searchValue) {
        var mergeProps = mergeGlobalConfig(this.props);
        var _a = mergeProps.config.apiModel, apiModel = _a === void 0 ? 'mtop' : _a;
        var searchResult = this.state.searchResult;
        if (apiModel === 'mtop' || apiModel === 'api') {
            if (searchValue) {
                this.doSearch(searchValue);
            }
            this.setState({
                searchLoading: Boolean(searchValue),
                searchResult: searchValue ? searchResult : [],
            });
        }
        this.setSearchValue(searchValue);
        mergeProps.onSearch && mergeProps.onSearch(searchValue);
    };
    CnAddress.prototype.getPath = function (pos) {
        var items = [];
        var nums = pos.split('-');
        if (nums === 2) {
            items.push(this._p2n[pos]);
        }
        else {
            for (var i = 1; i < nums.length; i++) {
                var p = nums.slice(0, i + 1).join('-');
                items.push(this._p2n[p]);
            }
        }
        return items;
    };
    CnAddress.prototype.filterItems = function () {
        var _this = this;
        var mergeProps = mergeGlobalConfig(this.props);
        var multiple = mergeProps.multiple, changeOnSelect = mergeProps.changeOnSelect, canOnlyCheckLeaf = mergeProps.canOnlyCheckLeaf, filter = mergeProps.filter;
        var searchValue = this.state.searchValue;
        var items = Object.keys(this._p2n).map(function (p) { return _this._p2n[p]; });
        if ((!multiple && !changeOnSelect) || (multiple && canOnlyCheckLeaf)) {
            items = items.filter(function (item) { return !item.children || !item.children.length; });
        }
        return items.map(function (item) { return _this.getPath(item.pos); }).filter(function (path) { return filter(searchValue, path); });
    };
    CnAddress.prototype.renderNotFound = function () {
        var mergeProps = mergeGlobalConfig(this.props);
        return React.createElement(NotFoundRender, __assign({}, mergeProps));
    };
    CnAddress.prototype.renderSearching = function () {
        var mergeProps = mergeGlobalConfig(this.props);
        return React.createElement(SearchingRender, __assign({}, mergeProps));
    };
    CnAddress.prototype.renderSearchFailed = function () {
        var mergeProps = mergeGlobalConfig(this.props);
        return React.createElement(SearchFailedRender, __assign({}, mergeProps));
    };
    CnAddress.prototype.renderCascader = function () {
        var _this = this;
        var _a, _b;
        var mergeProps = mergeGlobalConfig(this.props);
        var _c = this.state, dataSource = _c.dataSource, searchLoading = _c.searchLoading, searchValue = _c.searchValue, searchResult = _c.searchResult;
        if (dataSource.length === 0) {
            return this.renderNotFound();
        }
        var filteredPaths = [];
        if (searchValue) {
            if (((_a = mergeProps.config) === null || _a === void 0 ? void 0 : _a.apiModel) === 'cdn') {
                filteredPaths = this.filterItems();
            }
            else {
                filteredPaths = searchResult;
            }
            if (filteredPaths.length === 0 && !searchLoading) {
                if (((_b = mergeProps.config) === null || _b === void 0 ? void 0 : _b.apiModel) === 'cdn') {
                    return this.renderNotFound();
                }
                else {
                    return this.renderSearchFailed();
                }
            }
            if (searchLoading) {
                return this.renderSearching();
            }
        }
        var multiple = mergeProps.multiple, useVirtual = mergeProps.useVirtual, changeOnSelect = mergeProps.changeOnSelect, checkStrictly = mergeProps.checkStrictly, canOnlyCheckLeaf = mergeProps.canOnlyCheckLeaf, defaultExpandedValue = mergeProps.defaultExpandedValue, expandTriggerType = mergeProps.expandTriggerType, onExpand = mergeProps.onExpand, readOnly = mergeProps.readOnly, listStyle = mergeProps.listStyle, listClassName = mergeProps.listClassName, showSearch = mergeProps.showSearch, markedDeletion = mergeProps.markedDeletion, _d = mergeProps.itemRender, itemRender = _d === void 0 ? function (item) {
            return (React.createElement("span", { className: item.disable && markedDeletion ? 'cn-address--mark-deletion' : '', title: item.label }, item.label));
        } : _d, _e = mergeProps.resultRender, resultRender = _e === void 0 ? function (searchValue, path) {
            var parts = [];
            var disable = false;
            path.forEach(function (item, i) {
                if (item.disable)
                    disable = true;
                if (!item.label)
                    return '';
                var reExp = searchValue.replace(/[-.+*?^$()[\]{}|\\]/g, function (v) { return "\\".concat(v); });
                var re = new RegExp(reExp, 'gi');
                var others = item.label.split(re);
                var matches = item.label.match(re);
                others.forEach(function (other, j) {
                    if (other) {
                        parts.push(other);
                    }
                    if (j < others.length - 1) {
                        parts.push(React.createElement("em", { key: "".concat(i, "-").concat(j) }, matches[j]));
                    }
                });
                if (i < path.length - 1) {
                    parts.push(' / ');
                }
            });
            return (React.createElement("span", { className: disable && markedDeletion ? 'cn-address--mark-deletion' : '' }, parts));
        } : _e, immutable = mergeProps.immutable, _f = mergeProps.config.apiModel, apiModel = _f === void 0 ? 'mtop' : _f;
        var _g = this.state, value = _g.value, expand = _g.expand;
        var props = {
            dataSource: dataSource,
            value: value,
            expandedValue: expand,
            multiple: multiple,
            useVirtual: useVirtual,
            canOnlySelectLeaf: !changeOnSelect,
            checkStrictly: checkStrictly,
            canOnlyCheckLeaf: canOnlyCheckLeaf,
            defaultExpandedValue: defaultExpandedValue,
            expandTriggerType: expandTriggerType,
            ref: this.saveCascaderRef,
            onExpand: function (v) {
                var _a;
                var len = v.length;
                if (!((_a = _this._v2n[v[len - 1]]) === null || _a === void 0 ? void 0 : _a.children)) {
                    return;
                }
                _this.setState({
                    showExpandCount: len + 1,
                    expandCount: len + 1,
                    expand: v,
                });
                onExpand && onExpand(v);
            },
            listStyle: listStyle,
            listClassName: classNames(listClassName, "cn-address-list"),
            loadData: apiModel === 'cdn' ? null : this.onLoadData.bind(this),
            resultRender: resultRender,
            itemRender: itemRender,
            immutable: immutable,
            v2n: this._v2n,
            p2n: this._p2n,
        };
        if ('expandedValue' in mergeProps) {
            props.expandedValue = mergeProps.expandedValue;
        }
        if (!readOnly) {
            props.onChange = this.handleChange;
            props.onSelect = this.handleSelect;
        }
        if (showSearch) {
            props.searchValue = searchValue;
            props.filteredPaths = filteredPaths;
            props.resultRender = resultRender;
            props.filteredListStyle = { height: this.cascaderHeight };
        }
        return React.createElement(Cascader, __assign({}, props));
    };
    CnAddress.prototype.onLoadData = function (item) {
        return __awaiter(this, void 0, void 0, function () {
            var mergeProps, dataSource, iso, blackList, needSkipLevelData, pos, code, cur, res, realLevel_1, list, filterList;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        mergeProps = mergeGlobalConfig(this.props);
                        dataSource = this.state.dataSource;
                        iso = mergeProps.iso, blackList = mergeProps.blackList, needSkipLevelData = mergeProps.needSkipLevelData;
                        pos = item.pos, code = item.value;
                        cur = (this._v2n || {})[code];
                        if (!(!cur || !cur.children || cur.children.length === 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.services.getChildren({
                                id: code,
                                iso: iso || item.iso,
                                divisionLevel: item.rawLevel || item.level,
                            })];
                    case 1:
                        res = _a.sent();
                        realLevel_1 = getFinallyLevel(mergeProps, iso);
                        list = (res || []).filter(function (i) {
                            i.rawLevel = i.level;
                            i.level = pos === null || pos === void 0 ? void 0 : pos.split('-').length; // 根据当前已有层级，设置下一级level值
                            var l = needSkipLevelData ? i.level : i.rawLevel;
                            return l <= realLevel_1;
                        });
                        filterList = [];
                        // 过滤黑名单区域
                        filterList = list.filter(function (item) { return !blackList.includes(item.id); });
                        // 白名单
                        filterList = this._fiterDataForWhitelist(filterList);
                        this.mergeDataSource(dataSource, filterList, pos);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    CnAddress.prototype.mergeDataSource = function (source, item, pos) {
        var mergeProps = mergeGlobalConfig(this.props);
        var maxLevel = mergeProps.maxLevel, showDisabledDivision = mergeProps.showDisabledDivision, dataLanguage = mergeProps.dataLanguage, needSkipLevelData = mergeProps.needSkipLevelData, divisionSort = mergeProps.divisionSort, levelConfig = mergeProps.levelConfig, iso = mergeProps.iso, isShowAbroad = mergeProps.isShowAbroad;
        // 是否为全部国家
        var maxDataLevel = getFinallyLevel(mergeProps);
        var newData = __spreadArray([], __read(source), false);
        var posList = pos.split('-');
        var len = posList.length;
        posList = posList.splice(1);
        var next = {};
        posList.forEach(function (level, index) {
            if (index === 0) {
                next = newData[level];
            }
            else {
                next = next.children[level];
            }
        });
        if (item.length !== 0) {
            if (!next.children)
                next.children = handleData({
                    input: item,
                    isLeaf: maxLevel <= len,
                    parentId: next === null || next === void 0 ? void 0 : next.value,
                    showDisabledDivision: showDisabledDivision,
                    dataLanguage: dataLanguage,
                    maxDataLevel: maxDataLevel,
                    needSkipLevelData: needSkipLevelData,
                    divisionSort: divisionSort,
                    levelConfig: levelConfig,
                    iso: iso,
                    isShowAbroad: isShowAbroad,
                });
        }
        else {
            next.isLeaf = true;
        }
        this.setState({
            dataSource: newData,
        });
        this.preHandleData(newData);
    };
    CnAddress.prototype.batchHandleCheck = function (vs, checked, value) {
        if (!vs.length)
            return value;
        var mergeProps = mergeGlobalConfig(this.props);
        var checkStrictly = mergeProps.checkStrictly, canOnlyCheckLeaf = mergeProps.canOnlyCheckLeaf;
        var v2n = this._v2n || {};
        var p2n = this._p2n || {};
        if (checkStrictly || canOnlyCheckLeaf) {
            processValue(value, vs, checked);
        }
        else {
            var valueDict = genDict(value);
            var processV1_1 = [];
            for (var i = 0; i < vs.length; i++) {
                var v = vs[i];
                forEachEnableNode(v2n[v], function (node) {
                    if (node.checkable === false)
                        return;
                    processV1_1.push(node.value);
                    // processValue(value, node.value, checked);
                });
            }
            processValue(value, processV1_1, checked, valueDict);
            valueDict = genDict(value);
            var pos = this.getPos(vs[0]);
            var ps = Object.keys(p2n);
            var currentPos = pos;
            var nums = pos.split('-');
            for (var i = nums.length; i > 2; i--) {
                var parentCheck = true;
                var parentPos = nums.slice(0, i - 1).join('-');
                if (p2n[parentPos].disabled ||
                    p2n[parentPos].checkboxDisabled ||
                    p2n[parentPos].checkable === false) {
                    currentPos = parentPos;
                    continue;
                }
                var parentValue = p2n[parentPos].value;
                // const parentChecked = value.indexOf(parentValue) > -1;
                var parentChecked = valueDict[parentValue];
                if (!checked && !isNumber(parentChecked)) {
                    break;
                }
                for (var j = 0; j < ps.length; j++) {
                    var p = ps[j];
                    var pnode = p2n[p];
                    if (isSiblingOrSelf(currentPos, p) && !pnode.disabled && !pnode.checkboxDisabled) {
                        var k = pnode.value;
                        if (pnode.checkable === false) {
                            if (!pnode.children || pnode.children.length === 0) {
                                continue;
                            }
                            for (var m = 0; m < pnode.children.length; m++) {
                                if (!pnode.children.every(function (child) { return isNodeChecked(child, value); })) {
                                    parentCheck = false;
                                    break;
                                }
                            }
                        }
                        else if (!isNumber(valueDict[k])) {
                            parentCheck = false;
                        }
                        if (!parentCheck)
                            break;
                    }
                }
                // todo 可能有性能问题，批量优化
                processValue(value, parentValue, parentCheck, valueDict);
                valueDict = genDict(value);
                currentPos = parentPos;
            }
        }
        return value;
    };
    CnAddress.prototype.flatValueFn = function (value) {
        var v2n = this._v2n;
        var p2n = this._p2n;
        return filterChildValue(value, v2n, p2n);
    };
    CnAddress.prototype.batchChange = function (tempValue, checkStrictly, canOnlyCheckLeaf) {
        if (checkStrictly || canOnlyCheckLeaf) {
            var data = this.getData(tempValue);
            this.handleChange(tempValue, data, {
                checkedData: data,
            });
        }
        else {
            // flat 用于扁平
            // complete 用于补全
            var flatValue = this.flatValueFn(tempValue);
            var flatData = this.getData(flatValue);
            var checkedData = this.getData(tempValue);
            var indeterminateValue = this.getIndeterminate(this.state.value);
            var indeterminateData = this.getData(indeterminateValue);
            this.handleChange(flatValue, flatData, {
                checkedData: checkedData,
                indeterminateData: indeterminateData,
            });
        }
    };
    CnAddress.prototype.onChoose = function (index, type) {
        var _a, _b, _c;
        var mergeProps = mergeGlobalConfig(this.props);
        var _d = this.state, expand = _d.expand, value = _d.value, dataSource = _d.dataSource;
        var checkStrictly = mergeProps.checkStrictly, canOnlyCheckLeaf = mergeProps.canOnlyCheckLeaf;
        // cn-ui与next获取形式不同，兼容
        var tempValue = ((_c = (_b = (_a = this.cascader) === null || _a === void 0 ? void 0 : _a._instance) === null || _b === void 0 ? void 0 : _b.state) === null || _c === void 0 ? void 0 : _c.value) || this.cascader.state.value;
        var v2n = this._v2n;
        var parentCode = expand[index - 1];
        var parentLevelInfo = v2n[parentCode];
        var shouldDel = [];
        var shouldCheck = [];
        if (type === 'all') {
            if (checkStrictly) {
                var temp = (parentLevelInfo === null || parentLevelInfo === void 0 ? void 0 : parentLevelInfo.children) || [];
                if (index === 0) {
                    temp = temp.concat(dataSource);
                }
                temp.map(function (i) {
                    shouldCheck.push(i.value);
                });
                tempValue = this.batchHandleCheck(shouldCheck, true, tempValue);
                this.batchChange(tempValue, checkStrictly, canOnlyCheckLeaf);
                return;
            }
            else if (canOnlyCheckLeaf) {
                ((parentLevelInfo === null || parentLevelInfo === void 0 ? void 0 : parentLevelInfo.children) || []).map(function (i) {
                    if (i.isLeaf) {
                        shouldCheck.push(i.value);
                    }
                    else {
                        shouldDel.push(i.value);
                    }
                });
                tempValue = this.batchHandleCheck(shouldCheck, true, tempValue);
                tempValue = this.batchHandleCheck(shouldDel, false, tempValue);
                this.batchChange(tempValue, checkStrictly, canOnlyCheckLeaf);
                return;
            }
            else {
                if (index === 0) {
                    var shouldCheck_1 = [];
                    dataSource.map(function (i) {
                        shouldCheck_1.push(i.value);
                    });
                    tempValue = this.batchHandleCheck(shouldCheck_1, true, tempValue);
                    this.batchChange(tempValue);
                    return;
                }
                ((parentLevelInfo === null || parentLevelInfo === void 0 ? void 0 : parentLevelInfo.children) || []).map(function (i) {
                    shouldCheck.push(i.value);
                });
                tempValue = this.batchHandleCheck(shouldCheck, true, tempValue);
                this.batchChange(tempValue);
            }
            return;
        }
        if (type === 'reverse') {
            if (index === 0) {
                var map_1 = {};
                dataSource.map(function (i) {
                    i.check = true;
                    if (canOnlyCheckLeaf && !i.isLeaf)
                        return;
                    if (!map_1[i.value])
                        map_1[i.value] = i;
                });
                value.map(function (o) {
                    if (map_1[o]) {
                        map_1[o].check = false;
                    }
                });
                Object.values(map_1).map(function (i) {
                    if (i.check) {
                        shouldCheck.push(i.value);
                    }
                    else {
                        shouldDel.push(i.value);
                    }
                });
                tempValue = this.batchHandleCheck(shouldCheck, true, tempValue);
                tempValue = this.batchHandleCheck(shouldDel, false, tempValue);
                this.batchChange(tempValue, checkStrictly, canOnlyCheckLeaf);
                return;
            }
            if (checkStrictly || canOnlyCheckLeaf) {
                var allCur = [];
                if (checkStrictly)
                    allCur = parentLevelInfo.children;
                if (canOnlyCheckLeaf)
                    allCur = parentLevelInfo.children.filter(function (i) { return i.isLeaf; });
                allCur.map(function (i) {
                    if (i === null || i === void 0 ? void 0 : i.value) {
                        if (!value.includes(i.value)) {
                            shouldCheck.push(i.value);
                        }
                        else {
                            shouldDel.push(i.value);
                        }
                    }
                });
                tempValue = this.batchHandleCheck(shouldCheck, true, tempValue);
                tempValue = this.batchHandleCheck(shouldDel, false, tempValue);
                this.batchChange(tempValue, checkStrictly, canOnlyCheckLeaf);
                return;
            }
            else {
                var allCur = (parentLevelInfo === null || parentLevelInfo === void 0 ? void 0 : parentLevelInfo.children) || [];
                var valueDict_1 = genDict(tempValue);
                allCur.map(function (i) {
                    if (i === null || i === void 0 ? void 0 : i.value) {
                        if (!isNumber(valueDict_1[i.value])) {
                            shouldCheck.push(i.value);
                        }
                        else {
                            shouldDel.push(i.value);
                        }
                    }
                });
                tempValue = this.batchHandleCheck(shouldCheck, true, tempValue);
                tempValue = this.batchHandleCheck(shouldDel, false, tempValue);
                this.batchChange(tempValue);
                return;
            }
        }
        if (type === 'clear') {
            if (index === 0) {
                if (checkStrictly || canOnlyCheckLeaf) {
                    dataSource.map(function (i) {
                        shouldDel.push(i.value);
                    });
                    tempValue = this.batchHandleCheck(shouldDel, false, tempValue);
                    this.batchChange(tempValue, checkStrictly, canOnlyCheckLeaf);
                }
                else {
                    this.batchChange([]);
                }
                return;
            }
            parentLevelInfo.children.map(function (i) {
                shouldDel.push(i.value);
            });
            tempValue = this.batchHandleCheck(shouldDel, false, tempValue);
            this.batchChange(tempValue, checkStrictly, canOnlyCheckLeaf);
        }
    };
    CnAddress.prototype.renderPopupContent = function () {
        var mergeProps = mergeGlobalConfig(this.props);
        var prefix = mergeProps.prefix, header = mergeProps.header, footer = mergeProps.footer, iso = mergeProps.iso, multiple = mergeProps.multiple, maxLevel = mergeProps.maxLevel, apiModel = mergeProps.config.apiModel, showFooter = mergeProps.showFooter;
        var showExpandCount = this.state.showExpandCount;
        return (React.createElement("div", { className: "".concat(prefix, "cascader-select-dropdown") },
            header,
            this.renderCascader(),
            footer ||
                (multiple && showFooter ? (React.createElement(Footer, { apiModel: apiModel, maxLevel: maxLevel, iso: iso, count: showExpandCount, onChoose: this.onChoose.bind(this) })) : null)));
    };
    CnAddress.prototype.renderPreview = function (others) {
        var mergeProps = mergeGlobalConfig(this.props);
        var prefix = mergeProps.prefix, multiple = mergeProps.multiple, className = mergeProps.className, renderPreview = mergeProps.renderPreview, readOnlyProps = mergeProps.readOnlyProps;
        var value = this.state.value;
        var previewCls = classNames(className, "".concat(prefix, "form-preview"));
        var items = (multiple ? this.getMultipleData(value) : this.getSingleData(value)) || [];
        if (!Array.isArray(items)) {
            items = [items];
        }
        if (typeof renderPreview === 'function') {
            return React.createElement("div", { className: previewCls }, renderPreview(items, mergeProps));
        }
        return (React.createElement("div", { className: previewCls }, items.length > 0 && (React.createElement(CnReadOnly
        // showFolder
        , __assign({ 
            // showFolder
            value: items.map(function (_a) {
                var label = _a.label;
                return label;
            }).join(', ') }, readOnlyProps)))));
    };
    CnAddress.prototype.render = function () {
        var _this = this;
        var mergeProps = mergeGlobalConfig(this.props);
        var prefix = mergeProps.prefix, size = mergeProps.size, hasArrow = mergeProps.hasArrow, hasBorder = mergeProps.hasBorder, hasClear = mergeProps.hasClear, label = mergeProps.label, placeholder = mergeProps.placeholder, className = mergeProps.className, showSearch = mergeProps.showSearch, popupStyle = mergeProps.popupStyle, popupClassName = mergeProps.popupClassName, popupContainer = mergeProps.popupContainer, popupProps = mergeProps.popupProps, followTrigger = mergeProps.followTrigger, resultAutoWidth = mergeProps.resultAutoWidth, tagInline = mergeProps.tagInline, maxTagPlaceholder = mergeProps.maxTagPlaceholder, style = mergeProps.style, maxTagCount = mergeProps.maxTagCount, _a = mergeProps.config.apiModel, apiModel = _a === void 0 ? 'mtop' : _a, isParseSearch = mergeProps.isParseSearch, isSearchById = mergeProps.isSearchById;
        var _b = this.props, multiple = _b.multiple, disabled = _b.disabled, iso = _b.iso, readOnly = _b.readOnly;
        var _c = this.state, value = _c.value, searchValue = _c.searchValue, visible = _c.visible, initLoading = _c.initLoading, searchLoading = _c.searchLoading;
        var others = pickOthers(Object.keys(CnAddress.propTypes), mergeProps);
        if (readOnly) {
            return this.renderPreview(others);
        }
        var popupContent = this.renderPopupContent();
        var props = {
            tagInline: tagInline,
            prefix: prefix,
            className: classNames(className, "cn-address"),
            size: size,
            placeholder: placeholder,
            disabled: disabled,
            hasArrow: hasArrow,
            hasBorder: hasBorder,
            hasClear: hasClear,
            label: label,
            readOnly: readOnly,
            autoWidth: false,
            mode: multiple ? 'multiple' : 'single',
            value: multiple ? this.getMultipleData(value) : this.getSingleData(value),
            onChange: this.handleClear,
            onRemove: this.handleRemove,
            visible: visible,
            onVisibleChange: this.handleVisibleChange,
            showSearch: showSearch,
            onSearch: this.handleSearch,
            onKeyDown: this.handleKeyDown,
            popupContent: popupContent,
            popupStyle: popupStyle,
            popupClassName: classNames(popupClassName, "cn-address-popup"),
            popupContainer: popupContainer,
            popupProps: popupProps,
            followTrigger: followTrigger,
            maxTagCount: maxTagCount,
        };
        if (initLoading || searchLoading)
            props.state = 'loading';
        if (showSearch && apiModel === 'mtop') {
            props.popupProps = __assign(__assign({}, popupProps), { ref: this.getPopup, afterOpen: this.handleAfterOpen });
            props.autoWidth = resultAutoWidth && !!searchValue;
        }
        var maxTagPlaceholder2 = function (selectedValues) {
            var arrValue = transferObjToAvailable(value, __assign({}, mergeProps));
            var labels = _this.getData(arrValue)
                .map(function (o) { return o === null || o === void 0 ? void 0 : o.label; })
                .filter(function (item) { return item; });
            // 使用labels非 selectedValues，因为可能传入id不存在
            var trigger = (React.createElement("span", { className: "cn-address-select-tag-compact-inner" }, $i18n.get({
                id: 'LabelsLengthItemSelected',
                dm: '已选择 {labelsLength} 项',
                ns: 'CnAddress',
            }, { labelsLength: labels.length })));
            return React.createElement(CnTooltip, { trigger: trigger }, labels && labels.join(', '));
        };
        if ('maxTagPlaceholder' in mergeProps) {
            props.maxTagPlaceholder = maxTagPlaceholder;
        }
        else {
            props.maxTagPlaceholder = maxTagPlaceholder2;
        }
        if ('placeholder' in mergeProps) {
            props.placeholder = placeholder;
        }
        else {
            props.placeholder = $i18n.get({
                id: 'PleaseSelect',
                dm: '请选择',
                ns: 'CnAddress',
            });
        }
        if ('style' in mergeProps) {
            props.style = style;
        }
        if (visible && showSearch && isParseSearch && iso === 'CN') {
            props.innerAfter = (React.createElement(CnIcon, { type: "filter", title: $i18n.get({
                    id: 'ParseSearch',
                    dm: '解析搜索',
                    ns: 'CnAddress',
                }), style: { marginTop: 2, marginRight: 4, color: '#999' } }));
        }
        if (visible && showSearch && isSearchById) {
            props.innerAfter = (React.createElement(CnIcon, { className: "cn-address-suffix-icon", type: "attachment", title: $i18n.get({ id: 'IDSearch', dm: 'ID搜索', ns: 'CnAddress' }) }));
        }
        return React.createElement(CnAsyncSelect, __assign({ ref: this.saveSelectRef }, props));
    };
    CnAddress.propTypes = {
        prefix: PropTypes.string,
        pure: PropTypes.bool,
        className: PropTypes.string,
        /**
         * 选择框大小
         */
        size: PropTypes.oneOf(['small', 'medium', 'large']),
        /**
         * 选择框占位符
         */
        placeholder: PropTypes.string,
        /**
         * 是否禁用
         */
        disabled: PropTypes.bool,
        /**
         * 是否有下拉箭头
         */
        hasArrow: PropTypes.bool,
        /**
         * 是否有边框
         */
        hasBorder: PropTypes.bool,
        /**
         * 是否有清除按钮
         */
        hasClear: PropTypes.bool,
        /**
         * 自定义内联 label
         */
        label: PropTypes.node,
        /**
         * 是否只读，只读模式下可以展开弹层但不能选。废除。
         * 调整形态，预览态，与isPreview形态一致
         */
        readOnly: PropTypes.bool,
        /**
         * （非受控）默认值
         */
        defaultValue: PropTypes.any, // PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        /**
         * （受控）当前值
         */
        value: PropTypes.any, // PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        /**
         * 选中值改变时触发的回调函数
         * @param {String|Array} value 选中的值，单选时返回单个值，多选时返回数组
         * @param {Object|Array} data 选中的数据，包括 value 和 label，单选时返回单个值，多选时返回数组，父子节点选中关联时，同时选中，只返回父节点
         * @param {Object} extra 额外参数
         * @param {Array} extra.selectedPath 单选时选中的数据的路径
         * @param {Boolean} extra.checked 多选时当前的操作是选中还是取消选中
         * @param {Object} extra.currentData 多选时当前操作的数据
         * @param {Array} extra.checkedData 多选时所有被选中的数据
         * @param {Array} extra.indeterminateData 多选时半选的数据
         */
        onChange: PropTypes.func,
        /**
         * 默认展开值，如果不设置，组件内部会根据 defaultValue/value 进行自动设置
         */
        defaultExpandedValue: PropTypes.arrayOf(PropTypes.string),
        /**
         * （受控）当前展开值
         */
        expandedValue: PropTypes.arrayOf(PropTypes.string),
        /**
         * 展开触发的方式
         */
        expandTriggerType: PropTypes.oneOf(['click', 'hover']),
        onExpand: PropTypes.func,
        /**
         * 是否开启虚拟滚动
         */
        useVirtual: PropTypes.bool,
        /**
         * 是否多选
         */
        multiple: PropTypes.bool,
        /**
         * 是否选中即发生改变, 该属性仅在单选模式下有效
         */
        changeOnSelect: PropTypes.bool,
        /**
         * 是否只能勾选叶子项的checkbox，该属性仅在多选模式下有效
         */
        canOnlyCheckLeaf: PropTypes.bool,
        /**
         * 父子节点是否选中不关联
         */
        checkStrictly: PropTypes.bool,
        /**
         * 每列列表样式对象
         */
        listStyle: PropTypes.object,
        /**
         * 每列列表类名
         */
        listClassName: PropTypes.string,
        /**
         * 选择框单选时展示结果的自定义渲染函数
         * @param {Array} label 选中路径的文本数组
         * @return {ReactNode} 渲染在选择框中的内容
         * @default 单选时：labelPath => labelPath.join(' / ')；多选时：labelPath => labelPath[labelPath.length - 1]
         */
        displayRender: PropTypes.func,
        /**
         * 渲染 item 内容的方法
         * @param {Object} item 渲染节点的item
         * @return {ReactNode} item node
         */
        itemRender: PropTypes.func,
        /**
         * 是否显示搜索框
         */
        showSearch: PropTypes.bool,
        /**
         * 自定义搜索函数
         * @param {String} searchValue 搜索的关键字
         * @param {Array} path 节点路径
         * @return {Boolean} 是否匹配
         * @default 根据路径所有节点的文本值模糊匹配
         */
        filter: PropTypes.func,
        /**
         * 当搜索框值变化时回调
         * @param {String} value 数据
         * @version 1.23
         */
        onSearch: PropTypes.func,
        /**
         * 搜索结果自定义渲染函数
         * @param {String} searchValue 搜索的关键字
         * @param {Array} path 匹配到的节点路径
         * @return {ReactNode} 渲染的内容
         * @default 按照节点文本 a / b / c 的模式渲染
         */
        resultRender: PropTypes.func,
        /**
         * 搜索结果列表是否和选择框等宽
         */
        resultAutoWidth: PropTypes.bool,
        /**
         * 无数据时显示内容
         */
        notFoundContent: PropTypes.node,
        /**
         * 异步加载数据函数
         * @param {Object} data 当前点击异步加载的数据
         */
        loadData: PropTypes.func,
        /**
         * 自定义下拉框头部
         */
        header: PropTypes.node,
        /**
         * 自定义下拉框底部
         */
        footer: PropTypes.node,
        /**
         * 初始下拉框是否显示
         */
        defaultVisible: PropTypes.bool,
        /**
         * 当前下拉框是否显示
         */
        visible: PropTypes.bool,
        /**
         * 下拉框显示或关闭时触发事件的回调函数
         * @param {Boolean} visible 是否显示
         * @param {String} type 触发显示关闭的操作类型, fromTrigger 表示由trigger的点击触发； docClick 表示由document的点击触发
         */
        onVisibleChange: PropTypes.func,
        /**
         * 下拉框自定义样式对象
         */
        popupStyle: PropTypes.object,
        /**
         * 下拉框样式自定义类名
         */
        popupClassName: PropTypes.string,
        /**
         * 下拉框挂载的容器节点
         */
        popupContainer: PropTypes.any,
        /**
         * 透传到 Popup 的属性对象
         */
        popupProps: PropTypes.object,
        /**
         * 是否跟随滚动
         */
        followTrigger: PropTypes.bool,
        /**
         * 是否为预览态
         */
        // isPreview: PropTypes.bool,
        /**
         * 预览态模式下渲染的内容
         * @param {Array<data>} value 选择值 { label: , value:}
         */
        renderPreview: PropTypes.func,
        /**
         * 是否是不可变数据
         * @version 1.23
         */
        immutable: PropTypes.bool,
        /**
         * 可选区划层级
         */
        maxLevel: PropTypes.number,
        /**
         * value 使用对象类型, 同时 onChange 第一个参数返回也修改为对象类型
         */
        useDetailValue: PropTypes.bool,
        /**
         * useDetailValue开启时 详细数据映射表
         */
        useDetailMap: PropTypes.object,
        /**
         * 是否开启解析搜索，仅国内区划生效，iso=CN
         */
        isParseSearch: PropTypes.bool,
        /**
         * 是否开启ID搜索
         */
        isSearchById: PropTypes.bool,
        /**
         * 是否显示【中国】下【海外】
         */
        isShowAbroad: PropTypes.bool,
        /**
         * 屏蔽敏感数据
         */
        hideSensitiveDivision: PropTypes.bool,
        /**
         * 搜索严格模式，限制层级
         */
        searchStrictly: PropTypes.bool,
        /**
         * 当不存在ID回填时，检测到不是合法ID回调
         * @param {Object} value 数据
         */
        onNoExistId: PropTypes.func,
    };
    CnAddress.defaultProps = {
        prefix: 'cn-next-',
        iso: 'CN',
        dataLanguage: 'CN',
        pure: false,
        // size: "medium",
        disabled: false,
        hasArrow: true,
        hasBorder: true,
        hasClear: false,
        defaultValue: null,
        expandTriggerType: 'click',
        onExpand: function () { },
        useVirtual: false,
        multiple: false,
        changeOnSelect: false,
        canOnlyCheckLeaf: false,
        checkStrictly: false,
        showTopLevel: false,
        showFooter: false,
        divisionSort: '',
        maxLevel: 4,
        showSearch: false,
        needSkipLevelData: true,
        showDisabledDivision: false,
        markedDeletion: false,
        includeList: [],
        excludeList: [],
        blackList: [],
        whitelistCode: '',
        config: {
            apiModel: 'mtop',
            isJsonpForMtop: false,
        },
        useDetailValue: false,
        isParseSearch: false,
        isSearchById: false,
        isShowAbroad: false,
        filter: function (searchValue, path) {
            return path.some(function (item) {
                return String(item.label).toLowerCase().indexOf(String(searchValue).toLowerCase()) > -1;
            });
        },
        resultAutoWidth: true,
        defaultVisible: false,
        onchange: function () { },
        onVisibleChange: function () { },
        popupProps: {},
        immutable: false,
        hideSensitiveDivision: true,
        searchStrictly: true,
    };
    return CnAddress;
}(Component));
export default CnAddress;
