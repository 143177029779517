import { __assign, __read, __rest, __spreadArray } from "tslib";
import * as React from 'react';
/**
 * util module
 */
export function preventDefault(e) {
    e.preventDefault();
}
/**
 * 在 Select 中，认为 null 和 undefined 都是空值
 * @param {*} n any object
 * @return {boolean}
 */
export function isNull(n) {
    return n === null || n === undefined;
}
/**
 * 将字符串中的正则表达式关键字符添加转义
 * @param {string} str
 * @return {string}
 */
export function escapeForReg(str) {
    return str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}
/**
 * filter by key
 * @param {string} key filter key
 * @param {object} item item object
 * @return {boolean} it's filtered
 */
export function filter(key, item) {
    var _key = escapeForReg("".concat(key));
    var regExp = new RegExp("(".concat(_key, ")"), 'ig');
    return regExp.test("".concat(item.value)) || regExp.test("".concat(item.label));
}
/**
 * loop map
 * @param {Array} dataSource
 * @param {function} callback
 * @return {Array}
 * ----
 * @callback ~loopCallback
 * @param {object} option
 */
export function loopMap(dataSource, callback) {
    var result = [];
    dataSource.forEach(function (option) {
        if (option.children) {
            var children = loopMap(option.children, callback);
            result.push(__assign(__assign({}, option), { children: children }));
        }
        else {
            // eslint-disable-next-line callback-return
            var tmp = callback(option);
            tmp && result.push(tmp);
        }
    });
    return result;
}
/**
 * Normalize dataSource
 * @static
 * @param {Array} dataSource
 * @param {number} [deep=0] recursion deep level
 * ----
 * value priority: value > 'index'
 * label priority: label > 'value' > 'index'
 * disabled: disabled === true
 */
export function normalizeDataSource(dataSource, deep, showDataSourceChildren) {
    if (deep === void 0) { deep = 0; }
    if (showDataSourceChildren === void 0) { showDataSourceChildren = true; }
    var source = [];
    dataSource.forEach(function (item, index) {
        // enable array of basic type
        if (/string|boolean|number/.test(typeof item)) {
            item = { label: "".concat(item), value: item };
        }
        // filter off addon item
        if (item.__isAddon) {
            return;
        }
        var item2 = { deep: deep };
        // deep < 1: only 2 level allowed
        if (Array.isArray(item.children) && deep < 1 && showDataSourceChildren) {
            // handle group
            item2.label = item.label || item.value || "Group ".concat(index);
            // parse children
            item2.children = normalizeDataSource(item.children, deep + 1);
        }
        else {
            var value = item.value, label = item.label, disabled = item.disabled, title = item.title, others = __rest(item, ["value", "label", "disabled", "title"]);
            item2.value = !isNull(value) ? value : "".concat(index);
            item2.label = label || "".concat(item2.value);
            if ('title' in item) {
                item2.title = title;
            }
            disabled === true && (item2.disabled = true);
            Object.assign(item2, others);
        }
        source.push(item2);
    });
    return source;
}
/**
 * Get flatten dataSource
 * @static
 * @param  {Array} dataSource structured dataSource
 * @return {Array}
 */
export function flattingDataSource(dataSource) {
    var source = [];
    dataSource.forEach(function (item) {
        if (Array.isArray(item.children)) {
            source.push.apply(source, __spreadArray([], __read(flattingDataSource(item.children)), false));
        }
        else {
            source.push(item);
        }
    });
    return source;
}
export function filterDataSource(dataSource, key, filterFunc, addonKey) {
    if (!Array.isArray(dataSource)) {
        return [];
    }
    if (typeof key === 'undefined' || key === null) {
        return [].concat(dataSource);
    }
    var addKey = true;
    var menuDataSource = loopMap(dataSource, function (option) {
        if (key === "".concat(option.value)) {
            addKey = false;
        }
        return filterFunc(key, option) && !option.__isAddon && option;
    });
    // if key not in menuDataSource, add key to dataSource
    if (addonKey && key && addKey) {
        menuDataSource.unshift({
            value: key,
            label: key,
            __isAddon: true,
        });
    }
    return menuDataSource;
}
function getKeyItemByValue(value, valueMap) {
    var item;
    if (typeof value === 'object') {
        if (Object.prototype.hasOwnProperty.call(value, 'value')) {
            item = value;
        }
        else {
            item = __assign({ value: '' }, value);
        }
    }
    else {
        item = valueMap["".concat(value)] || {
            value: value,
            label: value,
        };
    }
    return item;
}
/**
 * compute valueDataSource by new value
 * @param {Array/String} value 数据
 * @param {*} mapMenuDS  通过 dataSource 建立 value => {value,label} 的映射关系表
 * @returns {Object} value: [value]; valueDS: [{value,label}]; mapValueDS: {value: {value,label}}
 */
export function getValueDataSource(value, mapMenuDS) {
    if (isNull(value)) {
        return {
            value: [],
            valueDS: [],
        };
    }
    if (!Array.isArray(value)) {
        value = [value];
    }
    var newValue = [];
    var newValueDS = [];
    var _newMapDS = Object.assign({}, mapMenuDS);
    value.forEach(function (v) {
        var item = getKeyItemByValue(v, _newMapDS);
        newValueDS.push(item);
        newValue.push(item.value);
    });
    return {
        value: newValue, // [value]
        valueDS: newValueDS, // [{value,label}]
    };
}
/**
 * Get flatten dataSource
 * @static
 * @param  {any} value structured dataSource
 * @return {String}
 */
export function valueToSelectKey(value) {
    var val;
    if (typeof value === 'object' && Object.prototype.hasOwnProperty.call(value, 'value')) {
        val = value.value;
    }
    else {
        val = value;
    }
    return "".concat(val);
}
/**
 * 高亮文字
 */
export var highlightRender = function (itemText, searchKey) {
    if (!searchKey) {
        return itemText;
    }
    if (searchKey && searchKey.length) {
        var key = searchKey.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
        var reg = new RegExp("(".concat(key, ")"), 'ig');
        itemText = itemText.replace(reg, function (x) { return "<span class=\"cn-multi-select-highlight\">".concat(x, "</span>"); });
    }
    return React.createElement("span", { dangerouslySetInnerHTML: { __html: itemText } });
};
