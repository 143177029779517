import cloneDeep from 'lodash/cloneDeep';
/**
 * 获取某国家层级
 * @param props 组件参数
 * @param isoCode 国家编码
 * @returns 返回当前层级
 */
export var getFinallyLevel = function (props, isoCode) {
    if (isoCode === void 0) { isoCode = ''; }
    var maxLevel = props.maxLevel, iso = props.iso, showTopLevel = props.showTopLevel, levelConfig = props.levelConfig;
    var curLevel = maxLevel;
    // 显示全部国家时
    if (iso === '' || iso === null) {
        if (levelConfig === null || levelConfig === void 0 ? void 0 : levelConfig[isoCode]) {
            curLevel = levelConfig === null || levelConfig === void 0 ? void 0 : levelConfig[isoCode];
        }
        else {
            curLevel = maxLevel;
        }
    }
    else if (showTopLevel) {
        curLevel = maxLevel;
    }
    else {
        curLevel = maxLevel + 1;
    }
    return curLevel;
};
/**
 * 获取当前数据项的逻辑意义上层级，即依次向上递归+1，直到无父级
 * @param item
 * @param mapData
 * @returns final level
 */
export function getItemRealLevel(item, mapData, l) {
    if (l === void 0) { l = 0; }
    var level = l;
    var parentId = item.parentId;
    if (mapData[parentId]) {
        level += 1;
        return getItemRealLevel(mapData[parentId], mapData, level);
    }
    return level;
}
/**
 * 处理逻辑level与原始level
 * @param data
 * @param config
 * @param l
 * @returns
 */
export function formatCdnDataLevel(data, config, l) {
    if (l === void 0) { l = 0; }
    var showTopLevel = config.showTopLevel;
    var level = l;
    data.forEach(function (item) {
        var _a;
        if (!showTopLevel) {
            item.rawLevel = cloneDeep(item.level);
            item.level = cloneDeep(level) + 1;
        }
        if (((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length) !== 0) {
            formatCdnDataLevel(item.children || [], config, item.level);
        }
    });
    return data;
}
/**
 *
 * @param data
 * @param config
 */
export function handleCdnDataSkip(data, config) {
    var maxLevel = config.maxLevel, needSkipLevelData = config.needSkipLevelData, showTopLevel = config.showTopLevel;
    data.forEach(function (item) {
        var _a, _b;
        var realLevel = showTopLevel ? item.rawLevel : item.level;
        var isLeaf = realLevel >= maxLevel;
        item.isLeaf = isLeaf;
        if (item.leaf) {
            item.isLeaf = item.leaf;
        }
        if (isLeaf) {
            item.children = [];
        }
        else {
            // 处理跳级数据
            var filterData = (item.children || []).filter(function (f) {
                if (showTopLevel) {
                    if (!needSkipLevelData && f.rawLevel > maxLevel) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else if (!needSkipLevelData && f.rawLevel > maxLevel + 1) {
                    return false;
                }
                else {
                    return true;
                }
            });
            item.children = item.children ? filterData : [];
        }
        if (((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            item.isLeaf = true;
        }
        if (((_b = item === null || item === void 0 ? void 0 : item.children) === null || _b === void 0 ? void 0 : _b.length) > 0) {
            handleCdnDataSkip(item.children, config);
        }
    });
    return data;
}
/**
 * 校验是否为跳级数据
 * @param item 区划数据对象
 * @returns true or false
 */
export function isSkipLevelData(item) {
    var _a, _b;
    if (((_a = item === null || item === void 0 ? void 0 : item.extra) === null || _a === void 0 ? void 0 : _a.skipLevel) === 'true' || ((_b = item === null || item === void 0 ? void 0 : item.extra) === null || _b === void 0 ? void 0 : _b.skipLevel) === true) {
        return true;
    }
    return false;
}
