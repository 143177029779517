import { __awaiter, __generator } from "tslib";
import axios from 'axios';
import PropTypes, { any } from 'prop-types';
var CndzkEntranceApiUrl = /** @class */ (function () {
    function CndzkEntranceApiUrl(options) {
        this.apis = {
            topLevelList: this.getApi.bind(this),
            topLevelInfo: this.getApi.bind(this),
            regionChain: this.getApi.bind(this),
            children: this.getApi.bind(this),
            regionChainByName: this.getApi.bind(this),
        };
        var urlBase = options.urlBase, apis = options.apis, iso = options.iso, apiConfig = options.apiConfig;
        urlBase = (urlBase && urlBase.trim && urlBase.trim()) || '';
        this.urlBase = urlBase;
        this.apis = apis;
        this.apiConfig = apiConfig;
        this.iso = iso;
        this.get = this.get.bind(this);
    }
    CndzkEntranceApiUrl.prototype.get = function (apiName, data) {
        if (data === void 0) { data = {}; }
        var mSelf = this;
        var n = 20;
        return new Promise(function (resolve, reject) {
            return __awaiter(this, void 0, void 0, function () {
                function action() {
                    var _a;
                    n--;
                    var api = mSelf.getApi(apiName, data);
                    if (n == 0 || api) {
                        axios({
                            url: "".concat(mSelf.urlBase, "/").concat(api),
                            params: ((_a = mSelf.apiConfig) === null || _a === void 0 ? void 0 : _a.params) || {},
                        })
                            .then(function (res) {
                            resolve(((res || {}).data || {}).data || []);
                        })
                            .catch(function (err) {
                            console.error('CnAddress Error: api request failed', err);
                            reject();
                        });
                    }
                    else if (n) {
                        setTimeout(action, 300);
                    }
                    else {
                        console.error('CnAddress Error: error');
                    }
                }
                return __generator(this, function (_a) {
                    action();
                    return [2 /*return*/];
                });
            });
        });
    };
    CndzkEntranceApiUrl.prototype.getApi = function (apiName, data) {
        if (data === void 0) { data = {}; }
        var iso = this.iso;
        var id = data.id, divisionToken = data.divisionToken;
        var arr = [this.apiConfig[apiName]];
        if (apiName === 'topLevelList') {
            return arr.join('/');
        }
        else if (apiName === 'topLevelInfo') {
            arr.push(iso);
            return arr.join('/');
        }
        else if (apiName === 'regionChain' || apiName === 'children') {
            arr.push(id);
            return arr.join('/');
        }
        else if (apiName === 'regionChainByName') {
            arr.push(divisionToken);
            return arr.join('/');
        }
        else {
            return false;
        }
    };
    CndzkEntranceApiUrl.propTypes = {
        urlBase: PropTypes.string,
        iso: PropTypes.string,
        apis: any,
        apiConfig: any,
    };
    return CndzkEntranceApiUrl;
}());
export default CndzkEntranceApiUrl;
