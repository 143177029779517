import { __read } from "tslib";
import './cn-ding-talk-camera-upload.scss';
import $i18n from 'panda-i18n';
import * as React from 'react';
import classNames from 'classnames';
import { UploadList } from './upload-list';
import { cnUtils } from '@cainiaofe/cn-ui';
import { pcCssPrefix } from '../constant/css-prefix';
export var CnDingTalkCameraUpload = function (props) {
    var _a;
    var _b = props.readOnly, readOnly = _b === void 0 ? false : _b;
    var _c = __read(cnUtils.useControlled(props), 2), _d = _c[0], value = _d === void 0 ? [] : _d, onChange = _c[1];
    var disabled = props.disabled || readOnly;
    return (React.createElement("div", { className: classNames("".concat(pcCssPrefix), (_a = {},
            _a["".concat(pcCssPrefix, "-readonly")] = readOnly,
            _a)) },
        React.createElement(UploadList, { disabled: disabled, value: value, onChange: onChange }),
        disabled ? null : (React.createElement("div", { className: "".concat(pcCssPrefix, "-disabled-tip") }, $i18n.get({
            id: 'OnlyDingTalkPhotoUploadIsSupport_184941936',
            dm: '仅支持钉钉拍照上传，请前往钉钉移动端拍照上传。',
            ns: 'CnDingTalkCameraUpload',
        })))));
};
CnDingTalkCameraUpload.displayName = 'CnDingTalkCameraUpload';
