import { __assign, __rest } from "tslib";
import $i18n from 'panda-i18n';
import * as React from 'react';
import omit from 'lodash/omit';
import cx from 'classnames';
import { Loading as NextLoading, Icon, CnMenu } from '@cainiaofe/cn-ui';
import './index.scss';
import { SelectableItem as MenuItem } from './menu-item';
var MenuPopupItem = CnMenu.PopupItem, MenuCheckboxItem = CnMenu.CheckboxItem, MenuRadioItem = CnMenu.RadioItem, SubMenu = CnMenu.SubMenu, Group = CnMenu.Group, Divider = CnMenu.Divider;
var renderChildren = function (dataSource) {
    var menuItemList = [];
    Array.isArray(dataSource) &&
        dataSource.forEach(function (dataItem) {
            var _a, _b, _c, _d, _e, _f, _g;
            if (!dataItem.type || ((_a = dataItem.type) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'item') {
                menuItemList.push(React.createElement(MenuItem, __assign({}, omit(dataItem, ['type']))));
            }
            if (((_b = dataItem.type) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === 'popupitem') {
                menuItemList.push(React.createElement(MenuPopupItem, __assign({}, omit(dataItem, ['type']))));
            }
            if (((_c = dataItem.type) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === 'checkboxitem') {
                var children = dataItem.children || dataItem.label;
                menuItemList.push(React.createElement(MenuCheckboxItem, __assign({}, omit(dataItem, ['type', 'label', 'children'])), children));
            }
            if (((_d = dataItem.type) === null || _d === void 0 ? void 0 : _d.toLowerCase()) === 'radioitem') {
                var children = dataItem.children || dataItem.label;
                menuItemList.push(React.createElement(MenuRadioItem, __assign({}, omit(dataItem, ['type', 'label', 'children'])), children));
            }
            if (((_e = dataItem.type) === null || _e === void 0 ? void 0 : _e.toLowerCase()) === 'divider') {
                menuItemList.push(React.createElement(Divider, __assign({}, omit(dataItem, ['type']))));
            }
            if (((_f = dataItem.type) === null || _f === void 0 ? void 0 : _f.toLowerCase()) === 'group') {
                // Group 一般情况下没有值（key），因此给定默认值
                menuItemList.push(React.createElement(Group, __assign({ key: "group-".concat(dataItem.label) }, omit(dataItem, ['type', 'children'])), renderChildren(dataItem.children)));
            }
            if (((_g = dataItem.type) === null || _g === void 0 ? void 0 : _g.toLowerCase()) === 'submenu') {
                menuItemList.push(React.createElement(SubMenu, __assign({}, omit(dataItem, ['type', 'children'])), renderChildren(dataItem.children)));
            }
        });
    return menuItemList;
};
export var Menu = function (props) {
    var _a;
    var style = props.style, className = props.className, dataSource = props.dataSource, loading = props.loading, error = props.error, densityMode = props.densityMode, otherProps = __rest(props, ["style", "className", "dataSource", "loading", "error", "densityMode"]);
    var renderLoadingContent = function () {
        return React.createElement(NextLoading, { size: "medium", className: "cn-multi-select-menu-loading" });
    };
    var renderErrorContent = function () {
        var errorText = typeof error === 'boolean'
            ? $i18n.get({
                id: 'SomethingWentWrong',
                dm: '出错了',
                ns: 'CnFastMultiSelect',
            })
            : error;
        return (React.createElement("div", { className: "cn-multi-select-menu-error" },
            React.createElement("div", { className: "cn-multi-select-menu-error-content" },
                React.createElement(Icon, { type: "ic_cry", size: "small" }),
                React.createElement("div", { className: "cn-multi-select-menu-error-content-text" }, errorText))));
    };
    var menuRef = React.useRef(null);
    var classes = cx((_a = {
            'cn-multi-select-menu': true
        },
        _a[className] = !!className,
        _a));
    var children;
    if (loading) {
        children = renderLoadingContent();
    }
    else if (error) {
        children = renderErrorContent();
    }
    else {
        children = renderChildren(dataSource);
    }
    var menuProps = __assign({ className: classes, children: children }, otherProps);
    return React.createElement(CnMenu, __assign({ ref: menuRef }, menuProps));
};
Menu.displayName = 'Menu';
export default Menu;
