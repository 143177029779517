import React from 'react';
import { CnIcon } from '@cainiaofe/cn-ui';
import { DragSource } from 'react-dnd';
var Drag = function (_a) {
    var isDragging = _a.isDragging, connectDragSource = _a.connectDragSource, connectDragPreview = _a.connectDragPreview, x = _a.x, y = _a.y, children = _a.children;
    var opacity = isDragging ? 0.4 : 1;
    var dragHandler = (React.createElement("span", { style: { display: 'flex', cursor: 'move', alignItems: 'center' } },
        React.createElement(CnIcon, { className: "cn-rule-tree-icon", type: "list" })));
    return connectDragPreview(React.createElement("div", { style: { opacity: opacity, left: x, top: y }, className: "drag" },
        connectDragSource(dragHandler),
        React.createElement("span", { style: { display: 'flex', alignItems: 'center', gap: '10px' } }, children)));
};
export default DragSource(function (_a) {
    var type = _a.type;
    return type;
}, {
    beginDrag: function (props) { return props; },
}, function (connect, monitor) { return ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
}); })(Drag);
export var UnDrag = function (_a) {
    var children = _a.children, x = _a.x, y = _a.y;
    return (React.createElement("div", { className: "un-drag", style: {
            position: 'absolute',
            left: x,
            top: y,
        } }, children));
};
