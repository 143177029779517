import * as React from 'react';
import { CnTagCloseable } from '@cainiaofe/cn-ui';
export var ValueList = function (props) {
    var value = props.value, onChange = props.onChange;
    if (!value.length) {
        return null;
    }
    return (React.createElement(React.Fragment, null, value.map(function (item, index) { return (React.createElement(CnTagCloseable
    // eslint-disable-next-line react/no-array-index-key
    , { 
        // eslint-disable-next-line react/no-array-index-key
        key: "".concat(item, "_").concat(index), type: "primary", onClose: function () {
            onChange(value.filter(function (_, i) { return i !== index; }));
            return true;
        } }, item)); })));
};
