import { camelcase, hyphenate } from './string';
import { each, isPlainObject } from './object';

/**
 * 是否能使用 DOM 方法
 * @type {Boolean}
 */
export const hasDOM =
  typeof window !== 'undefined' && !!window.document && !!document.createElement;

/**
 * 节点是否包含指定 className
 * @param  {Element}  node
 * @param  {String}  className
 * @return {Boolean}
 *
 * @example
 * dom.hasClass(document.body, 'foo');
 */
export function hasClass(node, className) {
  /* istanbul ignore if */
  if (!hasDOM || !node) {
    return false;
  }

  if (node.classList) {
    return node.classList.contains(className);
  } else {
    return node.className.indexOf(className) > -1;
  }
}

/**
 * 添加 className
 * @param {Element} node
 * @param {String} className
 *
 * @example
 * dom.addClass(document.body, 'foo');
 */
export function addClass(node, className, _force) {
  /* istanbul ignore if */
  if (!hasDOM || !node) {
    return;
  }

  if (node.classList) {
    node.classList.add(className);
  } else if (_force === true || !hasClass(node, className)) {
    node.className += ` ${className}`;
  }
}

/**
 * 移除 className
 * @param  {Element} node
 * @param  {String} className
 *
 * @example
 * dom.removeClass(document.body, 'foo');
 */
export function removeClass(node, className, _force) {
  /* istanbul ignore if */
  if (!hasDOM || !node) {
    return;
  }

  if (node.classList) {
    node.classList.remove(className);
  } else if (_force === true || hasClass(node, className)) {
    node.className = node.className.replace(className, '').replace(/\s+/g, ' ').trim();
  }
}

/**
 * 获取元素计算后的样式
 * @private
 * @param  {Element} node
 * @return {Object}
 */
function _getComputedStyle(node) {
  return node && node.nodeType === 1 ? window.getComputedStyle(node, null) : {};
}

const PIXEL_PATTERN = /margin|padding|width|height|max|min|offset|size|top/i;
const removePixel = { left: 1, top: 1, right: 1, bottom: 1 };

/**
 * 校验并修正元素的样式属性值
 * @private
 * @param  {Element} node
 * @param  {String} type
 * @param  {Number} value
 */
function _getStyleValue(node, type, value) {
  type = type.toLowerCase();

  if (value === 'auto') {
    if (type === 'height') {
      return node.offsetHeight || 0;
    }
    if (type === 'width') {
      return node.offsetWidth || 0;
    }
  }

  if (!(type in removePixel)) {
    // 属性值是否需要去掉 px 单位，这里假定此类的属性值都是 px 为单位的
    removePixel[type] = PIXEL_PATTERN.test(type);
  }

  return removePixel[type] ? parseFloat(value) || 0 : value;
}

const floatMap = { cssFloat: 1, styleFloat: 1, float: 1 };

export function getNodeHozWhitespace(node) {
  const paddingLeft = getStyle(node, 'paddingLeft');
  const paddingRight = getStyle(node, 'paddingRight');
  const marginLeft = getStyle(node, 'marginLeft');
  const marginRight = getStyle(node, 'marginRight');
  return paddingLeft + paddingRight + marginLeft + marginRight;
}

/**
 * 获取元素计算后的样式
 * @param  {Element} node DOM 节点
 * @param  {String} name 属性名
 * @return {Number|Object}
 */
export function getStyle(node, name) {
  /* istanbul ignore if */
  if (!hasDOM || !node) {
    return null;
  }

  const style = _getComputedStyle(node);

  // 如果不指定属性名，则返回全部值
  if (arguments.length === 1) {
    return style;
  }

  // if style is {}(e.g. node isn't a element node), return null
  if (isPlainObject(style)) {
    return null;
  }

  name = floatMap[name] ? ('cssFloat' in node.style ? 'cssFloat' : 'styleFloat') : name;

  return _getStyleValue(
    node,
    name,
    style.getPropertyValue(hyphenate(name)) || node.style[camelcase(name)],
  );
}

/**
 * 设置元素的样式
 * @param {Element} node  DOM 节点
 * @param {Object|String} name  属性名，或者是一个对象，包含多个属性
 * @param {Number|String} value 属性值
 *
 * @example
 * // 设置单个属性值
 * dom.setStyle(mountNode, 'width', 100);
 * // 设置多条属性值
 * dom.setStyle(mountNode, {
 *     width: 100,
 *     height: 200
 * });
 */
export function setStyle(node, name, value) {
  /* istanbul ignore if */
  if (!hasDOM || !node) {
    return false;
  }

  // 批量设置多个值
  if (typeof name === 'object' && arguments.length === 2) {
    each(name, (val, key) => setStyle(node, key, val));
  } else {
    name = floatMap[name] ? ('cssFloat' in node.style ? 'cssFloat' : 'styleFloat') : name;
    if (typeof value === 'number' && PIXEL_PATTERN.test(name)) {
      value = `${value}px`;
    }
    node.style[camelcase(name)] = value; // IE8 support
  }
}
