import $i18n from 'panda-i18n';
/**
 * 搜索失败时渲染
 */
import React from 'react';
import { CnMenu, CnMenuItem } from '@cainiaofe/cn-ui';
export default (function (props) {
    var prefix = props.prefix;
    return (React.createElement(CnMenu, { className: "".concat(prefix, "cascader-select-not-found") },
        React.createElement(CnMenuItem, null,
            React.createElement("div", { className: "cn-address--search-failed-container cn-address-search-text", title: $i18n.get({
                    id: 'SearchFailedPleaseCheckInput',
                    dm: '搜索失败,请检查输入内容',
                    ns: 'CnAddress',
                }) }, $i18n.get({
                id: 'SearchFailedPleaseCheckInput',
                dm: '搜索失败,请检查输入内容',
                ns: 'CnAddress',
            })))));
});
