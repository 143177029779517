import { __assign, __extends } from "tslib";
import * as React from 'react';
import { findDOMNode } from 'react-dom';
import cx from 'classnames';
import { Overlay } from '@cainiaofe/cn-ui';
import { func, dom, events } from '@cainiaofe/cn-ui/_fusion/lib/util/index';
var noop = func.noop, makeChain = func.makeChain;
var Popup = Overlay.Popup;
var SelectPopup = /** @class */ (function (_super) {
    __extends(SelectPopup, _super);
    function SelectPopup(props) {
        var _this = _super.call(this, props) || this;
        _this.handleResize = function () {
            window.clearTimeout(_this.resizeTimeout);
            if (_this.state.visible) {
                _this.resizeTimeout = window.setTimeout(function () {
                    _this.syncWidth();
                }, 200);
            }
        };
        _this.savePopupRef = function (ref) {
            _this.popupRef = ref;
            if (_this.props.popupProps && typeof _this.props.popupProps.ref === 'function') {
                _this.props.popupProps.ref(ref);
            }
        };
        _this.handleVisibleChange = function (visible, type) {
            _this.setVisible(visible, type);
        };
        _this.saveTriggerDOM = function (ref) {
            // eslint-disable-next-line react/no-find-dom-node
            _this.triggerDOM = findDOMNode(ref);
        };
        // 打开 Popup 之前，需要设定需要打开的 popup width
        _this.beforeOpen = function () {
            _this.syncWidth();
        };
        _this.state = {
            visible: 'visible' in props ? props.visible : props.defaultVisible,
            wrapperWidth: 0,
        };
        return _this;
    }
    SelectPopup.getDerivedStateFromProps = function (nextProps, prevState) {
        var state = {};
        // 用于受控更新 visible
        if ('visible' in nextProps && nextProps.visible !== prevState.visible) {
            Object.assign(state, {
                visible: nextProps.visible,
            });
        }
        if (Object.keys(state).length) {
            return state;
        }
        return null;
    };
    SelectPopup.prototype.componentDidMount = function () {
        var _this = this;
        // overlay 还没有完成 mount，所以需要滞后同步宽度
        window.setTimeout(function () { return _this.syncWidth(); }, 0);
        events.on(window, 'resize', this.handleResize);
    };
    SelectPopup.prototype.componentWillUnmount = function () {
        events.off(window, 'resize', this.handleResize);
        clearTimeout(this.resizeTimeout);
    };
    /**
     * Calculate and set width of popup menu
     */
    SelectPopup.prototype.syncWidth = function () {
        var _a = this.props, popupStyle = _a.popupStyle, popupProps = _a.popupProps;
        if ((popupStyle && 'width' in popupStyle) ||
            (popupProps && popupProps.style && 'width' in popupProps.style)) {
            return;
        }
        var width = dom.getStyle(this.triggerDOM, 'width');
        if (width && this.state.wrapperWidth !== width) {
            this.setState({
                wrapperWidth: width,
            });
        }
    };
    SelectPopup.prototype.setVisible = function (visible, type) {
        // disabled 状态下只允许关闭不允许打开
        if ((this.props.disabled && visible) || this.state.visible === visible) {
            return;
        }
        if (!('visible' in this.props)) {
            this.setState({
                visible: visible,
            });
        }
        this.props.onVisibleChange(visible, type);
    };
    SelectPopup.prototype.render = function () {
        var _a;
        var _b = this.props, popupProps = _b.popupProps, popupContainer = _b.popupContainer, popupClassName = _b.popupClassName, popupContent = _b.popupContent, popupStyle = _b.popupStyle, followTrigger = _b.followTrigger, cache = _b.cache, children = _b.children, trigger = _b.trigger, beforeOpen = _b.beforeOpen, afterOpen = _b.afterOpen, beforeClose = _b.beforeClose, afterClose = _b.afterClose, onFocus = _b.onFocus, onBlur = _b.onBlur;
        var _c = this.state, visible = _c.visible, wrapperWidth = _c.wrapperWidth;
        var cls = cx((_a = {},
            _a["cn-multi-select-menu"] = !popupContent,
            _a), popupClassName || popupProps.className);
        var _props = __assign(__assign({ triggerType: 'click', autoFocus: false, cache: cache }, popupProps), { 
            // beforeOpen node not mount, afterOpen too slow.
            // from display:none to block, we may need to recompute width
            beforeOpen: makeChain(this.beforeOpen, beforeOpen, popupProps.beforeOpen), afterOpen: makeChain(onFocus, afterOpen, popupProps.afterOpen), beforeClose: makeChain(beforeClose, popupProps.beforeClose), afterClose: makeChain(onBlur, afterClose, popupProps.afterClose), followTrigger: followTrigger, visible: visible, onVisibleChange: this.handleVisibleChange, shouldUpdatePosition: true, container: popupContainer || popupProps.container, className: cls, style: popupStyle || popupProps.style });
        var refTrigger = React.cloneElement(trigger, {
            ref: this.saveTriggerDOM,
        });
        return (React.createElement(Popup, __assign({}, _props, { trigger: refTrigger, ref: this.savePopupRef }),
            React.createElement("div", { className: "cn-multi-select-spacing-tb", style: { minWidth: wrapperWidth } }, children)));
    };
    SelectPopup.displayName = 'SelectPopup';
    SelectPopup.defaultProps = {
        autoWidth: true,
        popupProps: {},
        onVisibleChange: noop,
        beforeOpen: noop,
        afterOpen: noop,
        beforeClose: noop,
        afterClose: noop,
    };
    return SelectPopup;
}(React.Component));
export { SelectPopup };
