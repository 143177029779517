import { v4 as uuidv4 } from 'uuid';
import isFunction from 'lodash/isFunction';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import constants from './constants';
export var alwaysTrue = function () { return true; };
export var isFunctionOrArrayNotNull = function (val) {
    return isFunction(val) || (val === null || val === void 0 ? void 0 : val.length) > 0;
};
export function getExecuteFunOrValue(val) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if (isFunction(val)) {
        return val.apply(this, args);
    }
    return val;
}
export function transformValue(sourceValue, relations) {
    var _a, _b;
    var _c, _d, _e, _f;
    var relation = (_d = (_c = relations === null || relations === void 0 ? void 0 : relations[0]) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : '';
    if (!isObject(sourceValue)) {
        return _a = {},
            _a[constants.uniqueKey] = uuidv4(),
            _a.relation = relation,
            _a.children = [],
            _a;
    }
    return _b = {},
        _b[constants.uniqueKey] = (_e = getRealKey(sourceValue)) !== null && _e !== void 0 ? _e : uuidv4(),
        _b.relation = (_f = sourceValue === null || sourceValue === void 0 ? void 0 : sourceValue.relation) !== null && _f !== void 0 ? _f : relation,
        _b.children = isArray(sourceValue === null || sourceValue === void 0 ? void 0 : sourceValue.children) ? setChildrenUniKey(sourceValue === null || sourceValue === void 0 ? void 0 : sourceValue.children) : [],
        _b;
}
// 兼容老的写法
export function getRealKey(val) {
    var _a;
    return (_a = val === null || val === void 0 ? void 0 : val[constants.uniqueKey]) !== null && _a !== void 0 ? _a : val === null || val === void 0 ? void 0 : val.key;
}
function setChildrenUniKey(value, valueMap) {
    if (valueMap === void 0) { valueMap = {}; }
    if (isArray(value)) {
        value.forEach(function (item) {
            createKey(item);
        });
    }
    else {
        createKey(value);
    }
    return value;
    function createKey(source) {
        var _a;
        if (isUndefined(getRealKey(source))) {
            var newKey = uuidv4();
            source[constants.uniqueKey] = newKey;
            valueMap[newKey] = true; // 更新
        }
        else if (isObject(source)) {
            source[constants.uniqueKey] = getUniqKey(getRealKey(source));
            valueMap[source[constants.uniqueKey]] = true; // 更新
        }
        if (Array.isArray(source === null || source === void 0 ? void 0 : source.children) && ((_a = source === null || source === void 0 ? void 0 : source.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            setChildrenUniKey(source.children, valueMap);
        }
    }
    function getUniqKey(key) {
        if (key in valueMap) {
            var newKey = uuidv4();
            valueMap[newKey] = true; // 更新
            return newKey;
        }
        return key;
    }
}
