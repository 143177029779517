import { __assign } from "tslib";
var apis = {
    topLevelList: function (apiType) {
        var api = 'mtop.cainiao.geography.collaborate.countries.get';
        if (apiType == 'lazada') {
            api = 'mtop.cainiao.geography.lazada.common.division.country.list';
        }
        return api;
    },
    topLevelInfo: function (apiType) {
        var api = 'mtop.cainiao.geography.collaborate.coountryByCountryCode.get';
        if (apiType == 'lazada') {
            api = 'mtop.cainiao.geography.lazada.common.division.countryid.get';
        }
        return api;
    },
    regionChain: function (apiType) {
        var api = 'mtop.cainiao.geography.collaborate.regionChain.get';
        if (apiType == 'lazada') {
            api = 'mtop.cainiao.geography.lazada.common.division.regionchain.query';
        }
        return api;
    },
    children: function (apiType) {
        var api = 'mtop.cainiao.geography.collaborate.divisionsList.get';
        if (apiType == 'lazada') {
            api = 'mtop.cainiao.geography.lazada.common.division.children.get';
        }
        return api;
    },
    regionChainByName: function () {
        var api = 'mtop.cainiao.geography.collaborate.regionChainByName.get';
        return api;
    },
    // 解析查询接口
    regionChainByParse: function () {
        var api = 'mtop.cainiao.geography.collaborate.regionChain.parse';
        return api;
    },
};
var MAX_REPEAT_ACTION_COUNT = 20;
function get(apiType, apiName, data, sendSDK) {
    if (data === void 0) { data = {}; }
    var i = 0;
    var countryCode = data.countryCode, regionId = data.regionId, divisionId = data.divisionId, iso = data.iso, applicationType = data.applicationType, cnDataVersion = data.cnDataVersion, id = data.id, _a = data.maxLevel, maxLevel = _a === void 0 ? 4 : _a, _b = data.businessId, businessId = _b === void 0 ? '' : _b, _c = data.realm, realm = _c === void 0 ? '' : _c, divisionToken = data.divisionToken, maxDataLevel = data.maxDataLevel, divisionLevel = data.divisionLevel, fullAddress = data.fullAddress, isJsonpForMtop = data.isJsonpForMtop, hideSensitiveDivision = data.hideSensitiveDivision;
    var api = apis[apiName](apiType);
    iso = iso || countryCode;
    id = id || regionId || divisionId;
    return new Promise(function (resolve, reject) {
        action();
        function action() {
            i++;
            var requestBody = {
                api: api,
                v: '1.0',
                ecode: 0,
                dataType: isJsonpForMtop ? 'jsonp' : 'json',
                data: {
                    applicationType: applicationType,
                    cnDataVersion: cnDataVersion,
                    countryCode: iso,
                    regionId: id,
                    divisionId: id,
                    maxLevel: maxLevel,
                    businessId: businessId,
                    realm: realm,
                    fullAddress: fullAddress,
                    hideSensitiveDivision: hideSensitiveDivision,
                },
            };
            if (divisionLevel)
                requestBody.data.divisionLevel = divisionLevel;
            if (apiName === 'regionChainByName') {
                requestBody.data = {
                    divisionToken: divisionToken,
                    applicationType: applicationType,
                    cnDataVersion: cnDataVersion,
                    businessId: businessId,
                    realm: realm,
                    // maxDataLevel,
                    iso: iso,
                    hideSensitiveDivision: hideSensitiveDivision,
                };
            }
            var lib = window.lib;
            var fetchPromise;
            if (sendSDK) {
                fetchPromise = sendSDK.sendMtop(requestBody);
            }
            if (!sendSDK && lib) {
                fetchPromise = lib.mtop.request(requestBody);
            }
            // 如果传入自定义sdk则优先使用
            if (fetchPromise) {
                fetchPromise
                    .then(function (res) {
                    var _a;
                    var resData = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data;
                    // children处理敏感数据
                    if (hideSensitiveDivision && apiType !== 'lazada') {
                        if (apiName === 'children') {
                            var newRes = resData === null || resData === void 0 ? void 0 : resData.divisions.filter(function (o) { return !o.sensitive; });
                            resolve(__assign(__assign({}, resData), { divisions: newRes }));
                        }
                        if (apiName === 'topLevelList') {
                            var newRes = resData.filter(function (o) { return !o.sensitive; });
                            resolve(newRes);
                        }
                        resolve(resData);
                    }
                    else {
                        resolve(resData);
                    }
                })
                    .catch(function (err) {
                    console.error('CnAddress Error: mtop request failed', err);
                    reject();
                });
            }
            else if (i < MAX_REPEAT_ACTION_COUNT) {
                console.error('CnAddress Error: Try to find lib', i);
                setTimeout(action, 300);
            }
            else {
                console.error('CnAddress Error:  lib is not defined');
                reject();
            }
        }
    });
}
export default { get: get, apis: apis };
