import $i18n from 'panda-i18n';
/**
 * 无数据时渲染
 */
import React from 'react';
import { CnMenu, CnMenuItem } from '@cainiaofe/cn-ui';
export default (function (props) {
    var prefix = props.prefix, notFoundContent = props.notFoundContent;
    return (React.createElement(CnMenu, { className: "".concat(prefix, "cascader-select-not-found") },
        React.createElement(CnMenuItem, null, 'notFoundContent' in props ? (notFoundContent) : (React.createElement("div", { style: { textAlign: 'center' } }, $i18n.get({ id: 'TERM.NoData', dm: '暂无数据', ns: 'CnAddress' }))))));
});
