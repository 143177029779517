import * as React from 'react';
import classNames from 'classnames';
import { CnIcon } from '@cainiaofe/cn-ui';
import { pcCssPrefix } from '../constant/css-prefix';
export var CnDingTalkScanInputReadonly = function (props) {
    var value = props.value;
    if (!value.length) {
        return React.createElement(React.Fragment, null, "- -");
    }
    return (React.createElement("div", { className: classNames(pcCssPrefix, "".concat(pcCssPrefix, "--readonly")) }, value.map(function (item) { return (React.createElement("div", { className: "".concat(pcCssPrefix, "--readonly-item") },
        React.createElement(CnIcon, { type: "qr-code-fill" }),
        React.createElement("div", null, item))); })));
};
