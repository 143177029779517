import { __assign } from "tslib";
import { transferObjToAvailable } from './useDetail';
/**
 * 获取props透传的value并转化为 string 或[]<string> 形式，无论是否开启useDetailValue，都支持传入 string 或 object 两种数据形式
 * @param props
 * @returns
 */
export var getFinalValue = function (props) {
    var value = props.value, defaultValue = props.defaultValue;
    var v = 'value' in props ? value : defaultValue;
    v = transferObjToAvailable(v, __assign({}, props));
    return v;
};
