import { __assign } from "tslib";
import { filter, normalizeDataSource, flattingDataSource, filterDataSource, } from '../../utils/index';
/**
 * manage dataSource for menu list
 */
var DataStore = /** @class */ (function () {
    function DataStore(options) {
        // origin data
        this.dataSource = [];
        // current data for menu display
        this.menuDataSource = [];
        // key=>value map for menuDataSource
        // mapDataSource 存储着全量的信息，缓存从这里拿取
        this.mapDataSource = {};
        // current data can be select (not disabled) on menu
        this.enabledDataSource = [];
        // 打平后的 dataSource，用于全量信息统计
        this.flattenDataSource = [];
        this.options = __assign({ filter: filter, key: undefined, addonKey: false, filterLocal: true, showDataSourceChildren: true }, options);
    }
    // 设置属性
    DataStore.prototype.setOptions = function (options) {
        Object.assign(this.options, options);
    };
    // 重新设置数据源
    DataStore.prototype.updateByDS = function (dataSource) {
        this.dataSource = normalizeDataSource(dataSource, 0, this.options.showDataSourceChildren);
        return this.updateAll();
    };
    // 根据外界传入的 searchValue 来进行过滤
    DataStore.prototype.updateByKey = function (key) {
        if (key === this.options.key) {
            return this.getMenuDS();
        }
        this.options.key = key;
        return this.updateAll();
    };
    // 获取原始 dataSource
    DataStore.prototype.getOriginDS = function () {
        return this.dataSource;
    };
    DataStore.prototype.getMenuDS = function () {
        return this.menuDataSource;
    };
    DataStore.prototype.getFlattenDS = function () {
        return this.flattenDataSource;
    };
    DataStore.prototype.getEnableDS = function () {
        return this.enabledDataSource;
    };
    DataStore.prototype.getMapDS = function () {
        return this.mapDataSource;
    };
    // 通过 filter 获取 menuDataSource
    // 将 menuDataSource 打平放到 flattenDataSource
    // 将 flattenDataSource 中的值转为哈希表 mapDataSource
    DataStore.prototype.updateAll = function () {
        var _this = this;
        var _a = this.options, key = _a.key, filterFunc = _a.filter, filterLocal = _a.filterLocal, showDataSourceChildren = _a.showDataSourceChildren;
        this.menuDataSource = filterDataSource(this.dataSource, filterLocal ? key : '', filterFunc, this.options.addonKey);
        this.flattenDataSource = showDataSourceChildren
            ? flattingDataSource(this.menuDataSource)
            : this.menuDataSource;
        this.flattenDataSource.forEach(function (item) {
            _this.mapDataSource["".concat(item.value)] = item;
        });
        this.enabledDataSource = this.flattenDataSource.filter(function (item) { return !item.disabled; });
        return this.menuDataSource;
    };
    return DataStore;
}());
export default DataStore;
