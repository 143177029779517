import { __assign, __extends, __read, __rest, __spreadArray } from "tslib";
import $i18n from 'panda-i18n';
import * as React from 'react';
import { findDOMNode } from 'react-dom';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import { CnTag, CnIcon } from '@cainiaofe/cn-ui';
import { isNull } from '../../utils/index';
import './index.scss';
var SelectTrigger = /** @class */ (function (_super) {
    __extends(SelectTrigger, _super);
    function SelectTrigger() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.maxTagPlaceholder = function (selectedValues) {
            return (React.createElement(CnTag, { size: "small", className: "cn-multi-select-max-tag-placeholder-tag" }, $i18n.get({
                id: 'SelectedValuesLengthItemSelected',
                dm: '已选择 {selectedValuesLength} 项',
                ns: 'CnFastMultiSelect',
            }, { selectedValuesLength: selectedValues.length })));
        };
        _this.saveSelectRef = function (ref) {
            var saveSelectDOM = _this.props.saveSelectDOM;
            // eslint-disable-next-line react/no-find-dom-node
            saveSelectDOM && saveSelectDOM(findDOMNode(ref));
        };
        /**
         * 如果用户是自定义的弹层，则直接以 value 为准，不再校验 dataSource
         * TODO: 2.0 中 value 接受 string/object{value,label} 两种类型的数据，自动做识别，可以避免用户去转换，也和 date-picker 对齐
         * 此外 onChange 第一个参数根据 api 来控制类型是 [string] 还是 [object{value,label}]
         * @param {object} props
         */
        _this.renderValues = function () {
            var _a = _this.props, valueRender = _a.valueRender, fillProps = _a.fillProps, disabled = _a.disabled, valueDS = _a.valueDS, onTagClose = _a.onTagClose, triggerTagProps = _a.triggerTagProps;
            var value = _this.props.value;
            if (isNull(value)) {
                return null;
            }
            value = valueDS;
            if (Array.isArray(value)) {
                var limitedCountValue = value;
                var maxTagPlaceholderEl = void 0;
                // 关于单行值处理显示的方式
                var holder = _this.maxTagPlaceholder;
                if (value.length > 0) {
                    maxTagPlaceholderEl = (React.createElement("div", { className: "cn-multi-select-max-tag-placeholder", key: "_count" }, holder(value)));
                }
                value = limitedCountValue;
                if (!Array.isArray(value)) {
                    value = [value];
                }
                var selectedValueNodes = value.map(function (v) {
                    if (!v) {
                        return null;
                    }
                    var labelNode = fillProps ? v[fillProps] : valueRender(v);
                    return (React.createElement(CnTag, __assign({ key: v.value, disabled: disabled || v.disabled, type: "primary", size: "small", animation: false, onClose: function () { return onTagClose(v); }, closable: true }, triggerTagProps), labelNode));
                });
                if (maxTagPlaceholderEl) {
                    selectedValueNodes.unshift(maxTagPlaceholderEl);
                }
                return selectedValueNodes;
            }
            return null;
        };
        /**
         * 1. fix flash while click <label/>
         * 2. fix onBlur while has clear
         * @returns
         */
        _this.handleWrapClick = function (e) {
            // ignore click on input to choose text
            if (e.target.nodeName !== 'INPUT') {
                e.preventDefault();
            }
        };
        return _this;
    }
    SelectTrigger.prototype.hasClear = function () {
        var _a = this.props, disabled = _a.disabled, value = _a.value;
        if (!isNil(value) && !isEmpty(value) && !disabled) {
            return true;
        }
        return false;
    };
    /**
     * render arrow
     * @param {object} props
     * @param {function} [clickHandler]
     */
    SelectTrigger.prototype.renderExtraNode = function () {
        var onClear = this.props.onClear;
        var ret = [];
        ret.push(React.createElement("span", { key: "arrow", "aria-hidden": true, className: "cn-multi-select-arrow" },
            React.createElement(CnIcon, { type: "icon-triangle-down-fill", className: "cn-multi-select-symbol-fold" })));
        ret.push(React.createElement("span", { key: "clear", "aria-hidden": true, onClick: onClear, className: "cn-multi-select-clear" },
            React.createElement(CnIcon, { type: "icon-error-r-fill" })));
        return ret;
    };
    SelectTrigger.prototype.renderSearchInput = function (valueNodes) {
        var _a;
        var placeholder = this.props.placeholder;
        var cls = cx((_a = {},
            _a["cn-multi-select-values"] = true,
            _a));
        var searchInput;
        if (Array.isArray(valueNodes) && valueNodes.length > 0) {
            searchInput = valueNodes;
        }
        else {
            searchInput = (React.createElement("span", { className: "cn-multi-select-placeholder" }, placeholder || $i18n.get({ id: 'PleaseSelect', dm: '请选择', ns: 'CnFastMultiSelect' })));
        }
        return React.createElement("span", { className: cls }, searchInput);
    };
    SelectTrigger.prototype.render = function () {
        var _a = this.props, placeholder = _a.placeholder, className = _a.className, style = _a.style, disabled = _a.disabled, hasBorder = _a.hasBorder, label = _a.label, state = _a.state, onBlur = _a.onBlur, onFocus = _a.onFocus, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave, visible = _a.visible, 
        // others
        dataStore = _a.dataStore, onClear = _a.onClear, onTagClose = _a.onTagClose, fillProps = _a.fillProps, value = _a.value, valueDS = _a.valueDS, valueRender = _a.valueRender, useDetailValue = _a.useDetailValue, saveSelectDOM = _a.saveSelectDOM, triggerTagProps = _a.triggerTagProps, others = __rest(_a, ["placeholder", "className", "style", "disabled", "hasBorder", "label", "state", "onBlur", "onFocus", "onMouseEnter", "onMouseLeave", "visible", "dataStore", "onClear", "onTagClose", "fillProps", "value", "valueDS", "valueRender", "useDetailValue", "saveSelectDOM", "triggerTagProps"]);
        var valueNodes = this.renderValues();
        var triggerClasses = cx(["cn-multi-select", "cn-multi-select-trigger", className], {
            'cn-multi-has-clear': this.hasClear(),
            'cn-multi-select-active': !!visible,
        });
        return (React.createElement("span", __assign({ className: triggerClasses, style: style, onClick: this.handleWrapClick, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onMouseDown: this.handleWrapClick, ref: this.saveSelectRef }, others),
            this.renderSearchInput(valueNodes),
            this.renderExtraNode()));
    };
    return SelectTrigger;
}(React.Component));
export { SelectTrigger };
