import invert from 'lodash/invert';
import { isPlainObject } from './object';
import { normalizeValue } from './cascaders';
function formatSingleValue(data, useDetailMap, useDetailValue) {
    var detailObj = {
        iso: null,
        countryCode: null, // 国家
        countryName: null,
        provinceCode: null, // 省
        provinceName: null,
        cityCode: null, // 市
        cityName: null,
        districtCode: null, // 区
        districtName: null,
        streetCode: null, // 街道
        streetName: null,
    };
    var firstLevel = data[0];
    if (firstLevel) {
        detailObj.iso = firstLevel.iso;
    }
    data.forEach(function (item) {
        var level = item.level, rawLevel = item.rawLevel, value = item.value, label = item.label;
        // 当出现跳级数据时，会有 rawLevel 字段，表示原始level值，此时的level会进行减1处理
        // 故此处取值优先取 rawLevel，非跳级数据不存在该属性，取 level
        var realLevel = rawLevel || level;
        switch (realLevel) {
            case 1: // 国家
                detailObj.countryCode = value;
                detailObj.countryName = label;
                break;
            case 2: // 省
                detailObj.provinceCode = value;
                detailObj.provinceName = label;
                break;
            case 3: // 市
                detailObj.cityCode = value;
                detailObj.cityName = label;
                break;
            case 4: // 区
                detailObj.districtCode = value;
                detailObj.districtName = label;
                break;
            case 5: // 街道
                detailObj.streetCode = value;
                detailObj.streetName = label;
                break;
        }
    });
    // 开启useDetailMap，转化输出格式
    if (useDetailValue && useDetailMap) {
        return transferUseDetailToMap(detailObj, useDetailMap);
    }
    return detailObj;
}
function getValueByDetailObj(data, _a) {
    var iso = _a.iso, showTopLevel = _a.showTopLevel, maxLevel = _a.maxLevel;
    // 如果是非 obj 类型， 直接返回
    if (!isPlainObject(data))
        return '';
    var codeArr = ['countryCode', 'provinceCode', 'cityCode', 'districtCode', 'streetCode'];
    var level = !iso || showTopLevel ? maxLevel : maxLevel + 1;
    for (var i = level; i >= 0; i--) {
        var k = codeArr[i];
        if (data === null || data === void 0 ? void 0 : data[k]) {
            return data[k];
        }
    }
    return '';
}
/**
 * 转化为useDetailValue格式数据
 * @param {Array/String} value 数据
 * @returns {Object} 返回对象结构数据
 */
export function getDetailValueDS(v, data, extra, multiple, useDetailMap, useDetailValue) {
    if (!data)
        return null;
    if (multiple) {
        var finalData_1 = [];
        data.forEach(function (item) {
            var getChainData = extra.indeterminateData.filter(function (o) { return item.pos.indexOf(o.pos) === 0; });
            getChainData.push(item);
            finalData_1.push(formatSingleValue(getChainData, useDetailMap, useDetailValue));
        });
        return finalData_1;
    }
    else {
        return formatSingleValue((extra === null || extra === void 0 ? void 0 : extra.selectedPath) || [], useDetailMap, useDetailValue);
    }
}
/**
 * 使对象类型数据转为组件内可用类型
 * 暂不支持回填数据中混合形式，例如['310000', {cityCode: '320000'}]
 * @param data
 * @param param
 * @returns 对返回值格式化，返回结构为 Array
 */
export function transferObjToAvailable(data, _a) {
    var iso = _a.iso, showTopLevel = _a.showTopLevel, maxLevel = _a.maxLevel, multiple = _a.multiple, useDetailMap = _a.useDetailMap, useDetailValue = _a.useDetailValue;
    if (!useDetailValue)
        return normalizeValue(data);
    var isDetailData = checkUseDetail(data, multiple);
    if (multiple) {
        if (!Array.isArray(data))
            return [];
        // 判断如果有 对象 类型，则认为是开启详细数据模式
        if (!isDetailData)
            return normalizeValue(data);
        var newData = data.map(function (item) {
            return transferMapToAvailable(item, { iso: iso, showTopLevel: showTopLevel, maxLevel: maxLevel, useDetailMap: useDetailMap });
        });
        return newData.filter(function (o) { return o; });
    }
    else {
        if (!isDetailData)
            return normalizeValue(data);
        return normalizeValue(transferMapToAvailable(data, { iso: iso, showTopLevel: showTopLevel, maxLevel: maxLevel, useDetailMap: useDetailMap }));
    }
}
/**
 * 将map映射数据转换为组件内可用类型
 * @param data 转换源数据
 * @param configs 配置
 * @returns {String} 返回数据
 */
function transferMapToAvailable(data, configs) {
    var useDetailMap = configs.useDetailMap, iso = configs.iso, showTopLevel = configs.showTopLevel, maxLevel = configs.maxLevel;
    // 传入为对象结构，且传入了映射表时，转化
    var newData = useDetailMap ? transferMapToUseDetail(data, useDetailMap) : data;
    return getValueByDetailObj(newData, { iso: iso, showTopLevel: showTopLevel, maxLevel: maxLevel });
}
/**
 * 判断是否使用了 useDetail 类型数据传入
 * @param value 组件传入值(value或defaultValue)
 * @param multiple 是否多选
 */
export function checkUseDetail(value, multiple) {
    if (!value)
        return false;
    if (multiple) {
        // 数组中有一项是对象类型，则认为合法
        return !!normalizeValue(value).find(function (item) { return isPlainObject(item); });
    }
    else {
        return isPlainObject(value);
    }
}
/**
 * 将传入数据中的 key替换为 map 字典中设置的key
 * @param originData
 * @param mapObj
 * @returns 转化结果
 */
// ------示例 -------
/**
 *
 const originData = {
    countryCode: '1', // 国家
    countryName: '中国',
    provinceCode: '330000', // 省
    provinceName: '浙江省',
    cityCode: '330100', // 市
    cityName: '杭州市',
    districtCode: null, // 区
    districtName: null,
    streetCode: '330108002', // 街道
    streetName: '长河街道',
};

const useDetailMap = {
    countryCode: 'countryId',
    provinceCode: 'provinceId',
    districtCode: 'districtId',
    streetCode: 'streetId',
};

---转化后，数据为---
const res = {
    countryId: '1', // 国家
    countryName: '中国',
    provinceId: '330000', // 省
    provinceName: '浙江省',
    cityId: '330100', // 市
    cityName: '杭州市',
    districtId: null, // 区
    districtName: null,
    streetId: '330108002', // 街道
    streetName: '长河街道',
};
 */
function replaceKeysFromMap(originData, mapObj) {
    var newResultUDV = Object.keys(originData).reduce(function (newData, key) {
        var newKey = mapObj[key] || key;
        newData[newKey] = originData[key];
        return newData;
    }, {});
    return newResultUDV;
}
/**
 * 将默认的对象数据转化为自定义的数据结构
 * @param originData 原数据
 * @param mapObj 字段映射表
 */
export function transferUseDetailToMap(originData, mapObj) {
    return replaceKeysFromMap(originData, mapObj);
}
/**
 * 将传入的对象结构转化为内部的默认结构
 * @param originData 传入数据
 * @param mapObj 字段映射表
 * @returns 返回处理结果
 */
export function transferMapToUseDetail(originData, mapObj) {
    // 键值倒置
    var invertMapObj = invert(mapObj);
    return replaceKeysFromMap(originData, invertMapObj);
}
