import React from 'react';
import $i18n from 'panda-i18n';
import { CnButton } from '@cainiaofe/cn-ui';
var footer = [
    {
        text: $i18n.get({ id: 'SelectAll', dm: '全选', ns: 'CnAddress' }),
        type: 'all',
    },
    {
        text: $i18n.get({ id: 'Antielection', dm: '反选', ns: 'CnAddress' }),
        type: 'reverse',
    },
    {
        text: $i18n.get({ id: 'Empty', dm: '清空', ns: 'CnAddress' }),
        type: 'clear',
    },
];
export default (function (props) {
    var count = props.count, _a = props.onChoose, onChoose = _a === void 0 ? function () { } : _a;
    return (React.createElement("div", { className: "cn-address-footer" }, Array(count)
        .fill(0)
        .map(function (i, index) { return (React.createElement("div", { className: "cn-address-footer-item", key: i }, footer.map(function (btn) { return (React.createElement(CnButton, { key: btn.type, onClick: function () { return onChoose(index, btn.type); }, className: "cn-address-footer-btn" }, btn.text)); }))); })));
});
