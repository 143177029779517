import { __assign } from "tslib";
/* eslint-disable no-console */
import isNumber from 'lodash/isNumber';
import merge from 'lodash/merge';
/**
 * 检查SDK是否存在，不存在则插入
 * 注：当插入mtop依赖时，则必须再执行插入一次patch文件，否则可能导致mtop默认配置发生变更（主要发生在同时初始化多个组件实例的情况）
 * @returns
 */
export function checkSDK() {
    var isLoadingMtop = false;
    function addMtopPatch(cb) {
        var scripts = Array.from(document.querySelectorAll('script'));
        var sdk = scripts.find(function (o) { return o.src.includes('cndzk-mtop-patch.js'); });
        if (isLoadingMtop) {
            createScriptForPatch(cb);
            return;
        }
        if (!sdk) {
            createScriptForPatch(cb);
        }
        else {
            cb();
        }
    }
    function createScriptForPatch(callback) {
        var js = document.createElement('script');
        js.src = '//g.alicdn.com/cndzk-entrance/cndzk-mtop-patch/0.1.11/cndzk-mtop-patch.js';
        js.onload = function () {
            callback();
        };
        document.body.appendChild(js);
    }
    return new Promise(function (resolve) {
        var _a;
        var isLoadMtopLib = (_a = window === null || window === void 0 ? void 0 : window.lib) === null || _a === void 0 ? void 0 : _a.mtop;
        if (isLoadMtopLib || window.__is_load_mtop_sdk) {
            addMtopPatch(resolve);
        }
        else {
            isLoadingMtop = true;
            var mtopSDK = document.createElement('script');
            mtopSDK.src =
                '//g.alicdn.com/??mtb/lib-promise/3.1.1/polyfillB.js,mtb/lib-login/1.5.4/login.js,mtb/lib-mtop/2.5.7/mtop.js';
            mtopSDK.onload = function () {
                addMtopPatch(resolve);
                window.__is_load_mtop_sdk = true;
            };
            document.body.appendChild(mtopSDK);
        }
    });
}
/**
 * 设置 mtop 配置
 * @param config
 * @returns
 */
export function setMTOPConfig(config) {
    if (!window.lib)
        return;
    for (var k in config) {
        window.lib.mtop.config[k] = config[k];
    }
}
/**
 * 初始化前预检查
 * @param props
 * @returns
 */
export function preCheckConfigs(props) {
    var config = props.config, showSearch = props.showSearch, maxLevel = props.maxLevel;
    var apiModel = config.apiModel, cdnUrl = config.cdnUrl, businessId = config.businessId;
    if (!isNumber(maxLevel)) {
        console.error('CnAddress Error: maxLevel must be a number');
        return false;
    }
    if (apiModel === 'cdn' && !cdnUrl) {
        console.error('CnAddress Error: config.cdnUrl is required');
        return false;
    }
    if (apiModel === 'api' && !validateApiConfig(__assign(__assign({}, config), { showSearch: showSearch }))) {
        return false;
    }
    if (apiModel === 'mtop' || !apiModel) {
        if (businessId === 'toffee') {
            console.warn('CnAddress Warning: 请不要使用 toffee 作为 businessId，否则可能导致数据不可用', '点此申请->', 'https://bpms.alibaba-inc.com/workdesk/instStartLegao?__id=inist_start&processCode=businessId_apply_process');
        }
    }
    return true;
}
/**
 * API模式下，校验必填参数
 * @param props
 * @returns
 */
export function validateApiConfig(props) {
    if (!props.apiConfig) {
        console.error('CnAddres Error: config.apiConfig is required');
        return false;
    }
    var apiConfig = props.apiConfig, showSearch = props.showSearch;
    var topLevelList = apiConfig.topLevelList, topLevelInfo = apiConfig.topLevelInfo, regionChain = apiConfig.regionChain, children = apiConfig.children, regionChainByName = apiConfig.regionChainByName;
    if (!topLevelList) {
        console.error('CnAddres Error: config.apiConfig.topLevelList is required');
        return false;
    }
    if (!topLevelInfo) {
        console.error('CnAddres Error: config.apiConfig.topLevelInfo is required');
        return false;
    }
    if (!regionChain) {
        console.error('CnAddres Error: config.apiConfig.regionChain is required');
        return false;
    }
    if (!children) {
        console.error('CnAddres Error: config.apiConfig.children is required');
        return false;
    }
    if (!regionChainByName && showSearch) {
        console.error('CnAddres Error: config.apiConfig.regionChainByName is required');
        return false;
    }
    return true;
}
/**
 * 判断是否开启了全局配置
 * @returns
 */
export function isUseGlobalConfig() {
    var _a;
    if ((_a = window === null || window === void 0 ? void 0 : window.__cn_address_global_props__) === null || _a === void 0 ? void 0 : _a.forceUseGlobalConfig)
        return true;
    return false;
}
/**
 * 全局配置与组件配置合并
 * @param originProps 组件原始props
 * @returns
 */
export function mergeGlobalConfig(originProps) {
    if (!isUseGlobalConfig()) {
        return originProps;
    }
    var globalProps = window === null || window === void 0 ? void 0 : window.__cn_address_global_props__;
    return merge(originProps, globalProps);
}
